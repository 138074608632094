import React, { useRef, useState } from 'react'
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { ToastContainer, toast } from 'react-toastify';
import email from "../Assets/Images/email.gif"
import close from '../Assets/Images/close.png'

import 'react-toastify/dist/ReactToastify.css';

const base_url = process.env.REACT_APP_BASE_URL;
export default function Footer() {

    const [formdata, setFormdata] = useState({ email: '', message: '' });
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);

    const handleEmail = (e) => {
        const { name, value } = e.target;
        setFormdata(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const res = await fetch(`${base_url}/api/v1/convertkitSubscribe`, {
            body: JSON.stringify({ email: formdata.email }),
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            method: 'POST',
        })
        const data = await res.json();
        if (res.status === 200) {
            setOpen(true);
            setFormdata({ email: '', message: '' });
        } else {
            toast.error(data.message);
        }
        setFormdata(prevData => ({
            ...prevData,
            message: data.message
        }));
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <footer className="bg-bg mt-6 relative">
            <ToastContainer />

            <div className="mx-auto  space-y-8 px-4 xl:pt-16 lg:pt-16 pb-4 sm:px-6 lg:space-y-16 lg:px-8">
                <div className="grid grid-cols-2 gap-8 lg:grid-cols-3 xs:grid-col-3">
                    <div className='xs:w-[15rem] xl:w-auto'>
                        <div className="">
                            <img
                                loading="lazy"
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff8074e0079c824504623212afdc96129387a77795b04861550fdaed430eae78?"
                                className="object-contain object-center max-w-full aspect-[4.17] w-[200px] ml-3 xl:h-36 xs:h-20" alt=""
                            />
                        </div>

                        <div className='mx-4'>
                            <div className=" flex justify-start">
                                <form onSubmit={submitForm} className="xl:flex xs:hidden">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formdata.email}
                                        onChange={handleEmail}
                                        className="form-control bg-bg lg:w-72 py-3 md:w-64 sm:w-48 xs:w-full px-2 text-[17px] text-white border-white border-l-[0.98px] border-t-[0.98px] border-b-[0.98px]"
                                        placeholder="Enter Your Email"
                                        style={{ borderRadius: '6.355px 0px 0px 6.355px', '::placeholder': { color: 'white' }, WebkitTextFillColor: 'white' }} required
                                    />
                                    <div className="cursor-pointer ">
                                        <button className="bg-yellow xl:px-6 w-36 xs:px-6  py-4  xl:text-[17px] xs:text-base text-white font-medium hover:text-black" style={{ borderRadius: '0px 6.355px 6.355px 0px' }}>
                                            Get Updates
                                        </button>
                                    </div>

                                </form>
                                <form onSubmit={submitForm} className="xl:hidden xs:relative  w-96">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formdata.email}
                                        onChange={handleEmail}
                                        className="form-control bg-bg lg:w-72 md:w-64 sm:w-48 xs:w-full px-2 py-2 text-sm text-white border-white border-[0.98px] my-2"
                                        placeholder="Enter Your Email"
                                        style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px', '::placeholder': { color: 'white' }, WebkitTextFillColor: 'white' }} required
                                    />

                                    <div className="cursor-pointer">
                                        <button className="bg-yellow px-4 flex justify-start py-2 text-white text-sm font-medium hover:text-black" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                                            Get Updates
                                        </button>
                                    </div>

                                </form>

                            </div>
                            <Transition.Root show={open} as={Fragment}>
                                <Dialog
                                    as="div"
                                    className="fixed inset-0 overflow-y-auto z-50"
                                    initialFocus={cancelButtonRef}
                                    onClose={handleClose}
                                >
                                    <div className="flex items-center justify-center min-h-screen">
                                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <div className="bg-white rounded-lg p-4 w-80 sm:w-96" ref={cancelButtonRef}>

                                                <div className="flex justify-end">
                                                    <button onClick={handleClose} >
                                                        <img src={close} alt='close' />
                                                    </button>
                                                </div>
                                                <div className=' text-center text-base font-medium'>
                                                    <div className='flex justify-center'>
                                                        <img src={email} alt=''/>
                                                    </div>
                                                    <p>{formdata.message}</p>
                                                    <p>Thank you for connecting with us.</p>
                                                </div>
                                            </div>
                                        </Transition.Child>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                            {/* <ul className="mt-8 xl:flex gap-6  xs:hidden lg:flex xs:justify-start xl:justify-start">
                                <li>
                                    <Link
                                        to="www.facebook.com/groups/279095609748454/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-white transition hover:opacity-75"
                                    >
                                        <img src={fb} alt='' />
                                    </Link>
                                </li>

                                <li>
                                    <a
                                        href="/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-white transition hover:opacity-75"
                                    >
                                        <img src={ln} alt='' />
                                    </a>
                                </li>

                                <li>
                                    <a
                                        href="/"
                                        rel="noreferrer"
                                        target="_blank"
                                        className="text-white transition hover:opacity-75"
                                    >
                                        <img src={twitter} alt='' />
                                    </a>
                                </li>

                            </ul> */}
                        </div>
                    </div>

                    <div className="xs:hidden xl:grid lg:grid grid-cols-1 ml-16 gap-4 sm:grid-cols-2 lg:col-span-2 lg:grid-cols-3 md:grid-cols-3">
                        <div>
                            <p className="font-medium text-xl text-white text-start">About</p>

                            <ul className="mt-6 space-y-4 xl:text-lg xs:text-xs text-start">
                                <li>
                                    <a href="/" className="text-white transition hover:opacity-75"> Home </a>
                                </li>

                                <li>
                                    <a href="/about" className="text-white transition hover:opacity-75"> About Us </a>
                                </li>

                                <li>
                                    <a href="/articles" className="text-white transition hover:opacity-75"> Articles </a>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <p className="font-medium text-xl text-white text-start">Offerings</p>

                            <ul className="mt-6 space-y-4 xl:text-lg xs:text-xs text-start ">
                                <li>
                                    <a href="/offering" className="text-white transition hover:opacity-75">Real Estate Offerings </a>
                                </li>
                                <li>
                                    <a href="/Regcf" className="text-white transition hover:opacity-75"> Reg CF Offerings </a>
                                </li>
                                <li>
                                    <a href="/rega" className="text-white transition hover:opacity-75"> Reg A Offerings </a>
                                </li>

                            </ul>
                        </div>

                        <div>
                            <p className="font-medium text-xl text-white text-start">Resources</p>

                            <ul className="mt-6 space-y-4 xl:text-lg xs:text-xs text-start">
                                <li>
                                    <a href="/realstate" className="text-white transition hover:opacity-75"> Real Estate Risk Score </a>
                                </li>

                                <li>
                                    <a href="/sponser" className="text-white transition hover:opacity-75">Real Estate Sponsors </a>
                                </li>

                                <li>
                                    <a href="/fee" className="text-white transition hover:opacity-75"> Real Estate Fees </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className=" xl:hidden lg:hidden xs:grid  gap-1 sm:grid-cols-1  ">
                        <div>
                            <ul className="mt-8 ml-10 space-y-4 xl:text-lg xs:text-sm underline text-white  ">
                                <li>
                                    <a href="/terms" className="text-white transition hover:opacity-75">Terms of Use </a>
                                </li>

                                <li>
                                    <a href="/privacy" className="text-white transition hover:opacity-75">Privacy Policy </a>
                                </li>
                                <li>
                                    <a className='mx-1 hover:opacity-75' href="/cookies">Cookies Policy</a>
                                </li>
                                <li>
                                    <a className='mx-1 hover:opacity-75' href="/affiliate">Affiliate Disclosure</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='xl:flex lg:flex lg:justify-between xl:justify-between text-base border-t border-t-slate-400 pt-3 my-2'>
                    <div>
                        <p className=" text-white xl:text-[15px]  xs:text-sm my-1">Copyright &copy; 2023 The Crowdfund Investor | All Rights Reserved | Design and Developed by
                            <a className='mx-1 hover:opacity-75 underline' href='https://wise360.ltd/' target="_blank" rel="noopener noreferrer">Wise360 Solutions</a></p>
                    </div>
                    <div className='xl:flex  lg:flex xs:hidden text-white  justify-center xl:text-[15px]  xs:text-sm xl:my-1 lg:my-1 xs:my-3'>
                        <a className='mx-1 hover:opacity-75 underline' href="/terms">Terms of Use</a> | <a className='mx-1 hover:opacity-75 underline' href='/privacy'> Privacy Policy</a> | <a className='mx-1 hover:opacity-75 underline' href="/cookies">Cookies Policy</a> |  <a className='mx-1 hover:opacity-75 underline' href="/affiliate">Affiliate Disclosure</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}
