import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

export default function Cookies() {
    return (
        <div>
            <Navbar />

            <div className="bg-white flex flex-col items-stretch my-5 mb-3  xl:mx-5">

                <div className="text-[#625D42] xl:text-[60px] xs:text-3xl my-2 mb-3 font-semibold tracking-wide uppercase self-center  max-md:max-w-full max-md:text-4xl mt-3">
                    Cookies Policy
                </div>

                <div className="text-[#616161] my-3 xl:text-lg xs:text-base leading-5 self-start text-start mx:10 xl:mx-auto xl:w-[85rem] lg:mx-10  md:mx-10 xs:mx-5  mt-7 max-md:max-w-full">
                    <div className='my-3'>
                        <p className="my-1">
                            We at <b className='font-semibold' >The CrowdFund Investor </b> (“we,” “us,” or “our”) use cookies to improve your Site experience, to assess content usage, and to support the marketing of our services. We want to be completely transparent about the cookies we use and to make their control as easy as possible for you. This Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with may use cookies on the Service, your choices regarding cookies and further information about cookies.

                        </p>
                    </div>
                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            1. What is a cookie?
                        </p>
                    </div>

                    <div className='my-3'>
                        A cookie is a simple text file that is stored on your computer or mobile device by a website’s server and only that server will be able to retrieve or read the contents of that cookie. Each cookie is unique to your web browser. It will contain some anonymous information such as a unique identifier and the Site name and some digits and numbers.
                        <div className='my-3'>
                            <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-1">
                                2. Why do we use cookies?
                            </p>
                        </div>

                        <div className='my-2'>
                            We use cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to better understand your use of our website, to track and target the interests of our Users, and to enhance the experience of Users on our website. Third parties serve cookies through our website for advertising, analytics and other purposes. Generally, we use cookies for purposes including but not limited to:

                            <p className="my-1">  <i className='font-medium'>a. Session Management:</i> Ensuring seamless navigation and user interactions throughout the website, including the shopping cart and checkout process..</p>

                            <p className="my-1 "> <i className='font-medium'>b. Personalization:</i> Enhancing the user experience by providing personalized content, product recommendations, and tailored offers based on user preferences and behavior.</p>

                            <p className="my-1"><i className='font-medium'>c. Website Functionality:</i> Remembering user preferences, language settings, and display options to provide a customized experience.</p>

                            <p className="my-1"> <i className='font-medium'>d. User Authentication:</i> Facilitating secure access and protecting sensitive information.</p>

                            <p className="my-1"><i className='font-medium'>e. Analytics and Performance:</i> Tracking website traffic, user interactions, and performance metrics to analyze and improve our website's effectiveness..</p>
                            <p className="my-1"><i className='font-medium'>f. Advertising and Remarketing:</i> Delivering targeted advertisements and remarketing campaigns across different platforms based on user interests and behavior.</p>
                            <p className="my-1"><i className='font-medium'>g. Affiliate Marketing:</i> Tracking referrals from affiliate partners to ensure accurate commission calculations for successful referrals.</p>
                            <p className="my-1"><i className='font-medium'>h. Social Media Integration:</i> Integrating social media plugins to enable users to share content and engage with social media platforms directly from our website.</p>
                            <p className="my-1"><i className='font-medium'>i. A/B Testing:</i> Conducting A/B testing to compare different website versions and measure the impact of changes on user behavior.</p>


                        </div>
                    </div>
                    <div className='my-3'>
                        <p className="font-semibold xl:text-xl xs:text-xl leading-7 text-[#343A40] my-3">
                            The cookies we use
                        </p><p>We use the following sets of cookies;</p>

                    </div>
                    <ol className='my-3 custom-list '>
                        Where relevant under applicable laws, all processing of your personal
                        information will be justified by a &quot;lawful ground&quot; for
                        processing. In the majority of cases, processing will be justified on
                        the basis that:
                        <li className="font-semibold  text-[#616161] my-1">
                            Necessary cookies
                        </li>
                        <p>
                            These cookies are important to the underlying operation of the website, supporting important functionality such as shopping baskets and the technical operation of the website to ensure it performs how you would expect.

                        </p>
                        <li className="font-semibold  text-[#616161] my-1">
                            Site experience cookies
                        </li>
                        <p>
                            These cookies are used to support your experience on the Site and include user-selected options, Site navigation aids, etc. Generally, no personally identifiable data is collected with these types of cookies.
                        </p>
                        <li className="font-semibold  text-[#616161] my-1">
                            Performance & operation cookies
                        </li>
                        <p>
                            These cookies are used in the management of the Site and include customer surveys, recording visitor numbers and other web analytics. Limited identifiable data may be collected.
                        </p>
                        <li className="font-semibold  text-[#616161] my-1">
                            Marketing, targeted advertising cookies
                        </li>
                        <p>
                            These cookies are used to track browsing habits and activity. We use this information to enable us to show you relevant/personalized marketing content. Using these types of cookies, we may collect personally identifiable information and use this to display targeted advertising and/or share this data with third parties for the same purpose. Any activity tracked and recorded using these cookies maybe shared to third parties.

                        </p>
                    </ol>
                    <div className='my-3'>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            3. What to do if you don’t want cookies to be set
                        </p>
                    </div>
                    <div>
                        Some people find the idea of a website storing information on their computer or mobile device a bit intrusive, particularly when this information is stored and used by a third party without them knowing. Although this is generally quite harmless you may not, for example, want to see advertising that has been targeted to your interests. If you prefer, it is possible to block some or all cookies, or even to delete cookies that have already been set; but you need to be aware that you might lose some functions of the website. </div>
                    <div className='my-3'>
                        <div >
                            <p className="font-semibold xl:text-xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                How can I control cookies?
                            </p>
                        </div>
                        <p className=''>
                            If you don’t want to receive cookies, you can modify your browser so that it notifies you when cookies are sent to it or you can refuse cookies altogether. You can also delete cookies that have already been set.
                        </p>
                        <p className=''>
                            If you wish to restrict or block web browser cookies which are set on your device then you can do this through your browser settings; the Help function within your browser should tell you how. Alternatively, you may wish to visit www.aboutcookies.org which contains comprehensive information on how to do this on a wide variety of desktop browsers.

                        </p>
                        <p className=''>
                            Please be aware that restricting the use of cookies may affect the features available to you
                        </p>

                    </div>
                    <div className='my-3'>
                        <div >
                            <p className="font-semibold xl:text-xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                Notice about Do Not Track (DNT) browser setting
                            </p>
                        </div>
                        <p className=''>
                            DNT is a feature offered by some browsers which, when enabled, sends a signal to websites to request that your browsing is not tracked, such as by third party ad networks, social networks and analytic companies. This website does not currently respond to DNT requests. However, you may opt-out of tracking on this website, including analytics (and tailored advertising if you are visiting from outside the UK), by changing your cookie settings.
                        </p>
                        <p className=''>
                            A uniform standard has not yet been adopted to determine how DNT requests should be interpreted and what actions should be taken by websites and third parties. We will continue to review DNT and other new technologies and may adopt a DNT standard once available.

                        </p>

                    </div>
                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            4. Duration of cookies stored on your device
                        </p>
                    </div>
                    <div className='my-3'>
                        <div >
                            <p className="font-semibold xl:text-xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                Temporary cookies

                            </p>
                        </div>
                        <p className=''>
                            We use temporary cookies on our website to allow you to browse without interruption. We may also use temporary cookies for internal reporting purposes. After your browsing session ends, this type of cookie does not store any information on your hard drive.
                        </p>


                    </div>
                    <div className='my-3'>
                        <div >
                            <p className="font-semibold xl:text-xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                Persistent cookies
                            </p>
                        </div>
                        <p className=''>
                            Some of our analytics cookies and certain third-party cookies are persistent cookies. They allow us and third parties to understand visitors’ browsing behavior over a longer period of time, to provide you with additional features related to your use of the website, the content that you have saved or stored through them, as well as for advertising and marketing purposes. Depending on their function, this type of cookie may persist for different lengths of time ranging from several hours, to a week, or even several years. This type of cookie will persist after you end a browsing session and can be used by our us or third parties to recognize your device when you begin a new browsing session.</p>


                    </div>

                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            5. How often will you update this Cookie Statement?
                        </p>
                    </div>
                    <p className=''>Primarily, we store your data in the United States of America. Whether
                        We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies. The date at the top of this Cookie Policy indicates when it was last updated.
                    </p>

                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            6. Contact us
                        </p>
                    </div>
                    <p className=''>
 If you have any questions about our use of cookies or other technologies, please email us at 
 <a href="mailto:info@thecrowdfundinvestor.com" className='mx-1 hover:opacity-75'>info@thecrowdfundinvestor.com</a>
</p>

                </div>

            </div>

            <Footer />
        </div>
    )
}
