import React, { useState } from 'react';
import i from '../Assets/Icons/i.svg'
import Input from '../Components/Input'
import MainPageLayout from '../Pages/Layouts/MainPageLayout';
import { Popover, OverlayTrigger } from "react-bootstrap";
import bg from '../Assets/Images/bg.png';
import spinner from '../Assets/Images/spinner.gif'
const ApiToken = process.env.REACT_APP_RISK_SCORE;


const apiEndpoints = [
    `https://sheet.best/api/sheets/${ApiToken}/tabs/perc Change`,
    `https://sheet.best/api/sheets/${ApiToken}/tabs/bps Ranges`,
    `https://sheet.best/api/sheets/${ApiToken}/tabs/Sponsor Ranges`
];

export default function RealState() {

    const [purchaseValue, setPurchaseValue] = useState();
    const [saleValue, setSaleValue] = useState();
    const [avgRentPurchase, setAvgRentPurchase] = useState();
    const [avgRentSale, setAvgRentSale] = useState();
    const [occupancyPurchase, setOccupancyPurchase] = useState();
    const [occupancySale, setOccupancySale] = useState();
    const [leaseTeamPurchase, setLeaseTeamPurchase] = useState();
    const [leaseTeamSale, setLeaseTeamSale] = useState();
    const [grossRevenuePurchase, setGrossRevenuePurchase] = useState();
    const [grossRevenueSale, setGrossRevenueSale] = useState();
    const [expensesPurchase, setExpensesPurchase] = useState();
    const [expensesSale, setExpensesSale] = useState();
    const [reimbursementsPurchase, setReimbursementsPurchase] = useState();
    const [reimbursementsSale, setReimbursementsSale] = useState();
    const [netOperatingIncomePurchase, setNetOperatingIncomePurchase] = useState();
    const [netOperatingIncomeSale, setNetOperatingIncomeSale] = useState();
    const [salePrice, setSalePrice] = useState();
    const [purchase, setpurchase] = useState();
    const [year, setYear] = useState();
    const [Valueallproperty, setValueallproperty] = useState();
    const [losses, setlosses] = useState();
    const [offeringmaterials, setofferingmaterials] = useState();
    const [sponserfee, setsponserfee] = useState();
    const [invertorEquity, setinvertorEquity] = useState();
    const [coInvest, setcoInvest] = useState();
    const [Data, setData] = useState(false);

    const handelPercChange = (event, value) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9.]/g, '');
        inputValue = inputValue.replace(/(\..*)\./g, '$1');
        if (!inputValue.endsWith('%')) {
            inputValue = inputValue + '%';
        }
        if (value === "occupancypurchase") { setOccupancyPurchase(inputValue); }
        else if (value === "occupancysale") { setOccupancySale(inputValue); }
        else if (value === "cappurchase") { setPurchaseValue(inputValue); }
        else if (value === "capsale") { setSaleValue(inputValue); }
    };
    

    const handelCurrencyChange = (event, value) => {
        let inputValue = event.target.value;
        inputValue = inputValue.replace(/[^0-9.,]/g, '');
        inputValue = inputValue.replace(/,/g, '').replace(/%/g, '');
        inputValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        inputValue = inputValue.replace(/\./, ',');
        if (!inputValue.startsWith('$')) {
            inputValue = '$' + inputValue;
        }
        if (value === "rentpurchase") { setAvgRentPurchase(inputValue); }
        else if (value === "rentsale") { setAvgRentSale(inputValue); }
        else if (value === "grosspurchase") { setGrossRevenuePurchase(inputValue) }
        else if (value === "grosssale") { setGrossRevenueSale(inputValue) }
        else if (value === "expensespurchase") { setExpensesPurchase(inputValue) }
        else if (value === "expensesSale") { setExpensesSale(inputValue) }
        else if (value === "reimbursementpurchase") { setReimbursementsPurchase(inputValue) }
        else if (value === "reimbursementsale") { setReimbursementsSale(inputValue) }
        else if (value === "netoperatingpurchase") { setNetOperatingIncomePurchase(inputValue) }
        else if (value === "netoperatingsale") { setNetOperatingIncomeSale(inputValue) }
        else if (value === "purchasePrice") { setpurchase(inputValue) }
        else if (value === "salePrice") { setSalePrice(inputValue) }
        else if (value === "Valueallproperty") { setValueallproperty(inputValue) }
        else if (value === "sponserfee") { setsponserfee(inputValue) }
        else if (value === "invertorEquity") { setinvertorEquity(inputValue) }
        else if (value === "coInvest") { setcoInvest(inputValue) }

    };


    let correspondingyearRiskScore;
    const checkYearRiskScore = async (year) => {
        try {
            const response = await fetch(apiEndpoints[2]);
            const data = await response.json();

            const matchingItem = data.find(item => {
                return item['Year (Principal Year)'] === year;
            });

            if (matchingItem) {
                correspondingyearRiskScore = matchingItem['Risk Score (Principal Year)'];
            } else {
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const [capweightscore, setcapweightscore] = useState(null);
    const [rentweightscore, setrentweightscore] = useState(null);
    const [leaseweightscore, setleaseweightscore] = useState();
    const [occupanycyweightscore, setoccupanycyweightscore] = useState()
    const [grossweightscore, setgrossweightscore] = useState();
    const [exponseweightscore, setexponseweightscore] = useState();
    const [reimbursementsweightscore, setreimbursementsweightscore] = useState()
    const [netincomeWeightscore, setnetincomeWeightscore] = useState()
    const [purchaseWeightscore, setpurchaseWeightscore] = useState()
    const [allpropweightscore, setallpropweightscore] = useState()
    const [yearstatredweightscore, setyearstatredweightscore] = useState()
    const [propertsizeweightscore, setpropertsizeweightscore] = useState();
    const [properlossessizeweightscore, setproperlossessizeweightscore] = useState()
    const [offeringmaterialweightscore, setofferingmaterialweightscore] = useState();
    const [investequityweightscore, setinvestequityweightscore] = useState();



    const numericPurchaseValue = purchaseValue ? purchaseValue.replace(/,/g, '').replace('%', '') : '';
    const capRatePurchase = (parseFloat(numericPurchaseValue) / 100).toFixed(4);
    const numericsaleValue = saleValue ? saleValue.replace(/,/g, '').replace('%', '') : '';
    const capRateSale = (parseFloat(numericsaleValue) / 100).toFixed(4);
    const numericRentPurchaseValue = avgRentPurchase ? avgRentPurchase.replace(/,/g, '').replace('$', '') : '';
    const avgRentPurchaseValue = (parseInt(numericRentPurchaseValue));
    const numericRentSaleValue = avgRentSale ? avgRentSale.replace(/,/g, '').replace('$', '') : '';
    const avgRentSaleValue = (parseInt(numericRentSaleValue));
    const numericoccupancyPurchase = occupancyPurchase ? occupancyPurchase.replace(/,/g, '').replace('%', '') : '';
    const occupancyPurchaseValue = (parseInt(numericoccupancyPurchase));
    const numericoccupancysale = occupancySale ? occupancySale.replace(/,/g, '').replace('%', '') : '';
    const occupancySaleValue = numericoccupancysale;
    const leaseTeamPurchaseValue = leaseTeamPurchase;
    const leaseTeamSaleValue = leaseTeamSale;
    const numericgrossPurchase = grossRevenuePurchase ? grossRevenuePurchase.replace(/,/g, '').replace('$', '') : '';
    const grossRevenuePurchaseValue = (parseInt(numericgrossPurchase));
    const numericgrosssale = grossRevenueSale ? grossRevenueSale.replace(/,/g, '').replace('$', '') : '';
    const grossRevenueSaleValue = (parseInt(numericgrosssale));
    const numericexpensePurchase = expensesPurchase ? expensesPurchase.replace(/,/g, '').replace('$', '') : '';
    const expensesPurchaseValue = (parseInt(numericexpensePurchase));
    const numericexpensesale = expensesSale ? expensesSale.replace(/,/g, '').replace('$', '') : '';
    const expensesSaleValue = (parseInt(numericexpensesale));
    const numericreimbursementsPurchase = reimbursementsPurchase ? reimbursementsPurchase.replace(/,/g, '').replace('$', '') : '';
    const reimbursementsPurchaseValue = (parseInt(numericreimbursementsPurchase));
    const numericreimbursementsSale = reimbursementsSale ? reimbursementsSale.replace(/,/g, '').replace('$', '') : '';
    const reimbursementsSaleValue = (parseInt(numericreimbursementsSale));
    const numericnetOperatingIncomePurchase = netOperatingIncomePurchase ? netOperatingIncomePurchase.replace(/,/g, '').replace('$', '') : '';
    const netOperatingIncomePurchaseValue = (parseInt(numericnetOperatingIncomePurchase));
    const numericnetOperatingIncomeSale = netOperatingIncomeSale ? netOperatingIncomeSale.replace(/,/g, '').replace('$', '') : '';
    const netOperatingIncomeSaleValue = (parseInt(numericnetOperatingIncomeSale));
    const numericsalePrice = salePrice ? salePrice.replace(/,/g, '').replace('$', '') : '';
    const salePriceValue = (parseInt(numericsalePrice));
    const numericpurchasePrice = purchase ? purchase.replace(/,/g, '').replace('$', '') : '';
    const purchasePriceValue = (parseInt(numericpurchasePrice));
    const numericValueallproperty = Valueallproperty ? Valueallproperty.replace(/,/g, '').replace('$', '') : '';
    const purchasepropert = (parseInt(numericValueallproperty));
    const purchaselosses = losses;
    const PortfolioSize = (purchasepropert !== 0) ? (purchasePriceValue / purchasepropert).toFixed(4) * 100 : 0;

    const numericsponserfee = sponserfee ? sponserfee.replace(/,/g, '').replace('$', '') : '';
    const finalsponserfee = (parseInt(numericsponserfee));
    const numericinvertorEquity = invertorEquity ? invertorEquity.replace(/,/g, '').replace('$', '') : '';
    const finalinvertorEquity = (parseInt(numericinvertorEquity));
    const numericcoInvest = coInvest ? coInvest.replace(/,/g, '').replace('$', '') : '';
    const finalcoInvest = (parseInt(numericcoInvest));

    const calculatedLookupValue = (Math.round((capRatePurchase - capRateSale) * 1000)/10);
    const caplookupValue = (calculatedLookupValue).toFixed(3) + '%';
    const percentageChange = ((avgRentSaleValue - avgRentPurchaseValue) / avgRentPurchaseValue || 0) * 100;
    const rentscalculatedLookupValue = Math.round(percentageChange * 100) / 100;
    const occupancyLookupValue = Math.round((occupancySaleValue - occupancyPurchaseValue) * 1000) / 1000;
    const leaseLookupValue = Math.round(((leaseTeamSaleValue - leaseTeamPurchaseValue) / leaseTeamPurchaseValue || 0) * 100);
    const grossLookupValue = Math.round(((grossRevenueSaleValue - grossRevenuePurchaseValue) / grossRevenuePurchaseValue || 0) * 100);
    const expenseLookupValue = Math.round(((expensesSaleValue - expensesPurchaseValue) / expensesPurchaseValue || 0) * 100);
    const reimbursementsLookupValue = reimbursementsPurchaseValue === 0 ? 1 :
        Math.round((reimbursementsSaleValue - reimbursementsPurchaseValue) / reimbursementsPurchaseValue * 100 || 0) * 100;
    const netincomeLookupValue = Math.round(((netOperatingIncomeSaleValue - netOperatingIncomePurchaseValue) / netOperatingIncomePurchaseValue || 0) * 100);
    const purchaseLookupValue = Math.round(((salePriceValue - purchasePriceValue) / purchasePriceValue || 0) * 100) / 100;
    const PropertLookupValue = Math.round(purchasepropert / 10000000) * 10000000;
    const PortfolioSizeLookupValue = Math.ceil(PortfolioSize * 100) / 100;
    const investorLookupValue = Math.ceil((finalcoInvest - finalsponserfee) / finalinvertorEquity * 100);


    const capcalculatedWeight = 0.125;
    const rentscalculatedWeight = 0.075;
    const occupancyWeight = 0.075;
    const leaseWeight = 0.05;
    const grossWeight = 0.05;
    const expenseWeight = 0.05;
    const reimbursementsweight = 0.05;
    const netincomeWeight = 0.125;
    const purchaseWeight = 0.05;
    const yearstatredweight = 0.05;
    const allpropweight = 0.05;
    const propertsizeweight = 0.05;
    const properlossessizeweight = 0.05;
    const offeringmaterialweight = 0.05;
    const investequityweight = 0.10;
    const totalWeight = capcalculatedWeight + rentscalculatedWeight + occupancyWeight + leaseWeight + grossWeight + expenseWeight + reimbursementsweight + netincomeWeight + purchaseWeight + yearstatredweight
        + allpropweight + propertsizeweight + properlossessizeweight
        + offeringmaterialweight + investequityweight;
       

    const handleYearChange = (e) => {
        const newYear = e.target.value;
        setYear(newYear);
        checkYearRiskScore(newYear);
    };

    const [isLoading, setIsLoading] = useState(false);
    const calculate = () => {
        setIsLoading(true);

        let correspondingCapRateRiskScore;
        const capData = async () => {
            try {
                const response = await fetch(apiEndpoints[1]);
                const data = await response.json();

                if (calculatedLookupValue < 0) {
                    correspondingCapRateRiskScore = 10;
                } else {
                    const matchingItem = data.find(item => {
                        const itemCapRateChange = parseFloat(item['Cap Rate % Change']);
                        const difference = Math.abs(itemCapRateChange - calculatedLookupValue);
                        const tolerance = 0.01;
                        return difference < tolerance;
                    });

                    if (matchingItem) {
                        correspondingCapRateRiskScore = matchingItem['Cap Rate Risk Score'];
                        
                    } else {
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }; capData().then(() => {
            setcapweightscore(correspondingCapRateRiskScore * 0.125);
        });



        let correspondingRiskScore;
        const rentrange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    return Math.round(parseFloat(item['% Change post'])) === Math.round(rentscalculatedLookupValue) ||
                        Math.round(parseFloat(item['% Change neg'])) === Math.round(rentscalculatedLookupValue);
                });
                if (matchingItem) {
                    correspondingRiskScore = rentscalculatedLookupValue >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }; rentrange().then(() => {
            setrentweightscore((correspondingRiskScore * 0.75) / 10);
        })
        let correspondingoccpRiskScore;
        const occupancyRange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    return Math.round(parseFloat(item['% Change post'])) === Math.round(occupancyLookupValue) ||
                        Math.round(parseFloat(item['% Change neg'])) === Math.round(occupancyLookupValue);
                });
                if (matchingItem) {
                    correspondingoccpRiskScore = occupancyLookupValue >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching occupancy range data:", error);
            }
        }; occupancyRange().then(() => {
            setoccupanycyweightscore((correspondingoccpRiskScore * 0.75) / 10);
        })

        let correspondingleaseRiskScore;
        const leaseRange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    return Math.round(parseFloat(item['% Change post'])) === Math.round(leaseLookupValue) ||
                        Math.round(parseFloat(item['% Change neg'])) === Math.round(leaseLookupValue);
                });
                if (matchingItem) {
                    correspondingleaseRiskScore = leaseLookupValue >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching lease range data:", error);
            }
        }; leaseRange().then(() => {
            setleaseweightscore((correspondingleaseRiskScore * 0.05));
        })
        let correspondinggrossRiskScore;
        const formattedGrossLookupValue = grossLookupValue.toFixed(2) + "%";
        const grossRange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();

                const matchingItem = data.find(item => {
                    return item['% Change post'] === formattedGrossLookupValue ||
                        item['% Change neg'] === formattedGrossLookupValue;
                });

                if (matchingItem) {
                    correspondinggrossRiskScore = (grossLookupValue * 100) >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching gross range data:", error);
            }
        };
        grossRange().then(() => {
            setgrossweightscore(correspondinggrossRiskScore * 0.05);
        });

        let correspondingexpenseRiskScore
        const expenseRange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    return Math.round(parseFloat(item['% Change post'])) === Math.round(expenseLookupValue) ||
                        Math.round(parseFloat(item['% Change neg'])) === Math.round(expenseLookupValue);
                });

                if (matchingItem) {
                    correspondingexpenseRiskScore = expenseLookupValue >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching expense range data:", error);
            }
        }; expenseRange().then(() => {
            setexponseweightscore(correspondingexpenseRiskScore * 0.05);
        });
        let correspondingreimbursementsRiskScore;
        const reimbursementsRange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    return Math.round(parseFloat(item['% Change post'])) === Math.round(reimbursementsLookupValue) ||
                        Math.round(parseFloat(item['% Change neg'])) === Math.round(reimbursementsLookupValue);
                });

                if (matchingItem) {
                    correspondingreimbursementsRiskScore = reimbursementsLookupValue >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching reimbursements range data:", error);
            }
        }; reimbursementsRange().then(() => {
            setreimbursementsweightscore((correspondingreimbursementsRiskScore * 0.05) / 10);
        });
        let correspondingnetIncomeRiskScore
        const netIncomeRange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    return Math.round(parseFloat(item['% Change post'])) === Math.round(netincomeLookupValue) ||
                        Math.round(parseFloat(item['% Change neg'])) === Math.round(netincomeLookupValue);
                });

                if (matchingItem) {
                    correspondingnetIncomeRiskScore = netincomeLookupValue >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching net income range data:", error);
            }
        }; netIncomeRange().then(() => {
            setnetincomeWeightscore(correspondingnetIncomeRiskScore * 0.125);
        });

        let correspondingpurchaseRiskScore;
        const purchaseRange = async () => {
            try {
                const response = await fetch(apiEndpoints[0]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    return Math.round(parseFloat(item['% Change post'])) === Math.round(purchaseLookupValue * 100) ||
                        Math.round(parseFloat(item['% Change neg'])) === Math.round(purchaseLookupValue * 100);
                });

                if (matchingItem) {
                    correspondingpurchaseRiskScore = (purchaseLookupValue * 100) >= 0 ? matchingItem['Risk Score post'] : matchingItem['Risk Score neg'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching purchase range data:", error);
            }
        }; purchaseRange().then(() => {
            setpurchaseWeightscore(correspondingpurchaseRiskScore * 0.05);
        })
        checkYearRiskScore(year).then(() => {
            setyearstatredweightscore(correspondingyearRiskScore * 0.05);
        })

        let correspondingpropertyRiskScore;
        const PropertyRange = async () => {
            try {
                const response = await fetch(apiEndpoints[2]);
                const data = await response.json();

                const matchingItem = data.find(item => {
                    const volumeWithoutCommas = item['$ (Volume)'].replace(/,/g, '');
                    return volumeWithoutCommas === "$" + PropertLookupValue;
                });

                if (matchingItem) {
                    correspondingpropertyRiskScore = matchingItem['Risk Score (Volume)'];
                } else {
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }; PropertyRange().then(() => {
            setallpropweightscore(correspondingpropertyRiskScore * 0.05);
        })


        let correspondingPortfolioRiskScore;
        const PortfolioRange = async () => {
            try {
                const response = await fetch(apiEndpoints[2]);
                const data = await response.json();
                const matchingItem = data.find(item => {
                    const integerPart = parseInt(PortfolioSizeLookupValue);

                    const percentageValue = parseFloat(item['% Change  (Portfolio Size)']);
                    const integerPartData = parseInt(percentageValue);
                    return integerPart === integerPartData;
                });

                if (matchingItem) {
                    correspondingPortfolioRiskScore = matchingItem['Risk Score (Portfolio Size)'];
                  
                } else {
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        PortfolioRange().then(() => {
            setpropertsizeweightscore(correspondingPortfolioRiskScore * 0.05);
        })

        const scorelosses = purchaselosses > 0 ? 1 : 10;
        setproperlossessizeweightscore(scorelosses * 0.05);
        const offering = offeringmaterials;
        setofferingmaterialweightscore(offering * 0.05);

        let correspondinginvestorScore;
        const investorRange = async () => {
            try {
                const response = await fetch(apiEndpoints[2]);
                const data = await response.json();
                if (investorLookupValue < 0) {
                    correspondinginvestorScore = 1;
                } else {
                    const matchingItem = data.find(item => {
                        return Math.round(parseFloat(item['% Change ( Co-Invest)'])) === Math.round(investorLookupValue);
                    });

                    if (matchingItem) {
                        correspondinginvestorScore = matchingItem['Risk Score ( Co-Invest)'];
              
                    } else {
                    }
                }
            } catch (error) {
                console.error("Error fetching purchase range data:", error);
            }
        };
        investorRange().then(() => {
            setinvestequityweightscore(correspondinginvestorScore * 0.10);
        });
        setData(true);
    }

    let totalWeightedScore = 0;

    if (capweightscore !== null && rentweightscore !== null && leaseweightscore !== null && occupanycyweightscore !== null
        && grossweightscore !== null && exponseweightscore !== null && reimbursementsweightscore !== null && netincomeWeightscore !== null
        && purchaseWeightscore !== null && allpropweightscore !== null && yearstatredweightscore !== null && propertsizeweightscore !== null && properlossessizeweightscore !== null
        && offeringmaterialweightscore !== null && investequityweightscore !== null) {

        totalWeightedScore = capweightscore + rentweightscore + leaseweightscore + occupanycyweightscore +
            grossweightscore + exponseweightscore + reimbursementsweightscore + netincomeWeightscore +
            purchaseWeightscore + allpropweightscore + yearstatredweightscore + propertsizeweightscore + properlossessizeweightscore +
            offeringmaterialweightscore + investequityweightscore;
           
    } else {
    }

    let finalscore = Math.round(totalWeightedScore * 10);
    const [gradevalue, setGradevalue] = useState(null);

    const calculateGrade = async () => {

        try {
            const response = await fetch(apiEndpoints[0]);
            const data = await response.json();

            const matchingItem = data.find(item => {
                return Math.abs(parseFloat(item['Score']) - finalscore) < 0.0001;
            });

            if (matchingItem) {
                setGradevalue(matchingItem['Grade']);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    calculateGrade();



    const avgren = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Average rents at the property on either a per unit or square footage basis
        </Popover>
    );
    const occup = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            If you need to cancel click here.
        </Popover>
    );
    const weighted = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            For Office, Retail, Industrial, or Other Commercial property types.  Enter 0 for both Purchase and Sale if Multifamily.
        </Popover>
    );
    const gross = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            The maximum potential revenue the property can generate at 100% occupancy including base rent, percentage rent, reimbursement, and any other income.
        </Popover>
    );

    const Reimbursements = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Enter 0 for both Purchase and Sale if not applicable.
        </Popover>
    );

    const purchasecap = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Total of Purchase Price and all Capital Expenditures during the hold period.        </Popover>
    );
    const salep = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Total Gross Sale Price.
        </Popover>
    );
    const yearpop = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            First year when a Principal of the Sponsor had equity at risk and was in a position of control on a real estate investment.
        </Popover>
    );
    const allproper = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Total $ value of all properties owned historically or currently by all Principals of Sponsor.
        </Popover>
    );
    const loss = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Number of properties where the Principals of the Sponsor have been in a position of control and lost equity.
        </Popover>
    );
    const material = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            10 is Highest Quality. 1 is Lowest Quality
        </Popover>
    );
    const fee = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Total of all Fees going to Sponsor and Sponsor related entities at close of escrow.
        </Popover>
    );
    const equity = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Total equity being raised from third party investors.
        </Popover>
    );
    const Coinvest = (
        <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
            Total equity being invested by Principals of the Sponsor and Employees, if any. Excludes equity raised from and invested by Third Party investors.
        </Popover>
    );
    return (
        <MainPageLayout heading="Real Estate Risk Score"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
                height: '250px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
            <div className='my-4 mx-auto w-auto'>
                <div className=' grid-col-2 xl:flex md:flex mx-2 gap-3  justify-center my-5 mt-6'>
                    <div className='border-[1px] border[rgba(0, 0, 0, 0.28)] rounded-[17px] p-2 mt-3'>
                        <p className='text-[#060606] font-semibold text-[19px] my-2 text-start px-2'> PROPERTY</p>
                        <div className='flex flex-col-3 '>
                            <div>
                                <p className='text-[#343A40] text-[17px] my-2 text-start px-2'>Property</p>
                                <p className='bg-yellow xl:text-base xs:text-xs xl:h-10 xs:h-10 xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between ' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Cap Rate</p>
                                <div className='bg-yellow xl:text-base xs:text-xs xl:h-10 xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Avg. Rents
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        overlay={avgren}
                                    >
                                        <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                    </OverlayTrigger>
                                </div>
                                <div></div>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Occupancy
                                </p>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Weighted Average Lease Term
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        overlay={weighted}
                                    >
                                        <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                    </OverlayTrigger></p>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Gross Potential Revenue
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        overlay={gross}
                                    >
                                        <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                    </OverlayTrigger></p>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Operating Expenses

                                </p>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>
                                    Op Ex Reimbursements
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        overlay={Reimbursements}
                                    >
                                        <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                    </OverlayTrigger>
                                </p>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}> Net Operating Income

                                </p>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Purchase Price + Cap Ex
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        overlay={purchasecap}
                                    >
                                        <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                    </OverlayTrigger></p>
                                <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Sale Price
                                    <OverlayTrigger
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        overlay={salep}
                                    >
                                        <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                    </OverlayTrigger></p>
                            </div>
                            <div>
                                <p className='text-[#343A40] text-[17px] my-2 text-start px-2'>Purchase</p>
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={purchaseValue}
                                    onChange={(e) => handelPercChange(e, "cappurchase")}

                                />
                                <Input
                                    type="text"
                                    placeholder=""

                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={avgRentPurchase}
                                    onChange={(e) => handelCurrencyChange(e, "rentpurchase")}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={occupancyPurchase}
                                    onChange={(e) => handelPercChange(e, "occupancypurchase")}
                                />
                                <Input
                                    type="number"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={leaseTeamPurchase}
                                    onChange={(e) => setLeaseTeamPurchase(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={grossRevenuePurchase}
                                    onChange={(e) => handelCurrencyChange(e, "grosspurchase")}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={expensesPurchase}
                                    onChange={(e) => handelCurrencyChange(e, "expensespurchase")}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={reimbursementsPurchase}
                                    onChange={(e) => handelCurrencyChange(e, "reimbursementpurchase")}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={netOperatingIncomePurchase}
                                    onChange={(e) => handelCurrencyChange(e, "netoperatingpurchase")}

                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={purchase}
                                    onChange={(e) => handelCurrencyChange(e, "purchasePrice")}

                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={salePrice}
                                    onChange={(e) => handelCurrencyChange(e, "salePrice")}

                                />
                            </div>

                            <div>
                                <p className='text-[#343A40] text-[17px] my-2 text-start px-2'>Sale</p>
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={saleValue}
                                    onChange={(e) => handelPercChange(e, "capsale")}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={avgRentSale}
                                    onChange={(e) => handelCurrencyChange(e, "rentsale")}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={occupancySale}
                                    onChange={(e) => handelPercChange(e, "occupancysale")}
                                />
                                <Input
                                    type="number"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={leaseTeamSale}
                                    onChange={(e) => setLeaseTeamSale(e.target.value)}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={grossRevenueSale}
                                    onChange={(e) => handelCurrencyChange(e, "grosssale")}
                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={expensesSale}
                                    onChange={(e) => handelCurrencyChange(e, "expensesSale")}

                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={reimbursementsSale}
                                    onChange={(e) => handelCurrencyChange(e, "reimbursementsale")}

                                />
                                <Input
                                    type="text"
                                    placeholder=""
                                    className="xl:w-52 xs:w-16 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] my-1 mx-2"
                                    value={netOperatingIncomeSale}
                                    onChange={(e) => handelCurrencyChange(e, "netoperatingsale")}

                                />
                            </div>
                        </div>
                    </div>
                    <div className=''>
                        <div className='border-[1px] border[rgba(0, 0, 0, 0.28)] rounded-[17px] p-2 mb-3 mt-3'>
                            <p className='text-[#060606] font-semibold text-start text-[19px] my-2 px-2'> SPONSOR </p>
                            <div className='flex flex-col-2  mt-8'>
                                <div>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Year Started as Principal
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            overlay={yearpop}
                                        >
                                            <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                        </OverlayTrigger></p>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>$ Value of All Properties
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            overlay={allproper}
                                        >
                                            <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                        </OverlayTrigger></p>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Property Size vs. Portfolio Size
                                    </p>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}># of Properties with Losses
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            overlay={loss}
                                        >
                                            <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                        </OverlayTrigger> </p>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Quality of Offering Materials
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            overlay={material}
                                        >
                                            <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                        </OverlayTrigger> </p>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Sponsor Fees ($)
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            overlay={fee}
                                        >
                                            <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                        </OverlayTrigger> </p>
                                </div>
                                <div>
                                    <Input id=" " type="number" placeholder="" className=" w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42]  my-1 mx-2"
                                        value={year}
                                        onChange={handleYearChange} />

                                    <Input id=" " type="text" placeholder="" className=" w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42]  my-1 mx-2"
                                        value={Valueallproperty}
                                        onChange={(e) => handelCurrencyChange(e, "Valueallproperty")}
                                    />
                                    <p className="my-1 w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42] mx-2">
                                        {isNaN(PortfolioSize) ? 0 :( PortfolioSize).toFixed(2)}%
                                    </p>
                                    <Input id=" " type="number" placeholder="" className=" w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42]  my-1 mx-2"
                                        value={losses}
                                        onChange={(e) => setlosses(e.target.value)} />
                                    <Input id=" " type="number" placeholder="" className=" w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42]  my-1 mx-2"
                                        value={offeringmaterials}
                                        onChange={(e) => setofferingmaterials(e.target.value)} />
                                    <Input id=" " type="text" placeholder="" className=" w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42]  my-1 mx-2"
                                        value={sponserfee}
                                        onChange={(e) => handelCurrencyChange(e, "sponserfee")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                        <div className='border-[1px] border[rgba(0, 0, 0, 0.28)] rounded-[17px] p-2'>
                            <p className='text-[#060606] font-semibold text-[19px] my-2 text-start px-2'> EQUITY </p>
                            <div className='flex flex-col-2  mt-8'>
                                <div>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Investor Equity
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            overlay={equity}
                                        >
                                            <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                        </OverlayTrigger></p>
                                    <p className='bg-yellow xl:text-base xs:text-xs  xs:w-44 xl:w-72 text-start px-2 py-2 text-white rounded-[6px] my-1.5 mx-2 flex justify-between' style={{ boxShadow: '0px 2.452px 6.131px 0px rgba(0, 0, 0, 0.10)' }}>Sponsor Co-Invest  ($)
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="right"
                                            overlay={Coinvest}
                                        >
                                            <img src={i} alt="" className='h-6 w-6 pt-1 cursor-pointer' />
                                        </OverlayTrigger></p>
                                </div>
                                <div>
                                    <Input id=" " type="text" placeholder="" className=" w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42]  my-1 mx-2"
                                        value={invertorEquity}
                                        onChange={(e) => handelCurrencyChange(e, "invertorEquity")}
                                    />
                                    <Input id=" " type="text" placeholder="" className=" w-36 text-start px-2 py-1.5 text-black rounded-[6px] border-[1px] border-[#625D42]  my-1 mx-2"
                                        value={coInvest}
                                        onChange={(e) => handelCurrencyChange(e, "coInvest")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div>
                    <button onClick={calculate} className='rounded-[6px] hover:text-black text-white text-lg bg-yellow px-4 py-2'>
                        Calculate
                    </button>
                </div>
                {Data && <div >
                    <div className='flex justify-center mx-3 my-3'>
                        <div className='border-[1px] text-start border[rgba(0, 0, 0, 0.28)] w-56 rounded-[17px] p-3 mx-1'>
                            <p className='text-[#343A40]  font-medium text-2xl '>Total Score:</p>
                            {isLoading ? (
                                <div className='flex justify-center'>
                                    <img src={spinner} alt='loading' className='h-20 w-20' />
                                </div>
                            ) : (<p className='text-[#625D42] font-bold text-6xl'>
                                {finalscore !== null ? finalscore : "Loading..."}

                            </p>)}
                        </div>
                        <div className='border-[1px] text-start border[rgba(0, 0, 0, 0.28)] w-56 rounded-[17px] p-3 mx-1'>
                            <p className='text-[#343A40] font-medium text-2xl '>Grade:</p>
                            <div>
                                {isLoading ? (
                                    <div className='flex justify-center'>
                                        <img src={spinner} alt='loading' className='h-20 w-20' />
                                    </div>
                                ) : (
                                    gradevalue !== null ? (
                                        <p className='text-[#625D42] font-bold text-6xl'>{gradevalue}</p>
                                    ) : (
                                        <p className='text-[#625D42] font-bold text-6xl'>Nill</p>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        </MainPageLayout>
    )
}
