import React, { useState, useRef, useEffect } from 'react';
import mission from '../Assets/Images/mission.png'
import leftarrow from '../Assets/Icons/leftarrow.svg'
import { Link } from 'react-router-dom'
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import email from "../Assets/Images/email.gif"
import update from "../Assets/Images/update.gif"
import close from '../Assets/Images/close.png'

export default function Mission() {
    const base_url = process.env.REACT_APP_BASE_URL;
    const cancelButtonRef = useRef(null);
    const [formdata, setFormdata] = useState({ email: '', message: '' });
    const [open, setOpen] = useState(false);

    const handleEmail = (e) => {
        const { name, value } = e.target;
        setFormdata(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const res = await fetch(`${base_url}/api/v1/convertkitSubscribe`, {
            body: JSON.stringify({ email: formdata.email }),
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            method: 'POST',
        })
        const data = await res.json();
        if (res.status === 200) {
            showModal(false)
            setOpen(true);
            setFormdata({ email: '', message: '' });
        } else {
            toast.error(data.message);
        }
        setFormdata(prevData => ({
            ...prevData,
            message: data.message
        }));
    }

    const handleClose = () => {
        setOpen(false);
    }
    const [Modal, showModal] = useState(false);
    const openModal = () => {
        showModal(true);
    }

    const closeModal = () => {
        showModal(false)
    }
    return (
        <div className="bg-white flex flex-col my-2">
            <div className='flex '>
                <div className='w-full'>
                    <div className=' xl:flex lg:flex xs:hidden text-start my-3 .custom-container flex px-5 '>
                        <div className='xl:w-[55rem] 2xl:w-[70rem] lg:w-[45rem]  xl:px-16 xs:p-4'>
                            <p className='text-[#343A40] xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg font-bold my-2 uppercase '>Mission Statement</p>
                            <p className=' xl:text-xl lg:text-xl md:text-xl xs:text-xs text-[#616161]'>Investing Made Accessible: At The Crowdfund Investor, we strive to redefine the landscape of crowdfunding investments, enabling both accredited and non-accredited investors to leverage the power of crowdfunding. Our mission is to bridge the information gap in alternative investments and foster a community of informed, confident, and strategic investors.
                            </p>
                        </div>
                    </div>
                    <div className=' xs:relative lg:hidden xl:hidden text-start   flex px-5 '
                        style={{
                            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${mission})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}>
                        <div className='xl:w-[60rem] lg:w-[40rem]  xl:p-16 xs:p-2'>
                            <p className='text-[white] xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg font-bold my-2 uppercase'>Mission Statement</p>
                            <p className=' xl:text-xl lg:text-xl md:text-xl xs:text-xs text-[white]'>Investing Made Accessible: At The Crowdfund Investor, we strive to redefine the landscape of crowdfunding investments, enabling both accredited and non-accredited investors to leverage the power of crowdfunding. Our mission is to bridge the information gap in alternative investments and foster a community of informed, confident, and strategic investors.
                            </p>
                        </div>
                    </div>
                    <div className='text-start mb-2 bg-bg px-5 flex xl:h-[13rem] lg:h-[13rem]'>
                        <div className='xl:w-[55rem] 2xl:w-[70rem] lg:w-[45rem] xl:px-16 xs:p-2 mr-10'>
                            <p className='text-yellow xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg font-bold my-2 uppercase'>Vision Statement:</p>
                            <p className='text-yellow  xl:text-xl lg:text-xl md:text-xl xs:text-xs '>Empowering Informed Decisions: Our vision at TheCrowdfundInvestor.com is to become the leading resource and community for self-directed investors interested in crowdfunding. We aspire to create a digital environment where accurate information, insightful analysis, and interactive learning help investors make informed decisions, fostering financial success and independence.</p>
                        </div>
                        <img src={mission} alt='' className='xl:relative lg:flex lg:relative xl:flex xs:hidden xl:left-[0rem] xl:bottom-[15rem] lg:bottom-[15rem] xl:h-[420px] lg:h-[380px] lg:left-3 ' />
                    </div>
                </div>
            </div>
            

            <div className="items-center flex gap-1  mt-7 self-end  px-3 md:px-20">
                <div className="text-black xl:text-3xl xs:text-2xl font-bold leading-10 capitalize grow whitespace-nowrap my-auto">
                Get Updates
                </div>
                <button>
                    <img
                        src={leftarrow}
                        onClick={openModal}
                        alt=""
                        className="cursor-pointer aspect-[1.32] object-contain object-center w-[50%] lg:w-[80px] overflow-hidden self-stretch shrink-0 max-w-full"
                    />
                </button>
            </div>
            {Modal &&
                <Transition.Root show={Modal} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-10"
                        initialFocus={cancelButtonRef}
                        onClose={handleClose}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        <div className="fixed inset-0 lg:mb-16 xs:mb-16 z-10 w-screen overflow-y-auto">
                            <div className="flex min-h-full items-end  justify-center p-4  sm:items-center sm:p-0">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                >
                                    <Dialog.Panel className="bg-white rounded-lg p-4 w-80 sm:w-96">
                                        <div className="flex justify-end">
                                            <button onClick={closeModal} >
                                                <img src={close} alt='close' />
                                            </button>
                                        </div>
                                        <div className=' text-center text-base font-medium w-auto'>
                                            <div className='flex justify-center my-2'>
                                                <img src={update} className='h-24 w-24' />
                                            </div>
                                            <p className='my-2'>Get Updates On our exciting News</p>
                                            <form onSubmit={submitForm} className=" flex gap-1 justify-center my-2 mt-5">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    value={formdata.email}
                                                    onChange={handleEmail}
                                                    className="form-control bg-white  lg:w-72 md:w-64 sm:w-48 xs:w-64 px-2 text-sm text-black border-gray-500 border-[0.98px] "
                                                    placeholder="Enter Your Email"
                                                    style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px', '::placeholder': { color: 'black' }, WebkitTextFillColor: 'black' }} required
                                                />

                                                <div className="cursor-pointer ">
                                                    <button className="bg-yellow px-4 flex justify-start py-1 text-white text-sm font-medium hover:text-black" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                                                        Get Update
                                                    </button>
                                                </div>

                                            </form>

                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>
            }

            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 overflow-y-auto z-50"
                    initialFocus={cancelButtonRef}
                    onClose={handleClose}
                >
                    <div className="flex items-center justify-center min-h-screen">
                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="bg-white rounded-lg p-4 w-80 sm:w-96" ref={cancelButtonRef}>

                                <div className="flex justify-end">
                                    <button onClick={handleClose} >
                                        <img src={close} alt='close' />
                                    </button>
                                </div>
                                <div className=' text-center text-base font-medium'>
                                    <div className='flex justify-center'>
                                        <img src={email} />
                                    </div>
                                    <p>{formdata.message}</p>
                                    <p>Thank you for connecting with us.</p>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

        </div>
    )
}
