import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import dropdown from '../Assets/Icons/dropdown.svg'
import Search from '../Components/Search'
import MainPageLayout from '../Pages/Layouts/MainPageLayout';
import bg from '../Assets/Images/bg.png';
import PaginationButtons from '../Components/PaginationButtons';
import Loader from '../Components/LinearProgress';
import { db } from '../firebase';
import { doc, getDocs, setDoc, getDoc, updateDoc, collection } from 'firebase/firestore';
import TrackInvertedSlider from '../Components/TrackInvertedSlider';
const base_url = process.env.REACT_APP_BASE_URL;
const Token = process.env.REACT_APP_OFFERING_PLATFORM_LINKS;


export default function RegCF() {
    const [filterVisible, setFilterVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const filterRef = useRef(null);
    const showFilterMsg = () => {
        setFilterVisible(!filterVisible);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [URL, setURL] = useState([]);

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${base_url}/api/form/FormC`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json"
                    },

                });
                const result = await response.json();
                setURL(result.message.filings);
            } catch (error) {
            }
        };

        fetchData();
    }, []);

    const [filerData, setFilerData] = useState([]);
    useEffect(() => {
        const fetchTxtData = async () => {
            if (URL.length === 0) return;

            try {
                const dataPromises = URL.map(async (filling) => {
                    const response = await fetch(`${base_url}/api/v1/secdata`, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json"
                        },
                        body: JSON.stringify({ url: filling.linkToTxt })

                    });
                    const data = await response.json();
                    ;
                    return extractFilerData(data.response);
                });

                const filerDataArray = await Promise.all(dataPromises);
                setFilerData(filerDataArray.flat());
            } catch (error) {
                // console.error('Error fetching TXT data:', error);
            }
        };

        fetchTxtData();
    }, [URL]);

    const extractFilerData = async (textData) => {
        const filerDataArray = [];

        const formDataRegex = /<formData>(.*?)<\/formData>/gs;
        let formDataMatch;
        const filedDateMatch = /FILED AS OF DATE:\s+(.*?)\n/.exec(textData);
        let Date = filedDateMatch ? filedDateMatch[1] : null;

        if (Date) {
            Date = Date.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
        }

        while ((formDataMatch = formDataRegex.exec(textData)) !== null) {
            const formDataContent = formDataMatch[1];
            const issuerInfoRegex = /<issuerInformation>(.*?)<\/issuerInformation>/gs;
            let issuerInfoMatch;
            while ((issuerInfoMatch = issuerInfoRegex.exec(formDataContent)) !== null) {
                const issuerInfoContent = issuerInfoMatch[1];
                const companyNameMatch = /<companyName>(.*?)<\/companyName>/.exec(issuerInfoContent);
                const companyName = companyNameMatch ? companyNameMatch[1] : null;
                const nameOfIssuerMatch = /<nameOfIssuer>(.*?)<\/nameOfIssuer>/.exec(issuerInfoContent);
                const nameOfIssuer = nameOfIssuerMatch ? nameOfIssuerMatch[1] : null;
                const issuerWebsiteMatch = /<issuerWebsite>(.*?)<\/issuerWebsite>/.exec(issuerInfoContent);
                const issuerWebsite = issuerWebsiteMatch ? issuerWebsiteMatch[1] : null;
                const annualReportRegex = /<annualReportDisclosureRequirements>(.*?)<\/annualReportDisclosureRequirements>/gs;
                let annualReportMatch;
                let TotalAssect = null;
                let Income = null;
                while ((annualReportMatch = annualReportRegex.exec(formDataContent)) !== null) {
                    const annualReportContent = annualReportMatch[1];
                    const AssectMatch = /<totalAssetMostRecentFiscalYear>(.*?)<\/totalAssetMostRecentFiscalYear>/.exec(annualReportContent);
                    TotalAssect = AssectMatch ? AssectMatch[1] : null;

                    const IncomeMatch = /<netIncomeMostRecentFiscalYear>(.*?)<\/netIncomeMostRecentFiscalYear>/.exec(annualReportContent);
                    Income = IncomeMatch ? IncomeMatch[1] : null;
                }

                TotalAssect = TotalAssect ?? 0;
                Income = Income ?? 0;
                filerDataArray.push({
                    companyName,
                    nameOfIssuer,
                    issuerWebsite,
                    TotalAssect,
                    Income,
                    Date
                });
            }
        }

        const apiResponse = await fetch('https://sheet.best/api/sheets/0b1b6d08-239b-4ddb-ad7e-7c070fd83e13');
        const apiData = await apiResponse.json();
        for (const item of filerDataArray) {
            const matchingApiItem = apiData.find(apiItem => {
                const normalizedApiName = apiItem['Offering Platform'].toLowerCase().trim();
                const normalizedDbName = item.companyName.toLowerCase().trim();
                return normalizedDbName.includes(normalizedApiName) || normalizedApiName.includes(normalizedDbName);
            });
            if (matchingApiItem && matchingApiItem['Offering Platform Link']) {
                item.LinkOfOfferingPlatform = matchingApiItem['Offering Platform Link'];

            } else {
            }
        }

        await Promise.all(filerDataArray.map(async (item) => {
            // console.log(`Processing item: ${item.nameOfIssuer}`);
            if (item.nameOfIssuer) {
                const docRef = doc(collection(db, 'Form C'), item.nameOfIssuer);
                const docSnap = await getDoc(docRef);
                if (!docSnap.exists()) {
                    // Document does not exist, create it
                    await setDoc(docRef, {
                        company: item.companyName,
                        Name_Of_Issuer: item.nameOfIssuer,
                        Issuer_Website: item.issuerWebsite,
                        LinkOfOfferingPlatform: item.LinkOfOfferingPlatform || " ",
                        Assect: item.TotalAssect,
                        NetIncome: item.Income,
                        FiledDate: item.Date
                    }, { merge: true });
                    // console.log(`Document created for ${item.nameOfIssuer}:`);
                } else {
                    // Document exists, update it
                    await updateDoc(docRef, {
                        company: item.companyName,
                        Name_Of_Issuer: item.nameOfIssuer,
                        Issuer_Website: item.issuerWebsite,
                        LinkOfOfferingPlatform: item.LinkOfOfferingPlatform,
                        Assect: item.TotalAssect,
                        NetIncome: item.Income,
                        FiledDate: item.Date
                    });
                    // console.log(`Document updated for ${item.nameOfIssuer}, ${item.Date}:`);
                }
            }
        }));

        return filerDataArray;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Form C'));
                const data = querySnapshot.docs.map(doc => doc.data());
                setFilerData(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    function dateDiffInDays(date1, date2) {
        // Convert both dates to milliseconds
        const date1_ms = date1.getTime();
        const date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        const difference_ms = Math.abs(date2_ms - date1_ms);

        // Convert back to days and return
        return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
    }

    const formatDate = (date) => {
        const currentDate = new Date();
        const inputDate = new Date(date);

        const diffDays = dateDiffInDays(currentDate, inputDate);
        const diffMonths = Math.abs(currentDate.getMonth() - inputDate.getMonth()) +
            (12 * (currentDate.getFullYear() - inputDate.getFullYear()));

        if (diffDays <= 7) {
            return '1 Week';
        } else if (diffDays <= 30) {
            return '1 Month';
        } else if (diffMonths <= 3) {
            return '3 Months';
        } else {
            return 'Year';
        }
    };

    const itemsPerPage = 25;
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedNetIncome, setSelectedNetIncome] = useState('');
    const [sliderValues, setSliderValues] = useState([0, 100]);
    const [sliderUsed, setSliderUsed] = useState(false);

    const uniqueCompanyNames = [...new Set(filerData.map(filer => filer.company))];
    const uniqueDate = Array.from(new Set(filerData.map(filer => formatDate(filer.FiledDate))));
    const sortOrder = ['1 Week', '1 Month', '3 Months', 'Year'];
    uniqueDate.sort((a, b) => {
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    });

    const handleCompanyNameFilterChange = (e) => {
        setSelectedCompanyName(e.target.value);
    };

    const handleDateFilterChange = (e) => {
        setSelectedDate(e.target.value);
    };
    const handleNetIncomeFilterChange = (e) => {
        setSelectedNetIncome(e.target.value);
    };
    const checkNetIncome = (netIncome, selectedOption) => {
        if (selectedOption === 'lessThanZero') {
            return parseFloat(netIncome) < 0;
        } else if (selectedOption === 'moreThanZero') {
            return parseFloat(netIncome) >= 0;
        }
        return true;
    };



    const minRevenue = Math.min(...filerData.map(filer => filer.Assect));
    const maxRevenue = Math.max(...filerData.map(filer => filer.Assect));

    const handleSliderChange = (values) => {
        setSliderValues(values);
        setSliderUsed(true);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const filteredDataWithoutSlider = filerData.filter(filer => (
        (!selectedCompanyName || filer.company === selectedCompanyName) &&
        (!selectedDate || formatDate(filer.FiledDate) === selectedDate) &&
        (!selectedNetIncome || checkNetIncome(filer.NetIncome, selectedNetIncome)) &&
        (!searchTerm || filer.company && filer.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
            filer.Name_Of_Issuer && filer.Name_Of_Issuer.toLowerCase().includes(searchTerm.toLowerCase()))
    ));

    const filteredDataWithSlider = sliderUsed
        ? filteredDataWithoutSlider.filter(filer => {
            const totalRevenues = parseFloat(filer.Assect);
            return totalRevenues >= sliderValues[0] && totalRevenues <= sliderValues[1];
        })
        : filteredDataWithoutSlider;

    const filteredData = filteredDataWithSlider;
    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    const displayedData = filteredData.slice(startIndex, endIndex);


    function formatWebsiteUrl(url) {
        if (!/^https?:\/\//i.test(url)) {
            if (url.startsWith('www.')) {
                return `https://${url}`;
            }
            return `https://www.${url}`;
        }
        return url;
    }


    const groupedByWeek = uniqueDate.reduce((acc, date) => {
        const weekStart = new Date(date);
        weekStart.setDate(weekStart.getDate() - weekStart.getDay());
        if (!isNaN(weekStart.getTime())) {
            const weekKey = weekStart.toISOString().split('T')[0];
            if (!acc[weekKey]) {
                acc[weekKey] = [];
            }
            acc[weekKey].push(date);
        }
        return acc;
    }, {});

    const options = Object.entries(groupedByWeek).map(([weekKey, dates]) => {
        const now = new Date();
        const then = new Date(weekKey);
        if (!isNaN(then.getTime())) {
            const diffInMilliseconds = now - then;
            const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
            const diffInWeeks = Math.floor(diffInDays / 7);
            const diffInMonths = Math.floor(diffInDays / 30);

            let relativeTime;
            if (diffInMonths > 0) {
                relativeTime = new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-diffInMonths, 'month');
            } else if (diffInWeeks > 0) {
                relativeTime = new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-diffInWeeks, 'week');
            } else if (diffInDays > 0) {
                relativeTime = new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-diffInDays, 'day');
            } else {
                relativeTime = 'Today';
            }

            return (
                <option key={weekKey} value={dates[0]}>
                    {relativeTime}
                </option>
            );
        }
        return null;
    }).filter(option => option !== null);
    const resetFilters = () => {
        setSelectedCompanyName('');
        setSelectedDate('');
        setSelectedNetIncome('');
        setSelectedDate('');
        setSliderValues([0, 100]);
        setSliderUsed(false);
    };
    return (
        <MainPageLayout heading="Reg CF Offerings"
            text="THE CROWDFUND INVESTOR CENTRALIZES ALL REG CF OFFERINGS INTO ONE LOCATION RESOURCE, ALLOWING INVESTORS TO FOCUS THEIR EFFORTS ON THE OFFERINGS THAT INTEREST THEM MOST"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
                height: '368px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
        >
            <div>
                <div className='flex justify-between mx-5 my-4 '>
                    <div className='flex justify-start w-72 '>
                        <Search placeholder="Search "
                            popovertxt="Search by Issuer Name & Offering Platform "
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="relative" ref={filterRef}>
                        <div className="inline-flex items-center overflow-hidden  bg-white xl:mx-3 xs:mx-2 flex-" >
                            <button
                                onClick={showFilterMsg}
                                className="border-[#625D42] border-[1px] rounded-[7px] xl:px-3 xl:py-1 xs:p-2 xl:text-xl xs:text-base  flex text-[#625D42]"
                            >
                                Filter
                                <img src={dropdown} alt='' className=' xl:w-6 xl:h-5 xs:w-3 xs:h-5 xs:mx-2 xl:mx-0 xl:pt-3 xs:pt-2  ' />

                            </button>
                        </div>

                        {filterVisible && (
                            <div
                                className="absolute end-0 z-10 mt-2 xl:w-auto xs:w-72 lg:w-auto md:w-auto  divide-y divide-gray-100 rounded-xl border border-gray-100 bg-white "
                                role="menu"
                                style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
                            >
                                <div className='text-start p-9 '>

                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Date Filed</div>
                                    <div className='my-1'>
                                        <select
                                            className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                                            onChange={handleDateFilterChange}
                                            value={selectedDate}
                                        >
                                            <option value="">All date</option>
                                            {uniqueDate.map((date, index) => (
                                                <option key={index} value={date}>{date}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Offering Platform</div>
                                    <div className='my-1'>
                                        <select
                                            className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1 md:w-auto w-52'
                                            onChange={handleCompanyNameFilterChange}
                                            value={selectedCompanyName}
                                        >
                                            <option value="">All</option>
                                            {uniqueCompanyNames.map((companyName, index) => (
                                                <option key={index} value={companyName}>{companyName}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Assets Size</div>
                                    <div className='my-2 mx-4 pt-5'>
                                        <TrackInvertedSlider minRevenues={minRevenue} maxRevenues={maxRevenue} onChange={handleSliderChange} />

                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Net Income</div>
                                    <div className='my-1 flex mb-8'>
                                        <div className="flex items-center mx-2">
                                            <input
                                                id="default-radio-1"
                                                type="radio"
                                                value="lessThanZero"
                                                name="netIncome"
                                                className="w-4 h-4 text-custom-color bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                onChange={handleNetIncomeFilterChange}
                                                checked={selectedNetIncome === 'lessThanZero'}
                                            />
                                            <label
                                                htmlFor="default-radio-1"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                Less than $0
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="default-radio-2"
                                                type="radio"
                                                value="moreThanZero"
                                                name="netIncome"
                                                className="w-4 h-4 text-custom-color bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                onChange={handleNetIncomeFilterChange}
                                                checked={selectedNetIncome === 'moreThanZero'}
                                            />
                                            <label
                                                htmlFor="default-radio-2"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                More than $0
                                            </label>
                                        </div>
                                    </div>
                                    <div className='flex justify-center'>
                                        <button type="submit" className="bg-yellow px-2 xl:w-[100px] xs:w-[70px] flex justify-center py-2.5 text-white xl:text-lg xs:text-sm font-medium hover:text-black"
                                            style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}
                                            onClick={resetFilters} >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>

                {loading &&
                    <div className="mx-10 pt-32 items-center h-[50vh]">
                        <p className='text-lg my-2 font-medium'>Loading ....</p>
                        <div className='flex justify-center items-center'>
                            <Loader />
                        </div>
                    </div>}
                {error &&
                    <div className='text-center mx-10 mt-16 h-[50vh]'>
                        <div className='flex justify-center'>
                            <p className='text-yellow text-3xl font-medium'>Error:</p>
                            <p className='text-black mx-2 text-3xl font-medium'>{error}</p>
                        </div>
                        <p className='text-black mx-2 text-4xl font-medium my-2'>Please try again later</p>
                    </div>
                }

                {!loading && !error && (
                    <div className='mx-auto xl:w-full px-7 h-auto my-3 '>
                        {displayedData.length === 0 ? (
                            <div className="mx-10 items-center h-[70vh]">
                                <p className='text-xl my-2 font-medium '>No matching results found.</p>
                            </div>
                        ) : (
                            <>
                                <div className="overflow-x-auto rounded-lg border-none">
                                    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
                                        <thead className="ltr:text-left rtl:text-right">
                                            <tr className=''>
                                                <th className="whitespace-nowrap xs:px-2  py-2 text-lg font-semibold text-[#060606] "></th>

                                                <th className="whitespace-nowrap xs:text-sm xl:text-lg md:text-[15px]  xs:px-2 py-2 text-lg font-semibold text-[#060606]">Name of issuer </th>
                                                <th className="whitespace-nowrap xs:text-sm xl:text-lg md:text-[15px]  xs:px-2 text-lg font-semibold py-2  text-[#060606]">Offering Platform</th>
                                                <th className="whitespace-nowrap xs:text-sm xl:text-lg md:text-[15px]  xs:px-2 text-lg font-semibold py-2  text-[#060606]">Offering Platform Link</th>
                                                <th className="whitespace-nowrap xs:text-sm xl:text-lg md:text-[15px] xs:px-2  py-2 text-lg font-semibold text-[#060606]">Company Website</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y  divide-gray-200">
                                            {displayedData.map((filer, index) => (
                                                <tr key={index}>
                                                    <td className=" w-6 py-2 xs:px-2 text-base text-[#060606]">
                                                        <div className='mx-1 bg-[#625D42] h-2 w-2'></div>
                                                    </td>
                                                    <td className="w-44 py-3  xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]">{filer.Name_Of_Issuer}</td>
                                                    <td className="w-44 py-3  xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]">{filer.company}</td>
                                                    <td className="w-44 py-3  xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base ">
                                                        <a href={filer.LinkOfOfferingPlatform} target="_blank" rel="noopener noreferrer" className='underline'>View Link</a>
                                                    </td>
                                                    <td className="w-44 py-3 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]">
                                                        <a href={formatWebsiteUrl(filer.Issuer_Website)} target="_blank" rel="noopener noreferrer" className='underline'>Visit Website</a>
                                                    </td>
                                                    <td className=" hidden xl:w-44  py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                                                        {Number(filer.Assect).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                    </td>
                                                    <td className=" hidden xl:w-44 py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                                                        {Number(filer.NetIncome).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                    </td>
                                                    <td className=" hidden xl:w-44 py-3  xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]">{formatDate(filer.FiledDate)}</td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </table>
                                </div>
                            </>)}
                    </div>
                )}
                <div className='xl:flex justify-between mt-22 mx-2 my-2 '>
                    <div className='flex justify-center xl:mx-5 my-1'>
                        <PaginationButtons count={totalPages} page={currentPage} onChange={handlePageChange} />
                    </div>
                    <div className='flex justify-center gap-2 mx-5 my-1'>
                        <p className='mt-1'>Page</p>
                        <select className='border-[1px] border-[#DDDDDD] rounded-[10px] px-1 cursor-pointer'
                            onChange={(e) => setCurrentPage(Number(e.target.value))} value={currentPage}>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                <option key={page} value={page}>{page}</option>
                            ))}
                        </select>
                        <p className='mt-1'>of {totalPages}</p>
                    </div>
                </div>

            </div>
        </MainPageLayout>

    )
}
