
import ques from '../Assets/Images/ques.png'
import ques2 from '../Assets/Images/ques2.png'

import React, { useState } from 'react';
import down from '../Assets/Icons/down.svg';

export default function Questions() {
    const [activeSection, setActiveSection] = useState(null);

    const toggleAccordion = (section) => {
        setActiveSection(activeSection === section ? null : section);
    };

    const isSectionActive = (section) => activeSection === section;
    return (
        <div className='xl:mx-36 mt-10'>
            <div className='xs:flex lg:hidden md:hidden xl:hidden  '>
                <div className="mx-3 my-3  items-center text-center  ">
                    <img src={ques} className='ml-2' alt='' />
                </div>
                <div className='  font-bold text-start mx-6 xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg mt-6 '>FREQUENTLY  ASKED QUESTIONS? </div>
            </div>
            <div className="bg-white xl:flex lg:flex xs:flex md:flex xl:flex-col-2 xs:flex-col-2 justify-evenly my-5 xl:mx-5 ">

                <div className="mx-2 my-3  xl:flex lg:flex md:flex xs:hidden items-center text-center">
                <img src={ques2}  
                alt='' />
                </div>
                <div className='xs:mx-0 mx-4 '>
                    <div className='font-bold xl:flex  md:flex lg:flex xs:hidden text-start xl:mx-4 xs:mx-2 text-4xl my-4'>FREQUENTLY ASKED QUESTIONS? </div>
                    <div className=" ">
                        <div className=" ">
                            <div className='border-b-[1px] mx-2 '>
                                <div
                                    className={`flex items-center text-start  xl:text-xl lg:text-xl md:text-xl xs:text-sm text-[#616161] justify-between   p-4 cursor-pointer transition ${isSectionActive('section1') ? 'rounded-t-lg' : 'rounded'
                                        }`}
                                    onClick={() => toggleAccordion('section1')}
                                >
                                    <span className='px-2 my-4'>Where do you obtain the data presented throughout the website?</span>
                                    <img
                                        src={down}
                                        alt="Down Arrow"
                                        style={{
                                            transform: isSectionActive('section1') ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        className={`transition mx-2 transform  h-4 w-4 ${isSectionActive('section1') ? 'rotate-180' : ''
                                            }`}
                                    />
                                </div>
                                {isSectionActive('section1') && (
                                    <div className="bg-white p-4  mx-2 transition  xl:text-xl lg:text-xl md:text-xl xs:text-xs text-start  text-[#616161]">
                                        <p>The data has been obtained through two main sources. The first data source is the Securities and Exchange Commission’s EDGAR database. In many cases, this data is then supplemented by additional research by TheCrowdfundInvestor.com’s staff, which has read and researched the offering documents of thousands of offerings over the past five years. We have used this research to create our own proprietary database of offering data.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className='border-b-[1px] mx-2'>
                                <div
                                    className={`flex items-center text-start  xl:text-xl lg:text-xl md:text-xl xs:text-sm text-[#616161] justify-between   p-4 cursor-pointer transition ${isSectionActive('section2') ? 'rounded-b-lg' : 'rounded'
                                        }`}
                                    onClick={() => toggleAccordion('section2')}
                                >
                                    <span className='px-2 my-4'>Have you reviewed all of the Real Estate, Reg CF, and Reg A offerings?</span>
                                    <img
                                        src={down}
                                        style={{
                                            transform: isSectionActive('section2') ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        alt="Down Arrow"
                                        className={`transition mx-2 transform h-4 w-4 ${isSectionActive('section2') ? 'rotate-180' : ''
                                            }`}
                                    />
                                </div>
                                {isSectionActive('section2') && (
                                    <div className="bg-white p-4  mx-2 transition  xl:text-xl lg:text-xl md:text-xl xs:text-xs text-start  text-[#616161]">
                                        <p>No, we have not reviewed all of the offerings displayed. Any references to specific offerings, securities, investments, strategies, or recommendations are for illustrative purposes only and should not be interpreted as endorsements or recommendations to buy, sell, or hold any particular security or investment.
                                        </p>
                                    </div>
                                )}
                            </div>
                            <div className='border-b-[1px] mx-2'>
                                <div
                                    className={`flex items-center text-start  xl:text-xl lg:text-xl md:text-xl xs:text-sm text-[#616161] justify-between   p-4 cursor-pointer transition ${isSectionActive('section3') ? 'rounded-b-lg' : 'rounded'
                                        }`}
                                    onClick={() => toggleAccordion('section3')}
                                >
                                    <span className='px-2 my-4'>What type of alternative investment is appropriate for me?</span>
                                    <img
                                        src={down}
                                        alt="Down Arrow"
                                        style={{
                                            transform: isSectionActive('section3') ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        className={`transition mx-2 transform h-4 w-4 ${isSectionActive('section3') ? 'rotate-180' : ''
                                            }`}
                                    />
                                </div>
                                {isSectionActive('section3') && (
                                    <div className="bg-white p-4  mx-2 transition  xl:text-xl lg:text-xl md:text-xl xs:text-xs text-start  text-[#616161]">
                                        <p>Every person’s situation is different and the information presented here is not tailored to your individual circumstances, risk tolerance, or investment goals. Before making any investment decisions or taking any financial actions, it is recommended that you seek advice from qualified professionals who can provide guidance based on your specific situation</p>
                                    </div>
                                )}
                            </div>
                            <div className='border-b-[1px] mx-2'>
                                <div
                                    className={`flex items-center text-start  xl:text-xl lg:text-xl md:text-xl xs:text-sm text-[#616161] justify-between   p-4 cursor-pointer transition ${isSectionActive('section4') ? 'rounded-b-lg' : 'rounded'
                                        }`}
                                    onClick={() => toggleAccordion('section4')}
                                >
                                    <span className='px-2 my-4'>Can I invest in any type of alternative investment?</span>
                                    <img
                                        src={down}
                                        alt="Down Arrow"
                                        style={{
                                            transform: isSectionActive('section4') ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s ease',
                                        }}
                                        className={`transition mx-2 transform h-4 w-4 ${isSectionActive('section4') ? 'rotate-180' : ''
                                            }`}
                                    />
                                </div>
                                {isSectionActive('section4') && (
                                    <div className="bg-white p-4  mx-2 transition  xl:text-xl lg:text-xl md:text-xl xs:text-xs text-start  text-[#616161]">
                                        <p>For certain offerings, an investor must be considered an Accredited Investor to be eligible to invest. The definition of an Accredited Investor includes both financial and professional criteria and can be found here.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}
