import React, { useEffect, useState } from 'react'
import leftarrow from '../Assets/Icons/leftarrow.svg'
import RecentlyAddedCard from './RecentlyAddedCard'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import Loader from './LinearProgress';
const Base_url = process.env.REACT_APP_BASE_URL
const Token = process.env.REACT_APP_SPONSER_TOKEN;
export default function RecentlyAddeed() {
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    };



    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [Metaloading, setMetaLoading] = useState(false);
    const [companyUrls, setCompanyUrls] = useState([]);
    const [metadataList, setMetadataList] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setMetaLoading(true)
                const response = await fetch(`https://sheet.best/api/sheets/${Token}`);
                const result = await response.json();
                setData(result);
                setLoading(false);
                const urls = result.slice(0, 3).map(item => item["Company URL"]);
                // await fetchMeta(urls);
                // setCompanyUrls(urls);

                setMetaLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        // const fetchMeta = async (urls) => {
        //     try {
        //         setMetaLoading(true)
        //         const response = await fetch(`${Base_url}/api/v1/getMetadata`, {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Access-Control-Allow-Origin': '*',
        //             },
        //             body: JSON.stringify({ url: urls }),
        //         });

        //         const metaResponse = await response.json();
        //         setMetadataList(metaResponse.metadataList);
        //         setMetaLoading(false);
        //     } catch (error) {
        //         console.error('Error fetching meta data:', error);
        //         setMetaLoading(false);
        //     }
        // };

        fetchData();
    }, []);





    return (
        <div className="bg-white flex flex-col px-20  max-md:px-5 xl:mx-6 xl:h-auto xs:h-auto ">
            <div className="text-[#343A40] xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg   font-bold  leading-10 tracking-wider uppercase xl:mt-5 xs:mt-5 self-start max-md:max-w-full">
                Real Estate Sponsors
            </div>
            <div className="xl:hidden lg:hidden xs:self-stretch  max-md:max-w-full ">

                {loading ? (
                    <div className="mx-10 items-center ">
                        <p className='text-lg my-2 font-medium '>Loading ....</p>
                        <div className='flex justify-center items-center'>
                            <Loader />
                        </div>
                    </div>
                ) :
                    (<Slider {...sliderSettings}>
                        {data.slice(0, 3).map((card, index) => (
                            <div key={index} className="">
                                <div className="p-8 xl:w-[400px] lg:w-[33%] md:w-full xs:w-[100%] max-md:w-full max-md:ml-0 rounded-[13px]" style={{ boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>

                                    <p className='text-[#625D42] xl:text-2xl lg:text-2xl md:text-lg xs:text-lg   flex text-start justify-start font-semibold h-[4.5rem]'>
                                        {card["COMPANY NAME"]}
                                    </p>
                                    <div className='flex flex-col-2 gap-3 my-4'>
                                        <div className='my-2'>
                                            <p className='text-[#616161] font-medium  text-start text-lg my-2'>Offering type </p>
                                            <p className='text-[#343A40] py-1 font-semibold border-[1px] rounded-lg border-[#625D4]'> {card["Offering Type"]} </p>
                                        </div>
                                        <div className='my-2 xl:px-4 lg:px-6 md:px-1 xs:px-1'>
                                            <p className='text-[#616161] font-medium text-lg text-start my-2'>State </p>
                                            <p className='text-[#343A40] py-1 px-8 font-semibold border-[1px] rounded-lg border-[#625D4]'>{card.State} </p>
                                        </div>
                                    </div>
                                    {/* {Metaloading ? (
                                        <>
                                            <p className="text-[#343A40] py-1 px-8  xl:text-xl lg:text-xl md:text-xl xs:text-xs  italic">Description is loading ....</p>
                                        </>
                                    ) : (
                                        <>
                                            {metadataList[index] && (
                                                <>
                                                    {metadataList[index].description ? (
                                                        <p className='items-start text-start  xl:text-xl lg:text-xl md:text-xl xs:text-xs  h-14 overflow-auto my-2'>{metadataList[index].description}</p>
                                                    ) : (
                                                        <p className="text-[#343A40]  px-8 h-14 italic py-4 my-2"></p>
                                                    )}
                                                </>
                                            )}
                                        </>)} */}
                                    <div className="my-2 flex justify-start">
                                        <a href={card["Company URL"]} className='bg-yellow py-2 px-4 rounded-lg text-white hover:text-black' target="_blank" rel="noopener noreferrer">Visit Website</a>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </Slider>
                    )}

            </div>
            <div className="xs:hidden xl:grid lg:grid xl:self-stretch mt-11 max-md:max-w-full max-md:mt-10">
                {loading ? (
                    <div className="mx-10 items-center ">
                        <p className='text-lg my-2 font-medium '>Loading ....</p>
                        <div className='flex justify-center items-center'>
                            <Loader />
                        </div>
                    </div>
                ) :
                    (<div className="gap-2 flex max-md:flex-col max-md:items-stretch max-md:gap-0 justify-evenly">
                        {data.slice(0, 3).map((card, index) => (
                            <div key={index} className="xl:w-[350px] lg:w-[35%] md:w-[39%] xs:w-[100%] max-md:w-full max-md:ml-0 rounded-[13px]" style={{ boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                                <div className='p-8'>

                                    <p className='text-[#625D42] xl:text-2xl lg:text-2xl md:text-lg xs:text-lg  flex text-start justify-start font-semibold h-[4.5rem]'>
                                        {card["COMPANY NAME"]}
                                    </p>
                                    <div className='flex flex-col-2 gap-3 my-4'>
                                        <div className='my-2'>
                                            <p className='text-[#616161] font-medium  text-start text-lg my-2'>Offering type </p>
                                            <p className='text-[#343A40] py-1 font-semibold border-[1px] rounded-lg border-[#625D4]'> {card["Offering Type"]} </p>
                                        </div>
                                        <div className='my-2 xl:px-4 lg:px-6 md:px-1 xs:px-1'>
                                            <p className='text-[#616161] font-medium text-lg text-start my-2'>State </p>
                                            <p className='text-[#343A40] py-1 px-8 font-semibold border-[1px] rounded-lg border-[#625D4]'>{card.State} </p>
                                        </div>
                                    </div>
                                    {/* {Metaloading ? (
                                        <>
                                            <p className="text-[#343A40] py-1 px-8  italic">Description is loading ....</p>
                                        </>
                                    ) : (
                                        <>
                                            {metadataList[index] && (
                                                <>
                                                    {metadataList[index].description ? (
                                                        <p className='items-start text-start h-14 overflow-auto my-2'>{metadataList[index].description}</p>
                                                    ) : (
                                                        <p className="text-[#343A40]  px-8 h-14 italic py-4 my-2"></p>
                                                    )}
                                                </>
                                            )}
                                        </>)} */}
                                    <div className="my-2 flex justify-start">
                                        <a href={card["Company URL"]} className='bg-yellow py-2 px-4 rounded-lg text-white hover:text-black' target="_blank" rel="noopener noreferrer">Visit Website</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>)}

            </div>
           
            <div className="items-center flex gap-1 justify-center  mt-7 self-end my-5  ">
                <div className="text-black xl:text-3xl xs:text-2xl font-bold leading-10 capitalize grow whitespace-nowrap my-auto">
                    View All
                </div>
                <Link to="/Sponser">
                    <img
                        src={leftarrow}
                        alt=""
                        className=" cursor-pointer aspect-[1.32] object-contain object-center w-[50%] lg:w-[80px] overflow-hidden self-stretch shrink-0 max-w-full"
                        />
                </Link>
            </div>

        </div>
    )
}
