import React from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";

const navigation = [
  { name: 'Dashboard', to: '/Dashboard' },
  { name: 'Kris', to: '', hasDropdown: true },
];

export default function AdminNavbar() {

  const navigate = useNavigate();

  const logout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      navigate('/signin')

    }).catch((error) => {

    });
  }
  return (
    <div>
      <header className="bg-bg" >
        <nav className="flex h-[100px] items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/Dashboard" className="-m-1.5 p-1.5">
              <span className="sr-only"></span>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff8074e0079c824504623212afdc96129387a77795b04861550fdaed430eae78?"
                className="object-contain object-center max-w-full aspect-[4.17] w-[200px] h-24 xl:ml-14 xs:ml-2" alt=""
              />
            </Link>
          </div>

          <div className="flex px-3">
            <div className="relative group mx-6 ">

            </div>
            <div className="flex group mx-6 xs:mx-0 ">
              <NavLink
                onClick={logout}
                className=' font-medium text-lg leading-6 flex  text-white group-hover:text-[#BEA245] cursor-pointer '
              >
                <svg
                  style={{ color: 'white' }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className='h-5 w-5 mx-2 mt-0.5 svg-icon'
                > 
                  <path
                    d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
                    fill="white"
                  />
                </svg>
              </NavLink>
            </div>
          </div>
        </nav>

      </header>
    </div>
  );
}
