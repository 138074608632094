import React, { useState, useEffect } from "react";
import { auth } from '../../../firebase'; // Adjusted path for firebase import
import { confirmPasswordReset } from 'firebase/auth'; // Corrected import for confirmPasswordReset
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import bg from "../../../Assets/Images/bg.png"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AuthReset() {
    const [number, setNumber] = useState('');
    const [error, setError] = useState(null);
    const [resetSent, setResetSent] = useState(false);
    const [oobCode, setOobCode] = useState('');

    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmPassword] = useState(""); 
    const [showPassword, setShowPassword] = useState(false);
    const [showconfirmPassword, setShowconfirmPassword] = useState(false);
    const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;

    const navigate = useNavigate();
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleconfirmPasswordVisibility =()=>{
        setShowconfirmPassword(!showconfirmPassword);
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const extractedOobCode = urlParams.get('oobCode');
        setOobCode(extractedOobCode);
    }, []);

    const handleConfirmPasswordReset = async (e) => {
        e.preventDefault();
        try {
            await confirmPasswordReset(auth, oobCode, confirmpassword);
            setResetSent(true);
            setError(null);
            setTimeout(() => {
                setResetSent(false);
            }, 5000);
            navigate('/signin');
        } catch (error) {
            setResetSent(false);
            setError({
                pass1: (!password || !confirmpassword) ? 'Please fill in all the fields.' : (password ? '' : 'Email cannot be empty.'),
                pass2: (password && !strongPasswordRegex.test(password)) ? 'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit.' : (password ? '' : 'Password cannot be empty.'),
                confirmPassword: (password !== confirmpassword) ? 'Passwords do not match.' : (confirmpassword ? '' : 'Password cannot be empty.'),
            });
            setTimeout(() => {
                setError(null);
            }, 5000);
        }
    };

    return (
        <div>
            <ToastContainer />
            <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-12 max-md:px-5"
                style={{
                    backgroundImage: `url(${bg})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                {resetSent && <p className='alert alert-primary'>Password Change.</p>}
                <div className="flex relative flex-col items-stretch py-12 max-w-full shadow-sm bg-[#343A40] rounded-[38.009px] w-[700px] max-md:mt-10">
                    <div className="flex flex-col items-stretch px-12 max-md:px-5 max-md:max-w-full">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff8074e0079c824504623212afdc96129387a77795b04861550fdaed430eae78?"
                            className="object-contain object-center max-w-full aspect-[4.17] w-[200px]" alt=""
                        />
                        <div className="mt-4 text-4xl font-bold text-start text-white max-md:max-w-full max-md:text-3xl">
                            Password reset
                        </div>

                    </div>
                    <form onSubmit={handleConfirmPasswordReset} className="flex flex-col items-stretch px-12 mt-2 max-md:px-5 max-md:max-w-full">

                            <input
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="placeholder:text-white justify-center items-start py-3 pr-16 pl-5 mt-4 text-xl font-normal text-white whitespace-nowrap rounded-lg border-solid border-[1.118px] bg-[#343A40] border-[color:var(--Background,#FFF)] max-md:pr-5 max-md:max-w-full"
                                placeholder="Password"
                            />

                            <button
                                className="absolute lg:text-base xs:text-xs text-white lg:top-[190px] sm:top-[10px] md:top-[195px] xs:top-[190px] xl:left-[610px] lg:left-[610px] md:left-[530px] xs:left-[330px] z-10 bg-transparent border-none cursor-pointer"
                                type="button"
                                onClick={togglePasswordVisibility}
                            >
                                <FontAwesomeIcon
                                    icon={showPassword ? faEye : faEyeSlash}
                                />
                            </button>
                            {error && error.pass1 && <p className='text-red-500 text-xs'>{error.pass1}</p>}
                            <input
                                type={showconfirmPassword ? "text" : "password"}
                                value={confirmpassword}
                                onChange={(e) => setConfirmPassword(e.target.value)} 
                                className="placeholder:text-white justify-center items-start py-3 pr-16 pl-5 mt-4 text-xl font-normal text-white whitespace-nowrap rounded-lg border-solid border-[1.118px] bg-[#343A40] border-[color:var(--Background,#FFF)] max-md:pr-5 max-md:max-w-full"
                                placeholder="Confirm Password" 
                            />

                            <button
                                className="absolute lg:text-base xs:text-xs text-white lg:top-[260px] sm:top-[10px] md:top-[265px] xs:top-[260px] xl:left-[610px] lg:left-[610px] md:left-[530px] xs:left-[330px] z-10 bg-transparent border-none cursor-pointer"
                                type="button"
                                onClick={toggleconfirmPasswordVisibility}
                            >
                                <FontAwesomeIcon
                                    icon={showconfirmPassword ? faEye : faEyeSlash}
                                />
                            </button>
                            {error && error.pass2 && <p className='text-red-500 text-xs'>{error.pass2}</p>} 
                            {error && error.confirmPassword && <p className='text-red-500 text-xs'>{error.confirmPassword}</p>} 
                       
                        <button type="submit" className="justify-center items-center px-16 py-3 mt-4 text-2xl font-medium text-white whitespace-nowrap bg-yellow rounded-lg shadow-md max-md:px-5 max-md:max-w-full">
                            Save
                        </button>

                    </form>
                </div>
            </div>
        </div>
    )
}
