import React from 'react'
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
export default function MainPageLayout({ children, heading, text, style }) {
    return (
        <div>

            <div style={style} className=' '>

                <Navbar />
                <div className='flex justify-center'>
                    <div className='px-2 pt-10 xl:max-w-7xl xs:max-w-full text-center'>
                        <p className='font-bold text-white text-4xl xl:mt-5 '>{heading}</p>
                        <p className='font-bold text-white xl:text-xl xs:text-base max-w-[55rem]  mx-5 my-2 text-center '>{text}</p>
                    </div>
                </div>
            </div>
            {children}
            <Footer />
        </div>
    )
}
