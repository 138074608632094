import React, { useEffect, useState, useRef } from 'react'
import lay from '../Assets/Icons/lay.svg'
import dropdown from '../Assets/Icons/dropdown.svg'
import Search from '../Components/Search'
import PaginationButtons from '../Components/PaginationButtons';
import Loader from '../Components/LinearProgress';
import bg from '../Assets/Images/bg.png';
import MainPageLayout from '../Pages/Layouts/MainPageLayout';
import { Popover, OverlayTrigger } from "react-bootstrap";
const Token = process.env.REACT_APP_SPONSER_TOKEN;
const Base_url = process.env.REACT_APP_BASE_URL

export default function RealStateSponser() {
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 24;
    const [loading, setLoading] = useState(true);
    const [Metaloading, setMetaLoading] = useState(true);
    const [companyUrls, setCompanyUrls] = useState([]);
    const [metadataList, setMetadataList] = useState([]);
    const [filterVisible, setFilterVisible] = useState(false);
    const filterRef = useRef(null);
    const showFilterMsg = () => {
        setFilterVisible(!filterVisible);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const usaStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://sheet.best/api/sheets/${Token}`);
                const result = await response.json();
                setData(result);
                setLoading(false);
                const startIndex = (currentPage - 1) * itemsPerPage;
                const endIndex = Math.min(startIndex + itemsPerPage, result.length);
                const urls = result.slice(startIndex, endIndex).map(item => item["Company URL"]);
                setCompanyUrls(urls);

                fetchMeta(urls);
                setMetaLoading(true);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        const fetchMeta = async (urls) => {
            try {
                const response = await fetch(`${Base_url}/api/v1/getMetadata`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                    },
                    body: JSON.stringify({ url: urls }),
                });

                const metaResponse = await response.json();
                setMetadataList(metaResponse.metadataList);
                setMetaLoading(false);
            } catch (error) {
                console.error('Error fetching meta data:', error);
                setMetaLoading(false);
            }
        };

        if (currentPage >= 0) {
            fetchData();

        }
    }, [currentPage]);

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedofferings, setselectedofferings] = useState('');
    const [selectedState, setselectedState] = useState('');

    const uniqueOfferings = Array.from(new Set(data.map(item => item["Offering Type"] || '')));
    const uniqueState = Array.from(new Set(data.map(item => item.State || ''))).sort();


    const handleStateFilterChange = (e) => {
        setselectedofferings(e.target.value);
    };

    const handleYearFilterChange = (e) => {
        setselectedState(e.target.value);
    };

    const filteredPosts = data.filter(card => (
        (!selectedofferings || card["Offering Type"] === selectedofferings) &&
        (!selectedState || card.State === selectedState) &&
        (!searchTerm || card["Offering Type"].toLowerCase().includes(searchTerm.toLowerCase()) ||
            card.State.toLowerCase().includes(searchTerm.toLowerCase()))
    ));

    const totalItems = filteredPosts.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    const displayedData = filteredPosts.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        setLoading(true);
    };

    const [expandedCards, setExpandedCards] = useState({});

    const toggleExpand = (index) => {
        setExpandedCards(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };
    const resetFilters = () => {
        setselectedofferings('');
        setselectedState('');

    };
    return (
        <MainPageLayout heading="Real Estate Sponsors"
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
                height: '250px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}>
            <div className=''>
                <div className='flex justify-between md:mx-10 mx-5 my-4 '>
                    <div className='flex justify-start w-72'>
                        <Search placeholder="Search "
                            popovertxt="Search by offering type & state"
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="relative" ref={filterRef}>
                        <div className="inline-flex items-center overflow-hidden  bg-white xl:mx-3 xs:mx-2 flex-" >
                            <button
                                onClick={showFilterMsg}
                                className="border-[#625D42] border-[1px] rounded-[7px] xl:px-3 xl:py-1 xs:p-2 xl:text-xl xs:text-base  flex text-[#625D42]"
                            >
                                Filter
                                <img src={dropdown} alt='' className=' xl:w-6 xl:h-5 xs:w-3 xs:h-5 xs:mx-2 xl:mx-0 xl:pt-3 xs:pt-2  ' />

                            </button>
                        </div>

                        {filterVisible && (
                            <div
                                className="absolute end-0 z-10 mt-2 xl:w-[25rem] xs:w-72 lg:w-auto md:w-auto  divide-y divide-gray-100 rounded-xl border border-gray-100 bg-white "
                                role="menu"
                                style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
                            >
                                <div className='text-start p-9 '>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'> Offering type</div>
                                    <div className='my-1'>
                                        <select
                                            className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                                            onChange={handleStateFilterChange}
                                            value={selectedofferings}
                                        >
                                            <option value="">All Offering</option>
                                            {uniqueOfferings.map((offering, index) => (
                                                <option key={index} value={offering}>{offering}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>State</div>
                                    <div className='my-1'>
                                        <select
                                            className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                                            onChange={handleYearFilterChange}
                                            value={selectedState}
                                        >
                                            <option value="">All State</option>
                                            {uniqueState.filter(location => usaStates.includes(location.toUpperCase())).map((state, index) => (
                                                <option key={index} value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='flex justify-center mt-7'>
                                        <button type="submit" className="bg-yellow px-2 xl:w-[100px] xs:w-[70px] flex justify-center py-2 text-white xl:text-lg xs:text-sm font-medium hover:text-black"
                                            style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} onClick={resetFilters}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                {loading ? (
                    <div className="mx-10  pt-32 items-center h-[80vh]">
                        <p className='text-lg my-2 font-medium '>Loading ....</p>
                        <div className='flex justify-center items-center'>
                            <Loader />
                        </div>
                    </div>
                ) : displayedData.length === 0 ? (
                    <div className="mx-10 items-center h-[70vh]">
                        <p className='text-xl my-2 font-medium '>Nothing found</p>
                    </div>
                ) : (
                    <div className="bg-white flex flex-col px-20  max-md:px-5 xl:mx-6 mb-16  ">
                        <div className="grid self-stretch  max-md:max-w-full max-md:mt-5">
                            <div className="xs:block xl:grid lg:grid md:grid xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-5 mt-11 max-md:max-w-full max-md:mt-10">
                                {displayedData
                                    .filter((post) => usaStates.includes(post.State.toUpperCase())).map((card, index) => (
                                        <div key={index} className="flex flex-col my-2 xl:-[80%] max-md:w-full max-md:ml-0 rounded-[13px]" style={{ boxShadow: ' 0px 4px 10px 0px rgba(0, 0, 0, 0.10)' }}>
                                            <div className='p-8'>

                                                <p className='text-[#625D42] text-3xl flex text-start justify-start font-semibold xl:h-[3rem] lg:h-[4.5rem] '>
                                                    {card["COMPANY NAME"]}
                                                </p>
                                                <div className='flex flex-col-2 gap-3 my-4'>
                                                    <div className='my-2'>
                                                        <p className='text-[#616161] font-medium  text-start text-lg my-2'>Offering type </p>
                                                        <p className='text-[#343A40] py-1 font-semibold border-[1px] rounded-lg border-[#625D4]'> {card["Offering Type"]} </p>
                                                    </div>
                                                    <div className='my-2 xl:px-4 lg:px-6 md:px-1 xs:px-1'>
                                                        <p className='text-[#616161] font-medium text-lg text-start my-2'>State </p>
                                                        <p className='text-[#343A40] py-1 px-8 font-semibold border-[1px] rounded-lg border-[#625D4]'>{card.State} </p>
                                                    </div>
                                                </div>
                                                {/* {Metaloading ? (
                                                    <>
                                                        <p className="text-[#343A40] py-1 px-8  italic">Description is loading ....</p>
                                                    </>
                                                ) : (
                                                    <>
                                                        {metadataList[index] && (
                                                            <>
                                                                {metadataList[index].description ? (
                                                                    <div>
                                                                        <p className={`items-start text-start h-14 overflow-hidden my-2 ${expandedCards[index] ? 'h-auto' : ''}`}>
                                                                            {metadataList[index].description}
                                                                        </p>
                                                                        <button onClick={() => toggleExpand(index)} className="text-blue-500 hover:text-blue-700">
                                                                            {expandedCards[index] ? 'Show Less' : 'Show More'}
                                                                        </button>

                                                                    </div>
                                                                ) : (
                                                                    <p className="text-[#343A40]  px-8 h-14 italic py-4 my-2"></p>
                                                                )}
                                                            </>
                                                        )}
                                                    </>)} */}
                                                <div className="my-2 flex justify-start">
                                                    <a href={card["Company URL"]} className='bg-yellow py-2 px-4 rounded-lg text-white hover:text-black' target="_blank" rel="noopener noreferrer">Visit Website</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className='xl:flex justify-between mt-22 mx-2  '>
                    <div className='flex justify-center xl:mx-5 my-1'>
                        <PaginationButtons count={totalPages} page={currentPage} onChange={handlePageChange} />
                    </div>
                    <div className='flex justify-center gap-2 mx-5 my-1'>
                        <p className='mt-1'>Page</p>
                        <select className='border-[1px] border-[#DDDDDD] rounded-[10px] px-1 cursor-pointer'
                            onChange={(e) => setCurrentPage(Number(e.target.value))} value={currentPage}>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                <option key={page} value={page}>{page}</option>
                            ))}
                        </select>
                        <p className='mt-1'>of {totalPages}</p>
                    </div>
                </div>
            </div>
        </MainPageLayout>

    )
}
