import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationButtons({ count, page, onChange }) {
   
    return (
        <Stack spacing={1}>
            <Pagination
               count={count}
               page={page}  
               showFirstButton
               showLastButton
               onChange={onChange}
                sx={{
                    '@media (max-width: 600px)': {
                        '& .MuiPaginationItem-root': {
                          fontSize: '12px',
                          margin: '2px ', 
                          padding: '7px 7px', 
                          minWidth: '30px', 
                        },
                      },
                    '& .MuiPaginationItem-root': {
                        backgroundColor: 'white',
                        color: "black",
                        borderRadius: '10px',
                        border: '1px solid #F1F1F1',

                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: '#625D42 !important', 
                        border: '1px solid #625D42 !important',
                        color: '#FFFFFF !important',
                    },
                    '& .MuiPaginationItem-ellipsis': {
                        border: 'none',
                    },
                }}
            />
        </Stack>
    );
}
