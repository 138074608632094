import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { getDocs, collection } from 'firebase/firestore';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import leftarrow from '../Assets/Icons/leftarrow.svg';
import { Link } from 'react-router-dom';
import Loader from './LinearProgress';

export default function Articles() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'admin'));
                const postData = querySnapshot.docs
                    .map(doc => ({ id: doc.id, ...doc.data() }))
                    .filter(post => post.status === 'Published')
                    .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())
                    .slice(0, 2);
                setPosts(postData);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchPosts();
    }, []);


    if (loading) {
        return <div className="mx-10 items-center h-[50vh]">
            <p className='text-lg my-2 font-medium'>Loading ....</p>
            <div className='flex justify-center items-center'>
                <Loader />
            </div>
        </div>;
    }

    if (error) {
        return <div className='text-center mx-10 mt-16 h-[50vh]'>
            <div className='flex justify-center'>
                <p className='text-yellow text-3xl font-medium'>Error:</p>
                <p className='text-black mx-2 text-3xl font-medium'>{error.message}</p>
            </div>
        </div>;
    }

    return (
        <div className="bg-white flex flex-col px-20 max-md:px-5 xl:mx-6 xl:h-auto xs:h-auto my-2">
            <div className="text-[#343A40] xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg  font-bold leading-10 tracking-wider uppercase ml-3 mt-10 self-start max-md:max-w-full">
                Articles
            </div>
            <div className="self-center w-full max-w-[1214px] mt-20 max-md:max-w-full max-md:mt-10 border-b-[2px]">
                <Slider {...sliderSettings}>
                    {posts.map((post, index) => (
                        <Link to={`/posts/${post.id}`} key={index}>
                            <div className="flex max-md:flex-col max-md:items-stretch max-md:gap-0">
                                <div className="flex flex-col items-stretch w-[70%] max-md:w-full max-md:ml-0">
                                    <div className="flex items-stretch gap-5">
                                        <img
                                            loading="profile"
                                            src={post.profileImageUrl}
                                            className="aspect-square object-center w-[75px] overflow-hidden shrink-0 max-w-full rounded-full"
                                        />
                                        <div className="flex grow basis-[0%] flex-col items-start mt-5 self-start">
                                            <div className="text-neutral-700 xl:text-2xl xs:text-base font-medium leading-7 whitespace-nowrap">
                                                {post.title}
                                            </div>
                                            <div className="text-zinc-600 xl:text-base xs:text-[10px] font-medium leading-5 ">
                                                on {post.timestamp.toDate().toLocaleString([], { dateStyle: 'medium' })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-zinc-600 xl:text-lg lg:text-lg md:text-lg xs:text-xs xl:w-[44rem] xs:auto  text-start  leading-8 self-stretch mt-2 max-md:max-w-full">
                                        <div dangerouslySetInnerHTML={{ __html: truncateContent(post.content) }}></div>
                                        <Link to={`/posts/${post.id}`} className="xl:text-base lg:text-base md:text-lg xs:text-xs font-medium text-[#625D42] underline">
                                            {post.readTime} {post.time}  Read
                                        </Link>
                                    </div>
                                </div>
                                <div className="xl:flex lg:flex md:flex xl:relative xs:hidden flex-col ">
                                    <img
                                        loading=""
                                        src={post.featuredImageUrl}
                                        className="rounded-[21px] h-60 w-72 my-2"
                                        alt=""
                                    />
                                </div>
                            </div>
                        </Link>
                    ))}
                </Slider>
            </div>
            <div className="items-center gap-1 flex justify-center mt-7 self-end ">
                <div className="text-black xl:text-3xl xs:text-2xl font-bold leading-10 capitalize grow whitespace-nowrap my-auto">
                    View All
                </div>
                <Link to="articles">
                    <img
                        src={leftarrow}
                        alt=""
                        className="cursor-pointer aspect-[1.32] object-contain object-center w-[50%] lg:w-[80px] overflow-hidden self-stretch shrink-0 max-w-full"
                    />
                </Link>
            </div>
        </div>
    );
}

function truncateContent(content) {
    const MAX_LENGTH = 300;
    if (!content) return '';

    if (content.length <= MAX_LENGTH) return content;

    return content.slice(0, MAX_LENGTH) + '...';
}
