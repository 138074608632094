import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
 return `$${value}k`; 
}

export default function TrackInvertedSlider({ minRevenues, maxRevenues, onChange }) {
 const [value, setValue] = React.useState([minRevenues, maxRevenues]);

 const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
 };

 const handleDragChange = (event, newValue) => {
    onChange(newValue);
 };

 const minRevenuesFormatted = minRevenues.toLocaleString('en-US');
 const maxRevenuesFormatted = maxRevenues.toLocaleString('en-US');

 const formattedValues = [
    { value: minRevenues, label: `$${minRevenuesFormatted}` },
    { value: maxRevenues, label: `$${maxRevenuesFormatted}` },
 ];

 const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      minimumFractionDigits: 0, 
      maximumFractionDigits: 0,
      currency: 'USD',
    }).format(value);
 };

 return (
    <Box sx={{ width: { xs: '100%', sm: '350px' } }}>
      <Slider
        getAriaLabel={() => 'Minimum distance'}
        value={value}
        min={minRevenues}
        max={maxRevenues}
        marks={formattedValues}
        onChange={handleChange}
        onChangeCommitted={handleDragChange} 
        valueLabelDisplay="on" 
        getAriaValueText={valuetext}
        valueLabelFormat={formatCurrency} 
        disableSwap
        sx={{
          '.MuiSlider-rail': {
            backgroundColor: '#625D42',
          },
          '.MuiSlider-track': {
            backgroundColor: '#625D42',
          },
          '.MuiSlider-valueLabel': {
            backgroundColor: 'white',
            boxShadow: 'none',
            top: -10,
          },
        }}
      />
    </Box>
 );
}
