import React, { useState } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail,getAuth } from "firebase/auth";
import bg from "../../../Assets/Images/bg.png"
import { ToastContainer, toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPass() {
    const [email, setemail] = useState("");


    const reset = () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                toast.success("Reset password Email sent successfully");
            })
            .catch((error) => {
                toast.error(error.message);

            });
    }

    return (
        <div>
            <ToastContainer/>
            <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-12  max-md:px-5"
                style={{
                    backgroundImage: `url(${bg})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>

                <div className="flex relative flex-col items-stretch py-12  max-w-full shadow-sm bg-[#343A40] rounded-[38.009px] w-[700px] max-md:mt-10">
                    <div className="flex flex-col items-stretch px-12  max-md:px-5 max-md:max-w-full">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff8074e0079c824504623212afdc96129387a77795b04861550fdaed430eae78?"
                            className="object-contain object-center max-w-full aspect-[4.17] w-[200px]" alt=""
                        />
                        <div className="mt-4 text-4xl font-bold text-start text-white max-md:max-w-full max-md:text-3xl">
                        Enter Your Email
                        </div>

                    </div>
                    <div className="flex flex-col items-stretch px-12 mt-2 max-md:px-5 max-md:max-w-full">
                        
                        <input className="placeholder:text-gray-400 justify-center items-start py-3 pr-16 pl-5 mt-4 text-xl font-normal text-white whitespace-nowrap rounded-lg border-solid border-[1.118px]  bg-[#343A40] border-[color:var(--Background,#FFF)] max-md:pr-5 max-md:max-w-full"
                            type="email" id="email" placeholder='example@gmail.com'
                            onChange={(e) => setemail(e.target.value)} />
                        
                        <button onClick={reset} href='' className="justify-center items-center px-16 py-3 mt-4 text-2xl font-medium text-white whitespace-nowrap bg-yellow rounded-lg shadow-md max-md:px-5 max-md:max-w-full">
                           Send Reset link 
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
