import React, { useState, useRef, useEffect } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { db, storage } from '../../firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLayout from '../Layouts/AuthLayout';
import { useParams, useNavigate } from 'react-router-dom';

const Key = process.env.REACT_APP_TINYMCE_API;

export default function EditPost() {
    const { postId } = useParams();
    const navigate = useNavigate();
    const editorRef = useRef(null);

    const [formData, setFormData] = useState({
        title: "",
        readTime: "",
        time: "",
        Author: ""
    });

    const [content, setContent] = useState("");
    const [featuredImageUrl, setFeaturedImageUrl] = useState("");
    const [profileImageUrl, setProfileImageUrl] = useState("");
    const [coverImageUrl, setCoverImageUrl] = useState("");
    const [featuredImage, setFeaturedImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [featuredImagePreview, setFeaturedImagePreview] = useState(null);
    const [coverImagePreview, setCoverImagePreview] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const postDocRef = doc(db, 'admin', postId);
                const postDocSnapshot = await getDoc(postDocRef);
                if (postDocSnapshot.exists()) {
                    const postData = postDocSnapshot.data();
                    setFormData({
                        title: postData.title,
                        readTime: postData.readTime,
                        time: postData.time,
                        Author: postData.Author,
                    });
                    setContent(postData.content);
                    setFeaturedImageUrl(postData.featuredImageUrl);
                    setCoverImageUrl(postData.coverImageUrl);
                    setProfileImageUrl(postData.profileImageUrl);
                } else {
                }
            } catch (error) {
                console.error("Error fetching post:", error);
            }
        };
        fetchPost();
    }, [postId]);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const handleFeaturedImageChange = (e) => {
        const file = e.target.files[0];
        setFeaturedImage(file);
        setFeaturedImagePreview(URL.createObjectURL(file));
    };

    const handleCoverImageChange = (e) => {
        const file = e.target.files[0];
        setCoverImage(file);
        setCoverImagePreview(URL.createObjectURL(file));
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
        setProfileImagePreview(URL.createObjectURL(file));
    };

    const handleUpdatePost = async (event) => {
        event.preventDefault();
        try {
            const updateData = {
                title: formData.title,
                readTime: formData.readTime,
                time: formData.time,
                Author: formData.Author,
                content: content,
            };

            if (featuredImage) {
                const featuredImageRef = ref(storage, `featured_images/${featuredImage.name}`);
                await uploadBytesResumable(featuredImageRef, featuredImage);
                const featuredImageUrl = await getDownloadURL(featuredImageRef);
                updateData.featuredImageUrl = featuredImageUrl;
            }

            if (coverImage) {
                const coverImageRef = ref(storage, `cover_images/${coverImage.name}`);
                await uploadBytesResumable(coverImageRef, coverImage);
                const coverImageUrl = await getDownloadURL(coverImageRef);
                updateData.coverImageUrl = coverImageUrl;
            }

            if (profileImage) {
                const profileImageRef = ref(storage, `profile_images/${profileImage.name}`);
                await uploadBytesResumable(profileImageRef, profileImage);
                const profileImageUrl = await getDownloadURL(profileImageRef);
                updateData.profileImageUrl = profileImageUrl;
            }

            await updateDoc(doc(db, 'admin', postId), updateData);

            toast.success("Post updated successfully!");
            navigate('/Dashboard');
        } catch (error) {
            console.error("Error updating post: ", error);
            toast.error("Error updating post: " + error.message);
        }
    };
    

    return (
        <div>
            <AuthLayout>
                <ToastContainer />
                <div className='px-6'>
                    <div className='mx-4  my-2 mt-5'>
                        <p className='text-3xl text-start font-semibold'>Edit Post</p>
                        <form className=' xl:flex gap-3 mt-4 '  >
                            <div className='border-[#ececec] border-[1px] rounded-md p-4 my-2'>
                                <div className=' text-start'>
                                    <label className='text-start  text-base font-medium' >Author Name</label> <br />
                                    <input name='Author'
                                        value={formData.Author}
                                        className="border-[#ececec] border-[1px]  xs:w-64 xl:w-[45rem] rounded-[10px] w-[718px] mt-3 p-2"
                                        onChange={handleOnChange}
                                        type='text' placeholder='Author' />
                                </div>
                                <div className=' text-start my-4'>
                                    <label className='text-start  text-base font-medium' >Title</label> <br />
                                    <input
                                        name='title'
                                        value={formData.title}
                                        className="border-[#ececec] border-[1px] xs:w-64 xl:w-[45rem] rounded-[10px] w-[718px] mt-3 p-2"
                                        onChange={handleOnChange}
                                        type='text'
                                        placeholder='Title'
                                    />
                                </div>
                                <div className='my-4'>
                                    <p className='text-start  text-base font-medium'>Content</p>
                                </div>
                                <div className='my-4'>
                                    <Editor
                                        apiKey={Key}
                                        onInit={(evt, editor) => (editorRef.current = editor)}
                                        initialValue={content}
                                        onChange={(e) => setContent(e.target.getContent({ format: 'bold ,underline,italic, bullist, numlist, outdent, indent' }))}
                                        name='content'
                                        init={{
                                            height: 500,
                                            menubar: true,
                                            statusbar: false,
                                            plugins: [
                                                "advlist",
                                                "autolink",
                                                "lists",
                                                "link",
                                                "image",
                                                "charmap",
                                                "preview",
                                                "anchor",
                                                "searchreplace",
                                                "visualblocks",
                                                "code",
                                                "fullscreen",
                                                "insertdatetime",
                                                "media",
                                                "table",
                                                "code",
                                                "help",
                                                "wordcount",
                                            ],
                                            toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview media fullscreen |forecolor backcolor emoticons ',

                                            content_style:
                                                "body { font-family:sans-serif; font-size:14px }",

                                        }}
                                    />
                                </div>
                            </div>
                            <div className='border-[#ececec] border-[1px] rounded-md p-4 my-2'>
                                <div className='relative text-start '>
                                    <label className='text-start text-base font-medium' >Read Time</label> <br />
                                    <input
                                        className="border-[#ececec] border-[1px] xs:w-[17rem] xl:w-[30rem] rounded-[10px] w-[418px] mt-3 p-2"
                                        name='readTime'
                                        value={formData.readTime}
                                        onChange={handleOnChange}
                                        type='number'
                                        placeholder='00'
                                    />
                                    <select
                                        className='absolute right-10 top-11 border-[#ececec] border-[1px] rounded-[3px]'
                                        name='time'
                                        value={formData.time}
                                        onChange={handleOnChange}
                                    >
                                        <option>select</option>
                                        <option>min</option>
                                        <option>hrs</option>
                                    </select>

                                </div>
                                <div className='text-start mt-3 items-center'>
                                    <label className='text-start text-base font-medium'>Profile Image</label> <br />
                                    <div className='border-[#ececec] border-[1px] rounded-md p-4 mt-3'>
                                        <input className="mt-3 p-2"
                                            type='file' placeholder='' onChange={handleProfileImageChange}
                                            accept="image/webp,image/jpeg,image/png,image/jpg" />
                                        <p className='text-red-600 text-xs px-2'>Only webp, jpg, png, jpeg formats are accepted.</p>
                                        <div className=' flex justify-center items-center'>
                                            <img src={profileImagePreview || profileImageUrl} alt="profile" className='h-20 w-20 rounded-full my-2' />
                                        </div>
                                    </div>
                                </div>
                                <div className='text-start mt-3'>
                                    <label className='text-start text-base font-medium ' >Featured Image</label> <br />
                                    <div className='border-[#ececec] border-[1px] rounded-md p-4 mt-3 '>
                                        <input
                                            className="mt-3 p-2"
                                            type='file'
                                            placeholder=''
                                            onChange={handleFeaturedImageChange}
                                            accept="image/webp,image/jpeg,image/png,image/jpg"
                                        />
                                        <p className='text-red-600 text-xs  px-2'>Only webp, jpg, png, jpeg formats are accepted.</p>
                                        <div className='flex justify-center items-center'>
                                            <img src={featuredImagePreview || featuredImageUrl} alt="Featured" className='h-24 w-56 flex my-2 justify-center' />
                                        </div>
                                    </div>
                                </div>
                                <div className='text-start mt-3'>
                                    <label className='text-start text-base font-medium ' >Cover Image</label> <br />
                                    <div className='border-[#ececec] border-[1px] rounded-md p-4 mt-3 '>
                                        <input
                                            className="mt-3 p-2"
                                            type='file'
                                            placeholder=''
                                            onChange={handleCoverImageChange}
                                            accept="image/webp,image/jpeg,image/png,image/jpg"
                                        />
                                        <p className='text-red-600 text-xs  px-2'>Only webp, jpg, png, jpeg formats are accepted.</p>
                                        <div className='flex justify-center items-center'>
                                            <img src={coverImagePreview || coverImageUrl} alt="Cover" className='h-24 w-56 flex my-2 justify-center' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className=" flex justify-center mt-5">
                        <button onClick={handleUpdatePost} className="bg-yellow px-4 flex justify-start py-2 text-white hover:text-black  xl:text-lg xs:text-base font-normal" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                            Update
                        </button>
                    </div>
                </div>
            </AuthLayout>
        </div>
    );
}
