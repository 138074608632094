// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage,ref } from 'firebase/storage';
const ApiKey= process.env.REACT_APP_FIREBASE_API_KEY;
const Domain =process.env.REACT_APP_AUTH_DOMAIN;
const ProjID =process.env.REACT_APP_PROJECT_ID;
const Storagebucket =process.env.REACT_APP_STORAGE;
const Sender= process.env.REACT_APP_MSG_SENDER;
const Id =process.env.REACT_APP_API_ID;
const MeasurementId =process.env.REACT_APP_MEASURMENET_ID;
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: ApiKey,
  authDomain: Domain,
  projectId: ProjID,
  storageBucket: Storagebucket,
  messagingSenderId: Sender,
  appId: Id,
  measurementId: MeasurementId,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);                                                                                                                      
export const storage = getStorage(app);
export const storageRef = ref(storage);