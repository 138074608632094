import React from 'react';
import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import rightarrow from '../Assets/Icons/rightarrow.svg';
import { NavLink, Link } from 'react-router-dom';

const navigation = [
  { name: 'Home', to: '/' },
  { name: 'Offerings', to: '', hasDropdown: true },
  { name: 'Resources', to: '', hasDropdown: true },
  { name: 'About', to: '/about' },
  { name: 'Articles', to: '/articles' },
];

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
  const [resourcesDropdownOpen, setResourcesDropdownOpen] = useState(false);
  const close = () => {
    setAccountDropdownOpen(false);
    setResourcesDropdownOpen(false);
  }
  const mobileMenu = () => {
    setMobileMenuOpen(false);
    setAccountDropdownOpen(false);
    setResourcesDropdownOpen(false);
  }
  const [isActive, setIsActive] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); 

  return (
    <div>
      <header className="bg-bg"
      >
        <nav className="flex h-[100px] items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/" className="">
              <span className="sr-only"></span>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff8074e0079c824504623212afdc96129387a77795b04861550fdaed430eae78?"
                className="object-contain object-center max-w-full aspect-[4.17] w-[200px] h-24 xl:ml-14 xs:ml-2" alt=""
              />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex  ">
            {navigation.map((item) => (
              <div key={item.name} className="relative group mx-6 ">
                {item.to !== "" ? (
                  <NavLink
                    to={item.to}
                    className={({ isActive }) =>
                      isActive ? `font-medium text-lg leading-6 text-[#BEA245] cursor-pointer ${item.hasDropdown ? 'flex items-center' : ''}` : `font-medium text-lg leading-6 text-white group-hover:text-[#BEA245] cursor-pointer ${item.hasDropdown ? 'flex items-center' : ''}`
                    }
                    // className={` font-medium text-lg leading-6 text-white group-hover:text-[#BEA245] cursor-pointer ${item.hasDropdown ? 'flex items-center' : ''
                    //   }`}
                    onClick={() => {
                      if (item.hasDropdown) {
                        if (item.name === 'Offerings') {
                          setAccountDropdownOpen(!accountDropdownOpen);
                          setResourcesDropdownOpen(false);
                        } else if (item.name === 'Resources') {
                          setResourcesDropdownOpen(!resourcesDropdownOpen);
                          setAccountDropdownOpen(false);
                        }
                      }
                    }}
                  >
                    {item.name}
                    {item.hasDropdown && (
                      <img
                        src={rightarrow}
                        alt="Dropdown Arrow"
                        className="ml-2 w-3 h-3 transform transition-transform duration-300 ease-in-out hover:text-[#BEA245] cursor-pointer"
                        style={{
                          transform: item.name === 'Offerings'
                            ? accountDropdownOpen
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)'
                            : resourcesDropdownOpen
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)',
                        }}
                      />
                    )}
                  </NavLink>
                ) : (
                  <div
                    className={`font-medium text-lg leading-6  cursor-pointer ${isActive ? 'text-[#BEA245]' : 'text-white group-hover:text-[#BEA245]'} ${item.hasDropdown ? 'flex items-center' : ''}`
                    }
                    // className={` font-medium text-lg leading-6 text-white group-hover:text-[#BEA245] cursor-pointer ${item.hasDropdown ? 'flex items-center' : ''
                    //   }`}
                    onClick={() => {
                      if (item.hasDropdown) {
                        if (item.name === 'Offerings') {
                          setAccountDropdownOpen(!accountDropdownOpen);
                          setResourcesDropdownOpen(false);
                          // setIsActive(true)
                        } else if (item.name === 'Resources') {
                          setResourcesDropdownOpen(!resourcesDropdownOpen);
                          setAccountDropdownOpen(false);
                        }
                      }
                    }}
                  >
                    {item.name}
                    {item.hasDropdown && (
                      <img
                        src={rightarrow}
                        alt="Dropdown Arrow"
                        className="ml-2 w-3 h-3 transform transition-transform duration-300 ease-in-out hover:text-[#BEA245] cursor-pointer"
                        style={{
                          transform: item.name === 'Offerings'
                            ? accountDropdownOpen
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)'
                            : resourcesDropdownOpen
                              ? 'rotate(90deg)'
                              : 'rotate(0deg)',
                        }}
                      />
                    )}
                  </div>
                )}

                {accountDropdownOpen && item.hasDropdown && item.name === 'Offerings' && (
                  <div className="absolute z-10 my-1 w-48 lg:ml-14 lg:left-1/2 lg:-translate-x-1/2" id='offer'>
                    <div className=" overflow-hidden" style={{ borderRadius: '0px 9px 9px 9px' }}>
                      <div className="relative text-start bg-white text-4680A6 sm:gap-2">
                        <NavLink
                          to="/offering"
                          onClick={()=>{close();setIsActive(true);setActiveDropdown('Offering')}}
                          className="block rounded-lg px-3  text-sm font-semibold leading-7 text-black hover:text-[#343A40] hover:opacity-75 cursor-pointer"
                        >
                          Real Estate Offerings
                        </NavLink>
                        <NavLink
                          to="/Regcf"
                          onClick={()=>{close();setIsActive(true);setActiveDropdown('Offering')}}
                          className="block rounded-lg px-3 text-sm font-semibold leading-7 text-black hover:text-[#343A40] hover:opacity-75 cursor-pointer"
                        >
                          Reg CF Offerings
                        </NavLink>
                        <NavLink
                          to="/rega"
                          onClick={()=>{close();setIsActive(true);setActiveDropdown('Offering')}}
                          className="block rounded-lg px-3  text-sm font-semibold leading-7 text-black hover:text-[#343A40] hover:opacity-75 cursor-pointer"
                        >
                          Reg A Offering
                        </NavLink>
                        
                      </div>
                    </div>
                  </div>
                )}
                {resourcesDropdownOpen && item.hasDropdown && item.name === 'Resources' && (
                  <div className="absolute z-10 my-1 w-48 lg:ml-14 lg:left-1/2 lg:-translate-x-1/2">
                    <div className=" overflow-hidden" style={{ borderRadius: '0px 9px 9px 9px' }}>
                      <div className="relative text-start bg-white text-4680A6 sm:gap-2">
                        <NavLink
                          to="/realstate"
                          onClick={close}
                          className="block rounded-lg px-3 py-1 text-sm font-semibold leading-7 text-black hover:text-[#343A40] hover:opacity-75 cursor-pointer "
                        >
                          Real Estate Risk Score
                        </NavLink>
                        <NavLink
                          to="/sponser"
                          onClick={close}
                          className="block rounded-lg px-3  text-sm font-semibold leading-7 text-black hover:text-[#343A40] hover:opacity-75  cursor-pointer "
                        >
                          Real Estate Sponsors
                        </NavLink>
                        <NavLink
                          to="/fee"
                          onClick={close}
                          className="block rounded-lg px-3  text-sm font-semibold leading-7 text-black hover:text-[#343A40] hover:opacity-75 cursor-pointer "
                        >
                          Real Estate Fees
                        </NavLink>
                        
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 h-full  z-50 w-full overflow-y-auto bg-bg px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff8074e0079c824504623212afdc96129387a77795b04861550fdaed430eae78?"
                className="object-contain object-center max-w-full aspect-[4.17] w-[200px] h-24 xl:ml-14 xs:ml-2" alt=""
              />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only text-white">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className=" flow-root ">
              <div className=" divide-y text-white ">
                <div className="space-y-2 ">
                  {navigation.map((item) => (
                    <div key={item.name} className="relative group py-3">
                      <NavLink
                        to={item.to}
                        className={`text-lg font-medium leading-6 text-white group-hover:text-[#BEA245] cursor-pointer ${item.hasDropdown ? 'flex items-center' : ''
                          }`}
                        onClick={() => {
                          if (item.hasDropdown) {
                            if (item.name === 'Offerings') {
                              setAccountDropdownOpen(!accountDropdownOpen);
                              setResourcesDropdownOpen(false);
                            } else if (item.name === 'Resources') {
                              setResourcesDropdownOpen(!resourcesDropdownOpen);
                              setAccountDropdownOpen(false);
                            }
                          }
                        }}
                      >
                        {item.name}
                        {item.hasDropdown && (
                          <img
                            src={rightarrow}
                            alt="Dropdown Arrow"
                            className="ml-2 w-3 h-3 transform transition-transform duration-300 ease-in-out"
                            style={{
                              transform: item.name === 'Offerings'
                                ? accountDropdownOpen
                                  ? 'rotate(90deg)'
                                  : 'rotate(0deg)'
                                : resourcesDropdownOpen
                                  ? 'rotate(90deg)'
                                  : 'rotate(0deg)',
                            }}
                          />
                        )}
                      </NavLink>
                      {accountDropdownOpen && item.hasDropdown && item.name === 'Offerings' && (
                        <div className="xl:absolute xs:relative xl:z-10 my-1 w-48 lg:ml-14 lg:left-1/2 lg:-translate-x-1/2">
                          <div className=" overflow-hidden" style={{ borderRadius: '0px 9px 9px 9px' }}>
                            <div className="relative xl:bg-white text-4680A6 sm:gap-2">
                              <NavLink
                                to="/offering"
                                onClick={mobileMenu}
                                className="block rounded-lg px-3  text-sm font-semibold leading-7 xs:text-white xl:text-black hover:text-[#343A40]  cursor-pointer"
                              >
                                Real Estate Offerings
                              </NavLink>
                              <NavLink
                                to="/Regcf"
                                onClick={mobileMenu}
                                className="block rounded-lg px-3 text-sm font-semibold leading-7 xs:text-white xl:text-black hover:text-[#343A40] cursor-pointer"
                              >
                                Reg CF Offerings
                              </NavLink>
                              <NavLink
                                to="/rega"
                                onClick={mobileMenu}
                                className="block rounded-lg px-3  text-sm font-semibold leading-7 xs:text-white xl:text-black hover:text-[#343A40] cursor-pointer"
                              >
                                Reg A Offering
                              </NavLink>
                             
                            </div>
                          </div>
                        </div>
                      )}
                      {resourcesDropdownOpen && item.hasDropdown && item.name === 'Resources' && (
                        <div className="xl:absolute xs:relative xl:z-10 z-10 my-1 w-48 lg:ml-16 lg:left-1/2 lg:-translate-x-1/2">
                          <div className=" overflow-hidden" style={{ borderRadius: '0px 9px 9px 9px' }}>
                            <div className="relative xl:bg-white text-4680A6 sm:gap-2">
                              <NavLink
                                to="/realstate"
                                onClick={mobileMenu}
                                className="block rounded-lg px-3 py-1 text-sm font-semibold leading-7 xs:text-white xl:text-black hover:text-[#343A40]  cursor-pointer "
                              >
                                Real Estate Risk Score
                              </NavLink>
                              <NavLink
                                to="/sponser"
                                onClick={mobileMenu}
                                className="block rounded-lg px-3  text-sm font-semibold leading-7 xs:text-white xl:text-black hover:text-[#343A40]  cursor-pointer "
                              >
                                Real Estate Sponsors
                              </NavLink>
                              <NavLink
                                to="/fee"
                                onClick={mobileMenu}
                                className="block rounded-lg px-3  text-sm font-semibold leading-7 xs:text-white xl:text-black hover:text-[#343A40]  cursor-pointer "
                              >
                                Real Estate Fees 
                              </NavLink>
                              
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
}
