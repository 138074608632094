import React, { useState, useRef } from 'react';
import bg from '../Assets/Images/bg.png'
import Navbar from '../Components/Navbar'
import vec from '../Assets/Icons/Vec.svg'
import grommet from '../Assets/Icons/grommet.svg'
import RecentlyAddeed from '../Components/RecentlyAddeed'
import Articles from '../Components/Articles'
import Questions from '../Components/Questions'
import Footer from '../Components/Footer'
import Mission from '../Components/Mission'
import close from '../Assets/Images/close.png'
import stepper from '../Assets/Icons/stepper.svg'
import iconpark from '../Assets/Icons/iconpark.svg'
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import email from "../Assets/Images/email.gif"
export default function Landingpage() {

    const base_url = process.env.REACT_APP_BASE_URL;

    const [formdata, setFormdata] = useState({ email: '', message: '' });
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);

    const handleEmail = (e) => {
        const { name, value } = e.target;
        setFormdata(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    const submitForm = async (event) => {
        event.preventDefault();
        const res = await fetch(`${base_url}/api/v1/convertkitSubscribe`, {
            body: JSON.stringify({ email: formdata.email }),
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            method: 'POST',
        })
        const data = await res.json();
        if (res.status === 200) {
            setOpen(true);
            setFormdata({ email: '', message: '' });
        } else {
            toast.error(data.message);
        }
        setFormdata(prevData => ({
            ...prevData,
            message: data.message
        }));
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div className=''>
            <ToastContainer />
            <div style={{
                backgroundImage: `url(${bg})`,
                height: '100%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }} >
                <Navbar />
                <div className='flex'>
                    <div style={{
                        opacity: 0.9,
                        background: '#343A40',
                    }} className='flex justify-start xl:mx-10  lg:mx-10 md:mx-10 xl:w-[1096px] xl:h-[86.5%] lg:h-[88.5%] md:h-[560px] xs:h-[560px] xs:w-[1540px]  '>

                        <div className='text-start p-2 xl:mt-32 lg:mt-32 md:mt-14 xs:mt-10 xl:ml-10 lg:ml-2 md:ml-2 xs:ml-4 mx-1'>
                            <h1 className='xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg  text-white font-bold xl:mx-2 xs:mx-1'>Your Premier Destination for </h1>
                            <h2 className='xl:text-7xl lg:text-7xl md:text-5xl xs:text-3xl text-yellow  capitalize font-bold my-2'>CROWDFUNDING EXPERTISE AND COMMUNITY </h2>
                            <div className='xl:pr-16 xs:pr-2'>
                                <p className='mx-1 xl:text-xl lg:text-xl xs:text-base font-semibold text-white my-4 '>TheCrowdFundInvestor.com is committed to equipping self-directed investors with the knowledge required to confidently navigate the world of alternative investments. We strive to transform the complex landscape of crowdfunding into an accessible, understandable, and actionable resource for all.</p>
                            </div>
                            <div className="xl:mt-8 xs:mt-4 xl:flex lg:flex md:flex xs:hidden">
                                <form onSubmit={submitForm} className="flex lg:my-11 xs:my-3" >
                                    <input
                                        type="email"
                                        name="email"
                                        value={formdata.email}
                                        onChange={handleEmail}
                                        className="form-control bg-bg lg:w-72 md:w-64 sm:w-48 xs:w-full px-2 text-lg text-white border-white border-l-[0.98px] border-t-[0.98px] border-b-[0.98px]"
                                        placeholder="Enter Your Email"
                                        style={{ borderRadius: '6.355px 0px 0px 6.355px', '::placeholder': { color: 'white' }, WebkitTextFillColor: 'white' }}
                                        required
                                    />

                                    <div className=" cursor-pointer">
                                        <button className="bg-yellow xl:py-4 xl:px-6 md:py-3  xs:px-6 xs:py-0 xl:text-2xl xs:textbase text-white font-medium hover:text-black" style={{ borderRadius: '0px 6.355px 6.355px 0px' }}>
                                            Get Updates
                                        </button>
                                    </div>

                                </form>
                            </div>
                            <form onSubmit={submitForm} className="xl:hidden md:hidden xs:relative ">
                                <input
                                    type="email"
                                    name="email"
                                    value={formdata.email}
                                    onChange={handleEmail}
                                    className="form-control bg-bg lg:w-72 md:w-64 sm:w-48 xs:w-64 px-2 py-2 text-sm text-white border-white border-[0.98px] my-2"
                                    placeholder="Enter Your Email"
                                    style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px', '::placeholder': { color: 'white' }, WebkitTextFillColor: 'white' }} required
                                />

                                <div className="cursor-pointer">
                                    <button className="bg-yellow px-4 flex justify-start py-2 text-white text-sm font-medium hover:text-black" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                                        Get Updates
                                    </button>
                                </div>

                            </form>
                            <Transition.Root show={open} as={Fragment}>
                                <Dialog
                                    as="div"
                                    className="fixed inset-0 overflow-y-auto z-50"
                                    initialFocus={cancelButtonRef}
                                    onClose={handleClose}
                                >
                                    <div className="flex items-center justify-center min-h-screen">
                                        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
                                        <Transition.Child
                                            as={Fragment}
                                            enter="ease-out duration-300"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="ease-in duration-200"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <div className="bg-white rounded-lg p-4 w-80 sm:w-96" ref={cancelButtonRef}>

                                                <div className="flex justify-end">
                                                    <button onClick={handleClose} >
                                                        <img src={close} alt='close' />
                                                    </button>
                                                </div>
                                                <div className=' text-center text-base font-medium'>
                                                    <div className='flex justify-center'>
                                                        <img src={email} alt=''/>
                                                    </div>
                                                    <p>{formdata.message}</p>
                                                    <p>Thank you for connecting with us.</p>
                                                </div>
                                            </div>
                                        </Transition.Child>
                                    </div>
                                </Dialog>
                            </Transition.Root>
                        </div>
                    </div>

                </div>

            </div>

            <div className="bg-white flex flex-col px-20 pt-12 max-md:px-5 xl:mx-6 ">
                <div className="text-[#343A40] xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg   font-bold  leading-10 tracking-wider uppercase  self-start max-md:max-w-full">
                    Why Choose Us?
                </div>
                <div className=' gird flex grid-cols-2  '>
                    <div className='items-start self-start'>
                        <img src={stepper} alt='' className='xl:h-[25rem] 2xl:mt-5 2xl:h-[23rem] lg:h-[13rem] xl:w-auto lg:w-12 md:w-20 xs:w-[3.5rem] md:h-[14rem] lg:mt-4 xl:mt-2  xl:pb-0 xs:h-[15rem] xms:h-[20rem] xs:mt-2 md:mt-10 my-2 ' />
                    </div>
                    <div className='xl:mx-8 xs:mx-4 xl:my-5 xs:my-1  xs:mt-3'>
                        <div className='xl:flex lg:flex md:flex xl:my-10 xs:my-5 xl:mt-0 xs:mt-2 gap-6 ' >
                            <img src={vec} alt="" className=" xl:mx-4 aspect-[1.05] object-contain object-center xl:w-[86px] md:w-[45px] xs:w-[30px] overflow-hidden self-stretch shrink-0 max-w-full"
                            />
                            <div className="text-neutral-700 text-start   xl:text-xl lg:text-xl md:text-lg xs:text-xs leading-9 tracking-wide capitalize grow shrink basis-auto xl:mt-4 xs:mt-1 self-start max-md:max-w-full">
                                A hub for self-directed investors to explore and research alternative and crowdfunding investment options in one place
                            </div>
                        </div>
                        <div className='xl:flex lg:flex md:flex xl:my-10 xs:my-5 xl:mt-16 xs:mt-2 gap-6' >
                            <img src={iconpark} alt="" className=" xl:mx-4 aspect-[1.05] object-contain object-center xl:w-[86px] md:w-[45px] xs:w-[30px] overflow-hidden self-stretch shrink-0 max-w-full"
                            />
                            <div className="text-neutral-700 text-start  xl:text-xl lg:text-xl md:text-lg xs:text-xs leading-9 tracking-wide capitalize grow shrink basis-auto xl:mt-4 xs:mt-1 self-start max-md:max-w-full">
                            Empowering investors with tools for comprehensive investment opportunity analysis

                            </div>
                        </div>
                        <div className='xl:flex lg:flex md:flex xl:my-10 xs:my-5 xl:mt-16 xs:mt-2 gap-6' >
                            <img src={grommet} alt="" className=" xl:mx-4 aspect-[1.05] object-contain object-center xl:w-[86px] md:w-[45px] xs:w-[30px] overflow-hidden self-stretch shrink-0 max-w-full"
                            />
                            <div className="text-neutral-700 text-start  xl:text-xl lg:text-xl md:text-lg xs:text-xs leading-9 tracking-wide capitalize grow shrink basis-auto xl:mt-4 xs:mt-1 self-start max-md:max-w-full">
                                Educational resources for investors to broaden their asset class
                                knowledge and seize fresh investment opportunities
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <RecentlyAddeed />
            <Mission />
            <Articles />
            <Questions />
            <Footer />

        </div>
    )
}

