import React from 'react';
import lay from '../Assets/Icons/lay.svg'
import { Popover, OverlayTrigger } from "react-bootstrap";

const Search = ({ placeholder,value,onChange,popovertxt }) => {
  const filter = (
    <Popover id="popover-trigger-hover-focus" title="Popover bottom" className=''>
      {popovertxt}
    </Popover>
  );
  return (
    <div className="relative ml-3.5">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="border-[#DCDCDC] border-[1px] bg-[#F8F8F8] rounded-[11px] xs:w-64 py-2 px-10 pr-10 placeholder-gray-500 text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
      />
      <div className="absolute inset-y-0 right-0 xl:pr-[14rem] lg:pr-[14rem] md:pr-[14rem] xs:pr-[14rem] flex items-center pointer-events-none">
        <svg
          className="h-5 w-5 text-[#616161]"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
        </svg>
      </div>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="right"
        overlay={filter}
      >
        <img src={lay} className="cursor-pointer absolute xl:end-3 lg:end-3 md:end-3 xs:end-3 bottom-1.5 my-1  w-5 h-5" alt='' />
      </OverlayTrigger>
    </div>
  );
};

export default Search;
