import React, { useEffect, useRef, useState } from 'react'
import dropdown from '../Assets/Icons/dropdown.svg'
import Search from '../Components/Search'
import TrackInvertedSlider from '../Components/TrackInvertedSlider'
import PaginationButtons from '../Components/PaginationButtons';
import Loader from '../Components/LinearProgress'
import MainPageLayout from '../Pages/Layouts/MainPageLayout';
import bg from '../Assets/Images/bg.png';
import moment from 'moment';
import { collection, doc, setDoc, getDocs, getDoc, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
const Token = process.env.REACT_APP_OFFERING_API_TOKEN;
const base_url = process.env.REACT_APP_BASE_URL;



export default function RealEstateOfferings() {

  const [filterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const filterRef = useRef(null);
  const showFilterMsg = () => {
    setFilterVisible(!filterVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setFilterVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const itemsPerPage = 20;
  const [searchTerm, setSearchTerm] = useState('');

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedFilingTypes, setSelectedFilingTypes] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetch(`${base_url}/api/formD/FormD`, {
            method: "GET",
            headers: {
              "Content-type": "application/json"
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error Status: ${response.status}`);
          }
          const apiData = await response.json();
          const mappedData = apiData.message.offerings
            .filter(offer => !offer.offeringData.typeOfFiling.newOrAmendment.isAmendment)
            .map((offer) => {
              const offeringType = offer.offeringData.federalExemptionsExclusions.item.join(', ');
              let displayValue = '';
              if (offeringType.includes('06b')) {
                displayValue = '506b';
              } else if (offeringType.includes('06c')) {
                displayValue = '506c';
              }
              return {
                name: offer.primaryIssuer.entityName || '',
                City: offer.primaryIssuer.issuerAddress.city || '',
                State: offer.primaryIssuer.issuerAddress.stateOrCountry || '',
                offeeringtype: displayValue,
                filingStatus: offer.offeringData.typeOfFiling.newOrAmendment.isAmendment ? 'Amendment' : 'New',
                amount: offer.offeringData.minimumInvestmentAccepted || '0',
                duration: offer.offeringData.signatureBlock.signature[0].signatureDate,
                phone: offer.primaryIssuer.issuerPhoneNumber || '0',
                relatedperon: `${offer.relatedPersonsList.relatedPersonInfo[0].relatedPersonName.firstName || ''}, ${offer.relatedPersonsList.relatedPersonInfo[0].relatedPersonName.lastName || ''}`,
                industry: offer.offeringData.industryGroup.industryGroupType || '',
              };
            });

          // Filter mappedData to include only USA states
          const usaStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
          const filteredMappedData = mappedData.filter(offer => usaStates.includes(offer.State.toUpperCase()));

          resolve(filteredMappedData);

          const addRealEstateOffering = async (offeringData) => {
            try {
              const issuerName = offeringData.name.replace(/\s+/g, '_');
              const docRef = doc(collection(db, "Form D"), issuerName);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                await updateDoc(docRef, offeringData);
                // console.log("Document data:", docSnap.data());
              } else {
                await setDoc(docRef, offeringData, { merge: true });
              }
            }
            catch (e) {
              console.error("Error adding or updating document: ", e);
            }
          };

          Promise.all(filteredMappedData.map(offering => addRealEstateOffering(offering)))
            .then(() => {
            })
            .catch(error => {
            });
        }
        catch (error) {
          // setError(error.message);
        }
      });
    };

    fetchData()
      .then((result) => {
        // setData(result);
      })
      .catch((error) => {
        console.error('Error fetching data:', error.message);
      });

  }, []);


  function dateDiffInDays(date1, date2) {
    // Convert both dates to milliseconds
    const date1_ms = date1.getTime();
    const date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    const difference_ms = Math.abs(date2_ms - date1_ms);

    // Convert back to days and return
    return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
  }

  const formatDate = (date) => {
    const currentDate = new Date();
    const inputDate = new Date(date);

    const diffDays = dateDiffInDays(currentDate, inputDate);
    const diffMonths = Math.abs(currentDate.getMonth() - inputDate.getMonth()) +
      (12 * (currentDate.getFullYear() - inputDate.getFullYear()));

    if (diffDays <= 7) {
      return '1 Week';
    } else if (diffDays <= 30) {
      return '1 Month';
    } else if (diffMonths <= 3) {
      return '3 Months';
    } else {
      return 'Year';
    }
  };

  const [sliderValues, setSliderValues] = useState([0, 100]);
  const [sliderUsed, setSliderUsed] = useState(false);

  const minRevenue = Math.min(...data.map(row => row.amount));
  const maxRevenue = Math.max(...data.map(row => row.amount));


  const handleSliderChange = (values) => {
    setSliderValues(values);
    setSliderUsed(true);
  };



  const filteredDataWithoutSlider = data.filter(row => (
    (selectedDates.length === 0 || selectedDates.includes(formatDate(row.duration))) &&
    (selectedStates.length === 0 || selectedStates.includes(row.State)) &&
    (selectedFilingTypes.length === 0 || (
      selectedFilingTypes.includes('Amendment') && row.filingStatus === 'Amendment' ||
      selectedFilingTypes.includes('New') && row.filingStatus === 'New'
    )) &&
    (!searchTerm || (row.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.industry.toLowerCase().includes(searchTerm.toLowerCase())))
  ));

  const filteredDataWithSlider = sliderUsed
    ? filteredDataWithoutSlider.filter(row => {
      const totalRevenues = parseFloat(row.amount);
      return (totalRevenues >= sliderValues[0] && totalRevenues <= sliderValues[1]);
    })
    : filteredDataWithoutSlider;

  const filteredData = filteredDataWithSlider;
  const handleFilingTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedFilingTypes([selectedType]);
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [originalData, setOriginalData] = useState([]);
  const resetFilters = () => {
    setSelectedDates([]);
    setSelectedStates([]);
    setSelectedFilingTypes([]);
    setSearchTerm('');
    setSliderValues([0, 100]);
    setSliderUsed(false);
    // setData(originalData);
  };


  const uniqueLocations = Array.from(new Set(data.map(item => item.State))).sort();
  const uniqueDates = Array.from(new Set(data.map(item => formatDate(item.duration))));
  const sortOrder = ['1 Week', '1 Month', '3 Months', 'Year'];
  uniqueDates.sort((a, b) => {
    return sortOrder.indexOf(a) - sortOrder.indexOf(b);
  });
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
  const filteredPosts = filteredData.slice(startIndex, endIndex);
  // const [daata, setDaata] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "Form D"));
      const offerings = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setData(offerings);
      setLoading(false);
      // setOriginalData(mappedData);

    };

    fetchData();
  }, []);
  const usaStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

  return (
    <MainPageLayout heading="Real Estate Offerings" text="THE CROWDFUND INVESTOR PROVIDES THE INDUSTRY’S ONLY DEDICATED DATABASE OF 506b AND 506c COMMERCIAL REAL ESTATE OFFERINGS" style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
      height: '368px',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    }}
    >

      <div className='h-full'>
        <div className='flex justify-between mx-5 my-4 '>
          <div className='flex justify-start w-72 '>
            <Search placeholder="Search by Industry group"
              value={searchTerm}
              popovertxt="Search and find offerings for all property types (including Commercial, Construction, REITS & Finance, Residential and Other)"
              onChange={e => setSearchTerm(e.target.value)} />
          </div>
          <div className="relative" ref={filterRef}>
            <div className="inline-flex items-center overflow-hidden  bg-white xl:mx-3 xs:mx-2 flex-" >
              <button
                onClick={showFilterMsg}
                className="border-[#625D42] border-[1px] rounded-[7px] xl:px-3 xl:py-1 xs:p-2 xl:text-xl xs:text-base  flex text-[#625D42]"
              >
                Filter
                <img src={dropdown} alt='' className=' xl:w-6 xl:h-5 xs:w-3 xs:h-5 xs:mx-2 xl:mx-0 xl:pt-3 xs:pt-2  ' />

              </button>
            </div>

            {filterVisible && (
              <div
                className="absolute end-0 z-10 mt-2 xl:w-auto xs:w-72 lg:w-auto md:w-auto divide-y divide-gray-100 rounded-xl border border-gray-100 bg-white "
                role="menu"
                style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
              >
                <div className='text-start p-9'>
                  <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-1'>Date Filed</div>
                  <div className='my-1'>
                    <div className='my-1'>
                      <select
                        className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                        onChange={(e) => setSelectedDates(e.target.value)}
                        value={selectedDates.toString()}
                      >
                        <option value="">All </option>
                        {uniqueDates.map((date, index) => (
                          <option key={index} value={date}>{date}</option>
                        ))}
                      </select>
                    </div>

                  </div>

                  <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Minimum Investment</div>
                  <div className='my-2 mx-4 pt-5'>
                    <TrackInvertedSlider minRevenues={minRevenue} maxRevenues={maxRevenue} onChange={handleSliderChange} />

                  </div>

                  <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>State</div>
                  <div className='my-1'>
                    <select
                      className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                      onChange={(e) => setSelectedStates(e.target.value)}
                      value={selectedStates.toString()}
                    >
                      <option value="">All</option>
                      {uniqueLocations
                        .filter(location => usaStates.includes(location.toUpperCase())) // Filter uniqueLocations to include only USA states
                        .map((state, index) => (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        ))}
                    </select>

                  </div>
                  <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Type of filling</div>
                  <div className='my-1 flex mb-8'>
                    <div className="flex items-center mx-2">
                      <input
                        id="default-radio-1"
                        type="radio"
                        value="Amendment"
                        className="w-4 h-4 text-custom-color bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={handleFilingTypeChange}
                        checked={selectedFilingTypes.includes('Amendment')}
                      />
                      <label
                        htmlFor="default-radio-1"
                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Amendment
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="default-radio-2"
                        type="radio"
                        value="New"
                        className="w-4 h-4 text-custom-color bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        onChange={handleFilingTypeChange}
                        checked={selectedFilingTypes.includes('New')}
                      />

                      <label
                        htmlFor="default-radio-2"
                        className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        New Filing
                      </label>
                    </div>
                  </div>
                  <div className='flex justify-center'>
                    <button type="submit" className="bg-yellow px-2 xl:w-[100px] xs:w-[70px] flex justify-center py-2.5 text-white xl:text-lg xs:text-sm font-medium hover:text-black" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} onClick={resetFilters}>
                      Reset
                    </button>
                  </div>

                </div>
              </div>
            )}
          </div>
        </div>
        {loading &&
          <div className="mx-10 pt-32 items-center h-[50vh]">
            <p className='text-lg my-2 font-medium'>Loading ....</p>
            <div className='flex justify-center items-center'>
              <Loader />
            </div>
          </div>}
        {error &&
          <div className='text-center mx-10 mt-16 h-[50vh]'>
            <div className='flex justify-center'>
              <p className='text-yellow text-3xl font-medium'>Error:</p>
              <p className='text-black mx-2 text-3xl font-medium'>{error}</p>
            </div>
            <p className='text-black mx-2 text-4xl font-medium my-2'>Please try again later</p>
          </div>
        }
        {!loading && !error && (
          <div className='mx-7 my-5 h-auto'>
            {filteredPosts.length === 0 ? (
              <div className="mx-10 items-center h-[70vh]">
                <p className='text-xl my-2 font-medium '>No matching results found.</p>
              </div>
            ) : (
              <div className="overflow-x-auto rounded-lg border-none">
                <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                  <thead className="ltr:text-left rtl:text-right">
                    <tr className=''>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 xs:text-sm xl:text-lg font-semibold py-2  text-[#060606] "></th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 xs:text-sm xl:text-lg font-semibold py-2  text-[#060606]">Name of Issuer</th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 py-2 xs:text-sm xl:text-lg font-semibold text-[#060606]">City/State</th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 py-2 xs:text-sm xl:text-lg font-semibold text-[#060606]">Type of Offerings</th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 py-2 xs:text-sm xl:text-lg font-semibold text-[#060606]">Type of Filings</th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 py-2 xs:text-sm xl:text-lg font-semibold text-[#060606]">Minimum Investment</th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 py-2 xs:text-sm xl:text-lg font-semibold text-[#060606]">Filling Date</th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 py-2 xs:text-sm xl:text-lg font-semibold text-[#060606]">Industry group</th>
                      <th className="whitespace-nowrap md:text-[15px] lg:text-lg  xs:px-2 py-2 xs:text-sm xl:text-lg font-semibold text-[#060606]">Link of Website</th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200">
                    {filteredPosts
                      .filter((item) => item.industry !== "Pooled Investment Fund" &&
                        item.industry !== "Insurance" &&
                        item.industry !== "Commercial Banking" &&
                        item.industry !== "Other Banking and Financial Services" &&
                        item.industry !== "Investment Banking" &&
                        item.industry !== "Business Services" &&
                        item.industry !== "Manufacturing" &&
                        item.industry !== "Other Technology" &&
                        item.industry !== "Agriculture" &&
                        item.industry !== "Pharmaceuticals" &&
                        item.industry !== "Biotechnology" &&
                        item.industry !== "Oil and Gas" &&
                        item.industry !== "Other Health Care" &&
                        usaStates.includes(item.State.toUpperCase())) // Add this line to filter by USA states
                      .map((row, index) => {
                        return (
                          <tr key={index}>
                            <td className="w-6 xs:px-2 py-2 text-base text-[#060606]">
                              <div className='bg-[#625D42] mx-2 h-2 w-2'></div>
                            </td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]">{row.name}</td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">{row.City}, {row.State}</td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">{row.offeeringtype}</td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">{row.filingStatus}</td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                              {row.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                            </td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">{formatDate(row.duration)}</td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">{row.industry}</td>
                            <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                              <a
                                href={`https://www.google.com/search?q=${encodeURIComponent(
                                  `${row.name || ''} ${row.location || ''} ${row.phone || ''} ${row.relatedperon || ''}`
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='underline'
                              >
                                View Link
                              </a>
                            </td>
                          </tr>
                        );
                      })}

                  </tbody>
                </table>
              </div>)}
          </div>
        )}
        <div className='xl:flex justify-between mt-22 mx-2  my-2 '>
          <div className='flex justify-center xl:mx-5 my-1'>
            <PaginationButtons count={totalPages} page={currentPage} onChange={handlePageChange} />
          </div>
          <div className='flex justify-center gap-2 mx-5 my-1'>
            <p className='mt-1'>Page</p>
            <select className='border-[1px] border-[#DDDDDD] rounded-[10px] px-1 cursor-pointer'
              onChange={(e) => setCurrentPage(Number(e.target.value))} value={currentPage}>
              {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                <option key={page} value={page}>{page}</option>
              ))}
            </select>
            <p className='mt-1'>of {totalPages}</p>
          </div>
        </div>
      </div>
    </MainPageLayout>
  )
}