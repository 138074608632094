import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

export default function AffiliateDisclosure() {
    return (
        <div>
            <Navbar />
            <div className="bg-white flex flex-col items-stretch my-5 mb-3  xl:mx-5">

                <div className="text-[#625D42] xl:text-[60px] xs:text-3xl my-2 mb-3 font-semibold tracking-wide uppercase self-center  max-md:max-w-full max-md:text-4xl mt-3">
                    Affiliate Disclosure
                </div>
                <div className="text-[#616161] my-3 xl:text-lg xs:text-base leading-5 self-start text-start mx:10 xl:mx-auto xl:w-[85rem] lg:mx-10  md:mx-10 xs:mx-5  mt-7 max-md:max-w-full">
                    <div className='my-2'>
                        This website may contain affiliate links, which means that if you click on a link and make a purchase, we may earn a commission at no additional cost to you. These affiliate links are a way for us to earn compensation for promoting products, services, or resources that we believe can provide value to our audience.
                    </div>
                    <div className='my-2'>
                        It's important to note that we only recommend products, services, or resources that we genuinely believe in. Our recommendations are based on our independent research, expertise, and knowledge of the industry. However, your decision to use or purchase any product or service is entirely voluntary and should be based on your own assessment and judgment.
                    </div>
                    <div className='my-2'>
                        We want to be fully transparent with our readers and provide insight into our affiliate relationships. These relationships do not impact the content, topics, or posts we create. We maintain our editorial integrity and independence, and our recommendations are solely driven by our commitment to delivering valuable information to you.
                    </div>
                    <div className='my-2'>
                        Your support through using our affiliate links is greatly appreciated, as it enables us to continue producing high-quality content and resources for you. If you have any questions or concerns about the products or services we recommend, please don't hesitate to reach out to us for clarification.

                    </div>
                    <div className='my-2'>
                        It's worth emphasizing that while affiliate relationships are a way for us to sustain our website, your trust is of the utmost importance to us. We will always strive to provide accurate and unbiased information to help you make informed decisions.
                    </div>
                    <div className='my-2'>
                        Thank you for your understanding and support.

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
