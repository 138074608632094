import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, getDoc, collection, addDoc, serverTimestamp, getDocs, query, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import Loader from '../Components/LinearProgress';

export default function ArticleDetail() {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const fetchPost = async () => {
            const postDoc = await getDoc(doc(db, 'admin', postId));
            if (postDoc.exists()) {
                setPost({ id: postDoc.id, ...postDoc.data() });
                setLoading(false);

            } else {
                setError("Post Not Found")
                setLoading(false);

            }
        };
        fetchPost();
    }, [postId]);

    useEffect(() => {
        const fetchComments = async () => {
            const commentsQuery = query(
                collection(db, 'comments'),
                where('postId', '==', postId),
                where('approved', '==', true)
            );
            const commentsSnapshot = await getDocs(commentsQuery);
            const comments = commentsSnapshot.docs
                .map((doc) => ({ id: doc.id, ...doc.data() }))
                .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
            setComments(comments);
        };
        fetchComments();
    }, [postId]);

    const handleCommentSubmit = async (event) => {
        event.preventDefault();
        try {
            await addDoc(collection(db, 'comments'), {
                postId: postId,
                comment: comment,
                name: name,
                email: email,
                timestamp: serverTimestamp()
            });
            toast.success("Comment added successfully!");
            setComment('');
            setName('');
            setEmail('');
        } catch (error) {
            alert('Error adding comment:', error.message);
        }
    };


    return (
        <div>
            <Navbar />
            <ToastContainer />
            <div>

            {loading &&
                    <div className="mx-10 items-center h-[50vh]">
                        <p className='text-lg my-2 font-medium mt-32'>Loading ....</p>
                        <div className='flex justify-center items-center'>
                            <Loader />
                        </div>
                    </div>}
                {error &&
                    <div className='text-center mx-10 mt-16 h-[50vh]'>
                        <div className='flex justify-center mt-32'>
                            <p className='text-yellow text-3xl font-medium'>Error:</p>
                            <p className='text-black mx-2 text-3xl font-medium'>{error}</p>
                        </div>
                    </div>
                }
                  {!loading && !error && (<>
                <div className='text-start'>
                    <img src={post.coverImageUrl} alt='' className='w-full h-80' />
                    <div className='xl:mx-36 lg:mx-10 md:mx-10 xs:mx-5 my-10 mt-3'>
                        <div className='border-b-[#00000091] border-b-[1px]  pb-4 py-9'>
                            <h1 className='font-medium xl:text-6xl xs:text-4xl my-2'>{post.title}</h1>
                            <p className='text-[#616161] font-normal xl:text-3xl xs:text-lg my-3 mb-7'>Published on {post.timestamp.toDate().toLocaleString([], { dateStyle: 'medium' })}</p>

                        </div>
                        <div className='my-8 text-lg text-[#343A40]'>
                            <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                        </div>
                    </div>
                </div>

                <div className="my-4 xl:mx-36 xs:mx-5 border-t-[#00000091] border-t-[1px] py-4">
                    <h2 className="font-medium text-2xl text-start  ">{comments.length} Comments</h2>
                    {comments.map((comment) => (
                        <div key={comment.id} className=" text-start py-2 px-4 my-4 border-[1px] rounded-lg">
                            <div className='flex justify-between'>
                                <p className='text-[18px] text-[#343A40] font-semibold'>{comment.name}</p>
                                <p className='text-[18px] text-[#343A40] font-semibold'>{comment.timestamp.toDate().toLocaleString([], { dateStyle: "medium" })}</p>
                            </div>
                            <p className='text-[16px] font-normal text-[#343A40]'>{comment.comment}</p>
                        </div>
                    ))}
                </div>
                <div className='border-b xl:mx-36 xs:mx-5'>
                    <h2 className="font-medium text-2xl text-start  ">Post a comment</h2>
                    <form className=' my-3  pb-5 ' onSubmit={handleCommentSubmit}>
                        <div className="xl:flex lg:flex md:flex gap-4  content-start justify-start my-2 ">
                            <div className='xl:my-0 xs:my-3 flex justify-start'>
                                <input
                                    type="fname" id="fname"
                                    placeholder="Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    className="border-[#DCDCDC] border-[1px] bg-[white] py-2 xl:w-72 md:w-52 xs:w-[25rem]  px-2 placeholder-[#8E8B8B] text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                                    style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} required />
                            </div>

                            <div className='xl:my-0 xs:my-3 flex justify-start'>
                                <input
                                    type="email" id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="border-[#DCDCDC] border-[1px] bg-[white] py-2 xl:w-72 md:w-52 xs:w-[25rem] px-2 placeholder-[#8E8B8B] text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                                    style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} required />
                            </div>

                        </div>
                        <div className='flex justify-start my-6'>
                            <textarea
                                type="name"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Say something.."
                                className="border-[#DCDCDC] border-[1px] bg-[white] py-2 xl:w-full md:w-full xs:w-[25rem] h-auto  px-2 placeholder-[#8E8B8B] text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                                style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} required
                                rows={6} />
                        </div>
                        <div>
                            <div className=" mx-1">
                                <button type="submit " className="bg-yellow px-2 xl:w-[139px]  xs:w-[100px] flex justify-center py-2.5 text-white xl:text-lg xs:text-sm font-medium hover:text-black" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                                    Post
                                </button>
                            </div>
                        </div>
                    </form>
                </div></>)}
            </div>
            <Footer />
        </div>
    );
}
