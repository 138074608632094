import React, { useState, useEffect } from 'react';
import { ScatterChart, XAxis, YAxis, CartesianGrid, Tooltip, Scatter, ResponsiveContainer } from 'recharts';
import MainPageLayout from './Layouts/MainPageLayout';
import bg from '../Assets/Images/bg.png';
import Loader from '../Components/LinearProgress';

const Token = process.env.REACT_APP_FEE_tOKEN;
const rangeColors = ['#BEA245', '#625D42', '#616161'];

const RealEstateFee = () => {
    const [apiData, setApiData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedFeeType, setSelectedFeeType] = useState('Acquisition Fee %');
    const [selectedPropertyType, setSelectedPropertyType] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://sheet.best/api/sheets/${Token}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                const data = await response.json();
                setApiData(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const handleFeeTypeChange = (event) => {
        setSelectedFeeType(event.target.value);
    };

    const handlePropertyTypeChange = (event) => {
        setSelectedPropertyType(event.target.value);
    };

    const filterData = (data, selectedFeeType, selectedPropertyType) => {
        return data.filter(item => {
            const fee = item[selectedFeeType];
            const propertyType = item['Property Type'];
            const maxLimits = {
                "Acquisition Fee %": 5,
                "Disposition Fee %": 5,
                "Development Fee %": 10,
                "Asset Management - Revenue": 6,
                "Asset Management - Equity": 3,
                "Property Management Fee %": 6,
                "Construction Management Fee %": 10,
                "Finance Related Fees": 5
            };

            return fee !== "" && fee !== "0" && (selectedPropertyType === '' || propertyType === selectedPropertyType) && parseInt(fee) <= maxLimits[selectedFeeType];
        });
    };


    const generateChartData = (filteredData) => {
        return filteredData.map((item, index) => ({
            x: parseFloat(item[selectedFeeType]),
            y: parseFloat(item['Pro Forma Investor IRR']),
            color: rangeColors[index % rangeColors.length]
        }));
    };

    const filteredData = filterData(apiData, selectedFeeType, selectedPropertyType);

    const chartData = generateChartData(filteredData);

    const propertyTypes = [
        'Build-to-Rent',
        'Flex',
        'Hotel/Hospitality',
        'Industrial',
        'Medical Office',
        'Mixed-Use',
        'Mobile Home Park',
        'Multi-Strategy',
        'Multifamily',
        'Office',
        'Retail',
        'Self Storage',
        'Senior Housing',
        'Special Use',
        'Student Housing',
    ];
    const chartDataWithColors = chartData.map((dataPoint, index) => ({
        ...dataPoint,
        fill: rangeColors[index % rangeColors.length]
    }));

    const feeTypeTicks = {
        "Acquisition Fee %": [0, 1, 2, 3, 4, 5],
        "Disposition Fee %": [0, 1, 2, 3, 4, 5],
        "Development Fee %": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        "Asset Management - Revenue": [0, 1, 2, 3, 4, 5, 6],
        "Asset Management - Equity": [0, 1, 2, 3],
        "Property Management Fee %": [0, 1, 2, 3, 4, 5, 6],
        "Construction Management Fee %": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        "Finance Related Fees": [0, 1, 2, 3, 4, 5]
    };

    return (
        <>
            <MainPageLayout heading="Real Estate Fees"
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
                    height: '250px',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                {loading &&
                    <div className="mx-10 pt-32 items-center h-[50vh]">
                        <p className='text-lg my-2 font-medium'>Loading ....</p>
                        <div className='flex justify-center items-center'>
                            <Loader />
                        </div>
                    </div>}
                {!loading && (
                    <div className='my-5'>
                        <div className=' my-10 md:flex inline gap-4 md:justify-center'>
                            <div className='md:flex inline gap-4 md:justify-center'>
                                <p className='mt-3 font-medium '>Property Type</p>
                                <select value={selectedPropertyType} onChange={handlePropertyTypeChange}
                                    className="mt-3 border rounded-[7px] border-[#343A40]">
                                    <option value="">All Properties</option>
                                    {propertyTypes.map((type, index) => (
                                        <option key={index} value={type}>{type}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='md:flex inline gap-4 md:justify-center'>
                                <p className='mt-3 font-medium'>Fee Type</p>

                                <select value={selectedFeeType} onChange={handleFeeTypeChange}
                                    className="mt-3 border rounded-[7px] border-[#343A40]">
                                    <option value="Acquisition Fee %">Acquisition Fee %</option>
                                    <option value="Disposition Fee %">Disposition Fee %</option>
                                    <option value="Development Fee %">Development Fee %</option>
                                    <option value="Asset Management - Revenue">Asset Management Fee (Revenue)</option>
                                    <option value="Asset Management - Equity">Asset Management Fee (Equity)</option>
                                    <option value="Property Management Fee %">Property Management Fee %</option>
                                    <option value="Construction Management Fee %">Construction Management Fee %</option>
                                    <option value="Finance Related Fees">Finance Related Fees</option>
                                </select>
                            </div>
                        </div>
                        <div className='rounded-[18px]  md:w-[40rem] lg:w-[45rem] xs:w-[23rem] h-[610px] my-5 mx-auto' style={{ boxShadow: ' 0px 5.189px 12.973px 0px rgba(0, 0, 0, 0.10)' }}>
                            <h1 className='font-bold text-2xl text-start m-4 pt-3'>{selectedFeeType} </h1>
                            <div style={{ width: '90%', height: '400px' }} className='my-3 '>
                                <ResponsiveContainer width="100%" height={550}>
                                    <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                        <CartesianGrid strokeDasharray="1 1" />
                                        <XAxis
                                            type="number"
                                            dataKey="x"
                                            name={selectedFeeType}
                                            tick={{ fontSize: '12px' }}
                                            label={{ value: selectedFeeType, position: 'insideBottom', offset: -10 }}
                                            tickFormatter={(value) => `${value}%`}
                                            domain={[0, feeTypeTicks[selectedFeeType][feeTypeTicks[selectedFeeType].length - 1]]}
                                            ticks={feeTypeTicks[selectedFeeType]}
                                        />

                                        <YAxis
                                            type="number"
                                            dataKey="y"
                                            name="Pro Forma Investor IRR"
                                            tickFormatter={(value) => `${value}%`}
                                            label={{ value: 'Pro Forma Investor IRR', angle: -90, position: 'insideLeft' }}
                                            tick={{ fontSize: '12px' }}
                                            ticks={[0, 10, 20, 30, 40]} />

                                        <Tooltip
                                            cursor={{ strokeDasharray: '3 3' }}
                                            formatter={(value, name, props) => [`${value}%`, name]}
                                        />
                                        {chartDataWithColors.map((dataPoint, index) => (
                                            <Scatter key={index} name="Scatter" data={[dataPoint]} fill={dataPoint.fill} />
                                        ))}
                                    </ScatterChart>

                                </ResponsiveContainer>


                            </div>
                        </div>
                    </div>
                )}
            </MainPageLayout>
        </>
    );
};

export default RealEstateFee;