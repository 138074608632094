import AdminNavbar from '../Admin/AdminNavbar'
import Footer from '../../Components/Footer'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function AuthLayout({ children }) {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
  const auth = getAuth();
  const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);

    if (!currentUser) {
      navigate('/signin');
    }
  });

  return () => {
    unsubscribe();
  };
}, [navigate]);

  return (
    <div>
      {user && (
        <>
          <AdminNavbar />
          {children}
          <Footer />
        </>
      )}
    </div>
  );
}
