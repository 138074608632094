import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc, collection, addDoc, serverTimestamp, getDocs, query, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../Components/Navbar'
import Footer from '../../Components/Footer'
import Loader from '../../Components/LinearProgress';
import AuthLayout from '../Layouts/AuthLayout';

export default function AdminArticlePage() {
    const { postId } = useParams();
    const [post, setPost] = useState(null);
    const [comment, setComment] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comments, setComments] = useState([]);

    useEffect(() => {
        const fetchPost = async () => {
            const postDoc = await getDoc(doc(db, 'admin', postId));
            if (postDoc.exists()) {
                setPost({ id: postDoc.id, ...postDoc.data() });
            } else {
            }
        };
        fetchPost();
    }, [postId]);

    useEffect(() => {
        const fetchComments = async () => {
            const commentsQuery = query(
                collection(db, 'comments'),
                where('postId', '==', postId),
                where('approved', '==', true)
            );
            const commentsSnapshot = await getDocs(commentsQuery);
            const comments = commentsSnapshot.docs
                .map((doc) => ({ id: doc.id, ...doc.data() }))
                .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
            setComments(comments);
        };
        fetchComments();
    }, [postId]);

    const handleCommentSubmit = async (event) => {
        event.preventDefault();
        try {
            await addDoc(collection(db, 'comments'), {
                postId: postId,
                comment: comment,
                name: name,
                email: email,
                timestamp: serverTimestamp()
            });
            toast.success("Comment added successfully!");
            setComment('');
            setName('');
            setEmail('');
        } catch (error) {
            alert('Error adding comment:', error.message);
        }
    };

    if (!post) {
        return (
            <div className="mx-10 items-center h-[80vh]">
                <p className="text-lg my-2 font-medium">Loading ....</p>
                <div className="flex justify-center items-center">
                    <Loader />
                </div>
            </div>
        );
    }

    return (
        <div>
            <AuthLayout>
                <ToastContainer />
                <div className='text-start'>
                    <img src={post.coverImageUrl} alt='' className='w-full h-80' />
                    <div className='xl:mx-36 xs:mx-5 my-10 mt-3'>
                        <div className='border-b-[#00000091] border-b-[1px]  pb-4 py-9'>
                            <p className='text-[#625D42] font-semibold xl:text-4xl xs:text-xl'>The CrowdFund Investor </p>
                            <h1 className='font-medium xl:text-6xl xs:text-4xl my-2'>{post.title}</h1>
                            <p className='text-[#616161] font-normal xl:text-3xl xs:text-lg my-3 mb-7'>Published on {post.timestamp.toDate().toLocaleString([], { dateStyle: 'medium' })}</p>

                        </div>
                        <div className='my-8 text-lg text-[#343A40]'>
                            <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                        </div>
                    </div>
                </div>

                <div className="my-4 xl:mx-36 xs:mx-5 border-t-[#00000091] border-t-[1px] py-4">
                    <h2 className="font-medium text-2xl text-start  ">{comments.length} Comments</h2>
                    {comments.map((comment) => (
                        <div key={comment.id} className=" text-start py-2 px-4 my-4 border-[1px] rounded-lg">
                            <div className='flex justify-between'>
                                <p className='text-[18px] text-[#343A40] font-semibold'>{comment.name}</p>
                                <p className='text-[18px] text-[#343A40] font-semibold'>{comment.timestamp.toDate().toLocaleString([], { dateStyle: "medium" })}</p>
                            </div>
                            <p className='text-[16px] font-normal text-[#343A40]'>{comment.comment}</p>
                        </div>
                    ))}
                </div>
                <div className='border-b xl:mx-36 xs:mx-5'>
                    <h2 className="font-medium text-2xl text-start  ">Post a comment</h2>
                    <form className=' my-3  pb-5 ' onSubmit={handleCommentSubmit}>
                        <div className="flex gap-4 my-2 ">
                            <input
                                type="fname" id="fname"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="border-[#DCDCDC] border-[1px] bg-[white] py-2 xl:w-72 xs:w-40  px-2 placeholder-[#8E8B8B] text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                                style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} required />

                            <input
                                type="email" id="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border-[#DCDCDC] border-[1px] bg-[white] py-2 xl:w-72 xs:w-40  px-2 placeholder-[#8E8B8B] text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                                style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} required />

                        </div>
                        <div className='flex justify-start my-6'>
                            <textarea
                                type="name"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Say something.."
                                className="border-[#DCDCDC] border-[1px] bg-[white] py-2 xl:w-full xs:w-40 h-auto  px-2 placeholder-[#8E8B8B] text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                                style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} required />
                        </div>
                        <div>
                            <div className=" mx-1">
                                <button type="submit " className="bg-yellow xl:px-8  xs:px-4 flex justify-start py-2.5 text-white xl:text-base xs:text-sm font-medium" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                                    Post
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </AuthLayout>
        </div>
    );
}
