import React, { useEffect, useRef, useLayoutEffect, useState } from 'react'
import dropdown from '../Assets/Icons/dropdown.svg'
import Search from '../Components/Search'
import TrackInvertedSlider from '../Components/TrackInvertedSlider'
import PaginationButtons from '../Components/PaginationButtons';
import MainPageLayout from '../Pages/Layouts/MainPageLayout';
import bg from '../Assets/Images/bg.png';
import Loader from '../Components/LinearProgress';
import { db } from '../firebase';
import { doc, getDocs, setDoc, getDoc, updateDoc, collection } from 'firebase/firestore';

const base_url = process.env.REACT_APP_BASE_URL;

export default function RegA() {

    const [filterVisible, setFilterVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const filterRef = useRef(null);
    const showFilterMsg = () => {
        setFilterVisible(!filterVisible);
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (filterRef.current && !filterRef.current.contains(event.target)) {
                setFilterVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [URL, setURL] = useState([]);

    useLayoutEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${base_url}/api/formA/FormA`, {
                    method: "GET",
                    headers: {
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*"
                    },
                });
                const result = await response.json();
                const linkToTxtArray = result.message.filings
                setURL(linkToTxtArray);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    const [filerData, setFilerData] = useState([]);

    useEffect(() => {
        const fetchTxtData = async () => {
            if (URL.length === 0) return;

            try {
                const dataPromises = URL.map(async (filling) => {
                    const response = await fetch(`${base_url}/api/v1/secdata`, {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json"
                        },
                        body: JSON.stringify({ url: filling.linkToTxt })
                    });
                    const data = await response.json();
                    return extractFilerData(data.response);
                });

                const filerDataArray = await Promise.all(dataPromises);
                setFilerData(filerDataArray.flat());
            } catch (error) {
            }
        };

        fetchTxtData();
    }, [URL]);
    const usaStates = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

    const extractFilerData = async (textData) => {
        const filerDataMap = {};

        const regex = /<SEC-HEADER>(.*?)<\/SEC-HEADER>|<formData>(.*?)<\/formData>/gs;
        let match;
        let currentCompany = null;


        while ((match = regex.exec(textData)) !== null) {
            if (match[1]) {

                const companyNameMatch = /COMPANY CONFORMED NAME:\s+(.*?)\n/.exec(match[1]);
                const companyName = companyNameMatch ? companyNameMatch[1] : null;
                console.log(companyName, "companyName")
                const stateMatch = /STATE:\s+(.*?)\n/.exec(match[1]);
                const state = stateMatch ? stateMatch[1] : null;
                if (!usaStates.includes(state.toUpperCase())) {
                    continue;
                }
                const streetOneMatch = /STREET 1:\s+(.*?)\n/.exec(match[1]);
                const streetOne = streetOneMatch ? streetOneMatch[1] : " ";
                const streetTwoMatch = /STREET 2:\s+(.*?)\n/.exec(match[1]);
                const streetTwo = streetTwoMatch ? streetTwoMatch[1] : " ";
                const CityMatch = /CITY:\s+(.*?)\n/.exec(match[1]);
                const City = CityMatch ? CityMatch[1] : " ";
                const zipMatch = /Zip:\s+(.*?)\n/.exec(match[1]);
                const zip = zipMatch ? zipMatch[1] : " ";
                const PhoneMatch = /BUSINESS PHONE:\s+(.*?)\n/.exec(match[1]);
                const Phone = PhoneMatch ? PhoneMatch[1] : " ";
                const fullAddress = `${streetOne} ${streetTwo} ${City} ${zip} ${Phone}`;
                
                const filedDateMatch = /FILED AS OF DATE:\s+(.*?)\n/.exec(textData);
                let filedDate = filedDateMatch ? filedDateMatch[1] : null;
                if (filedDate) {
                    filedDate = filedDate.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3");
                }
                currentCompany = companyName;
                filerDataMap[currentCompany] = { companyName, state, filedDate, fullAddress };
            } else if (match[2] && currentCompany) {
                const issuerNameMatch = /<issuerInfo>\s*<nameOfIssuer>(.*?)<\/nameOfIssuer>\s*<\/issuerInfo>/s.exec(match[2]);
                const issuerName = issuerNameMatch ? issuerNameMatch[1] : null;

                const yearIncorporationMatch = /<yearIncorporation>(.*?)<\/yearIncorporation>/.exec(match[2]);
                const yearIncorporation = yearIncorporationMatch ? yearIncorporationMatch[1] : null;
                const fullTimeEmployeesMatch = /<fullTimeEmployees>(.*?)<\/fullTimeEmployees>/.exec(match[2]);
                const fullTimeEmployees = fullTimeEmployeesMatch ? fullTimeEmployeesMatch[1] : null;
                const totalStockholderEquityMatch = /<totalStockholderEquity>(.*?)<\/totalStockholderEquity>/.exec(match[2]);
                const totalStockholderEquity = totalStockholderEquityMatch ? totalStockholderEquityMatch[1] : null;
                const totalRevenuesMatch = /<totalRevenues>(.*?)<\/totalRevenues>/.exec(match[2]);
                const totalRevenues = totalRevenuesMatch ? totalRevenuesMatch[1] : null;
                const netIncomeMatch = /<netIncome>(.*?)<\/netIncome>/.exec(match[2]);
                const netIncome = netIncomeMatch ? netIncomeMatch[1] : null;
                const issuerWebsiteMatch = /<issuerWebsite>(.*?)<\/issuerWebsite>/.exec(match[2]);
                const issuerWebsite = issuerWebsiteMatch ? issuerWebsiteMatch[1] : null;

                if (yearIncorporation) {
                    filerDataMap[currentCompany] = {
                        ...filerDataMap[currentCompany],
                        issuerName,
                        yearIncorporation,
                        fullTimeEmployees,
                        totalStockholderEquity,
                        totalRevenues,
                        netIncome,
                        issuerWebsite,
                    };
                }
            }
        }
        const filerDataArray = Object.values(filerDataMap);
        await Promise.all(filerDataArray.map(async (item, index) => {
            const sanitizedCompanyName = item.companyName.replace(/\//g, '_');

            const docRef = doc(collection(db, 'Form A'), sanitizedCompanyName);
            const docSnap = await getDoc(docRef);

            if (!docSnap.exists()) {
                // Document does not exist, create it
                await setDoc(docRef, {
                    company: item.companyName,
                    State: item.state,
                    Year: item.yearIncorporation || '',
                    Full_Time_Employees: item.fullTimeEmployees || '',
                    Stockholder_Equity: item.totalStockholderEquity || '',
                    Total_Revenues: item.totalRevenues || '',
                    Net_Income: item.netIncome || '',
                    Issuer_Website: item.issuerWebsite || '',
                    filed_Date: item.filedDate,
                    fullAddress: item.fullAddress || ''
                }, { merge: true });
                // console.log(`Document created for ${item.companyName}:`);

            } else {
                // Document exists, update it
                await updateDoc(docRef, {
                    company: item.companyName,
                    State: item.state,
                    Year: item.yearIncorporation || '',
                    Full_Time_Employees: item.fullTimeEmployees || '',
                    Stockholder_Equity: item.totalStockholderEquity || '',
                    Total_Revenues: item.totalRevenues || '',
                    Net_Income: item.netIncome || '',
                    Issuer_Website: item.issuerWebsite || '',
                    filed_Date: item.filedDate,
                    fullAddress: item.fullAddress || ''
                });
                // console.log(`Document updated for ${item.companyName}, ${item.filedDate}:`);

            }
        }));
        return filerDataArray;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'Form A'));
                const data = querySnapshot.docs.map(doc => doc.data());
                setFilerData(data);
                setLoading(false);
            } catch (error) {
                setError(error.message);
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    function dateDiffInDays(date1, date2) {
        // Convert both dates to milliseconds
        const date1_ms = date1.getTime();
        const date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        const difference_ms = Math.abs(date2_ms - date1_ms);

        // Convert back to days and return
        return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
    }

    const formatDate = (date) => {
        const currentDate = new Date();
        const inputDate = new Date(date);

        const diffDays = dateDiffInDays(currentDate, inputDate);
        const diffMonths = Math.abs(currentDate.getMonth() - inputDate.getMonth()) +
            (12 * (currentDate.getFullYear() - inputDate.getFullYear()));

        if (diffDays <= 7) {
            return '1 Week';
        } else if (diffDays <= 30) {
            return '1 Month';
        } else if (diffMonths <= 3) {
            return '3 Months';
        } else {
            return 'Year';
        }
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedNetIncome, setSelectedNetIncome] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [sliderValues, setSliderValues] = useState([0, 100]);
    const [sliderUsed, setSliderUsed] = useState(false);

    const uniqueStates = Array.from(new Set(filerData.map(item => item.State))).sort();
    const uniqueYears = Array.from(new Set(filerData.map(item => item.Year)));
    uniqueYears.sort((a, b) => a - b);
    const uniqueDate = [...new Set(filerData.map(filer => formatDate(filer.filed_Date)))];
    const sortOrder = ['1 Week', '1 Month', '3 Months', 'Year'];
    uniqueDate.sort((a, b) => {
        return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    });

    const handleStateFilterChange = (e) => {
        setSelectedState(e.target.value);
    };

    const handleYearFilterChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const handleNetIncomeFilterChange = (e) => {
        setSelectedNetIncome(e.target.value);
    };
    const handleDateFilterChange = (e) => {
        setSelectedDate(e.target.value);
    };
    const checkNetIncome = (netIncome, selectedOption) => {
        if (selectedOption === 'lessThanZero') {
            return parseFloat(netIncome) < 0;
        } else if (selectedOption === 'moreThanZero') {
            return parseFloat(netIncome) >= 0;
        }
        return true;
    };

    const minRevenue = Math.min(...filerData.map(filer => filer.Total_Revenues));
    const maxRevenue = Math.max(...filerData.map(filer => filer.Total_Revenues));


    const handleSliderChange = (values) => {
        setSliderValues(values);
        setSliderUsed(true);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    const filteredDataWithoutSlider = filerData.filter(filer => (
        (!selectedState || filer.State === selectedState) &&
        (!selectedYear || filer.Year === selectedYear) &&
        (!selectedDate || formatDate(filer.filed_Date) === selectedDate) &&
        (!selectedNetIncome || checkNetIncome(filer.Net_Income, selectedNetIncome)) &&
        (!searchTerm || filer.company && filer.company.toLowerCase().includes(searchTerm.toLowerCase()))
    ));

    const filteredDataWithSlider = sliderUsed
        ? filteredDataWithoutSlider.filter(filer => {
            const totalRevenues = parseFloat(filer.Total_Revenues);
            return totalRevenues >= sliderValues[0] && totalRevenues <= sliderValues[1];
        })
        : filteredDataWithoutSlider;

    const filteredData = filteredDataWithSlider;
    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, totalItems);
    const filteredPosts = filteredData.slice(startIndex, endIndex);

    // const groupedByWeek = uniqueDate.reduce((acc, date) => {
    //     const weekStart = new Date(date);
    //     weekStart.setDate(weekStart.getDate() - weekStart.getDay());
    //     if (!isNaN(weekStart.getTime())) {
    //         const weekKey = weekStart.toISOString().split('T')[0];
    //         if (!acc[weekKey]) {
    //             acc[weekKey] = [];
    //         }
    //         acc[weekKey].push(date);
    //     }
    //     return acc;
    // }, {});

    // const options = Object.entries(groupedByWeek).map(([weekKey, dates]) => {
    //     const now = new Date();
    //     const then = new Date(weekKey);
    //     if (!isNaN(then.getTime())) {
    //         const diffInMilliseconds = now - then;
    //         const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    //         const diffInWeeks = Math.floor(diffInDays / 7);
    //         const diffInMonths = Math.floor(diffInDays / 30);

    //         let relativeTime;
    //         if (diffInMonths > 0) {
    //             relativeTime = new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-diffInMonths, 'month');
    //         } else if (diffInWeeks > 0) {
    //             relativeTime = new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-diffInWeeks, 'week');
    //         } else if (diffInDays > 0) {
    //             relativeTime = new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(-diffInDays, 'day');
    //         } else {
    //             relativeTime = 'Today';
    //         }

    //         return (
    //             <option key={weekKey} value={dates[0]}>
    //                 {relativeTime}
    //             </option>
    //         );
    //     }
    //     return null;
    // }).filter(option => option !== null);

    const resetFilters = () => {
        setSearchTerm('');
        setSelectedState('');
        setSelectedYear('');
        setSelectedNetIncome('');
        setSelectedDate('');
        setSliderValues([0, 100]);
        setSliderUsed(false);
    };

    return (
        <MainPageLayout heading="Reg A Offerings" text="REG A+ OFFERINGS ENABLE BOTH ACCREDITED AND NON-ACCREDITED INVESTORS TO INVEST IN SMALL- TO MEDIUM-SIZED BUSINESSES THROUGHOUT THE UNITED STATES AND ACTS LIKE A MINI-IPO FOR EACH BUSINESS."
            style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
                height: '368px',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }}
        >
            <div className=''>
                <div className='flex justify-between mx-5 my-4 '>
                    <div className='flex justify-start w-72'>
                        <Search placeholder="Search"
                            value={searchTerm}
                            popovertxt="Search by Issuer Name"
                            onChange={e => setSearchTerm(e.target.value)} />
                    </div>
                    <div className="relative" ref={filterRef}>
                        <div className="inline-flex items-center overflow-hidden  bg-white xl:mx-4 xs:mx-2 flex-" >
                            <button
                                onClick={showFilterMsg}
                                className="border-[#625D42] border-[1px] rounded-[7px] xl:px-3 xl:py-1 xs:p-2 xl:text-xl xs:text-base  flex text-[#625D42]"
                            >
                                Filter
                                <img src={dropdown} alt='' className=' xl:w-6 xl:h-5 xs:w-3 xs:h-5 xs:mx-2 xl:mx-0 xl:pt-3 xs:pt-2  ' />

                            </button>
                        </div>

                        {filterVisible && (
                            <div
                                className="absolute end-0 z-10 mt-2 xl:w-auto xs:w-72 lg:w-auto md:w-auto  divide-y divide-gray-100 rounded-xl border border-gray-100 bg-white "
                                role="menu"
                                style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
                            >
                                <div className='text-start p-9'><div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Date Filed</div>
                                    <div className='my-1'>
                                        <select
                                            className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                                            onChange={handleDateFilterChange}
                                            value={selectedDate}
                                        >
                                            <option value="">All</option>
                                            {uniqueDate.map((date, index) => (
                                                <option key={index} value={date}>{date}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>State</div>
                                    <div className='my-1'>
                                        <select
                                            className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                                            onChange={handleStateFilterChange}
                                            value={selectedState}
                                        >
                                            <option value="">All</option>
                                            {uniqueStates.filter(location => usaStates.includes(location.toUpperCase())).map((state, index) => (
                                                <option key={index} value={state}>{state}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Years Incorporated</div>
                                    <div className='my-1'>
                                        <select
                                            className='border-[#625D42] border-[1px] rounded-[7px] xl:p-2 xs:p-1 mx-1'
                                            onChange={handleYearFilterChange}
                                            value={selectedYear}
                                        >
                                            <option value="">All </option>
                                            {uniqueYears.map((year, index) => (
                                                <option key={index} value={year}>{year}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Revenues</div>
                                    <div className='my-2 mx-4 pt-5'>
                                        <TrackInvertedSlider minRevenues={minRevenue} maxRevenues={maxRevenue} onChange={handleSliderChange} />

                                    </div>
                                    <div className='xl:text-xl xs:text-base font-medium text-[#343A40] my-4'>Net Income</div>
                                    <div className='my-1 flex mb-8'>
                                        <div className="flex items-center mx-2">
                                            <input
                                                id="default-radio-1"
                                                type="radio"
                                                value="lessThanZero"
                                                name="netIncome"
                                                className="w-4 h-4 text-custom-color bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                onChange={handleNetIncomeFilterChange}
                                                checked={selectedNetIncome === 'lessThanZero'}
                                            />
                                            <label
                                                htmlFor="default-radio-1"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                Less than $0
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                id="default-radio-2"
                                                type="radio"
                                                value="moreThanZero"
                                                name="netIncome"
                                                className="w-4 h-4 text-custom-color bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                onChange={handleNetIncomeFilterChange}
                                                checked={selectedNetIncome === 'moreThanZero'}
                                            />
                                            <label
                                                htmlFor="default-radio-2"
                                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                            >
                                                More than $0
                                            </label>
                                        </div>
                                    </div>
                                    <div className='flex justify-center'>
                                        <button type="submit" className="bg-yellow px-2 xl:w-[100px] xs:w-[70px] flex justify-center py-2.5 text-white xl:text-lg xs:text-sm font-medium hover:text-black"
                                            style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }} onClick={resetFilters}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {loading &&
                    <div className="mx-10 pt-32 items-center h-[50vh]">
                        <p className='text-lg my-2 font-medium'>Loading ....</p>
                        <div className='flex justify-center items-center'>
                            <Loader />
                        </div>
                    </div>}
                {error &&
                    <div className='text-center mx-10 mt-16 h-[50vh]'>
                        <div className='flex justify-center'>
                            <p className='text-yellow text-3xl font-medium'>Error:</p>
                            <p className='text-black mx-2 text-3xl font-medium'>{error}</p>
                        </div>
                        <p className='text-black mx-2 text-4xl font-medium my-2'>Please try again later</p>
                    </div>
                }
                {!loading && !error && (
                    <div className='mx-10 my-3 h-auto'>
                        {filteredPosts.length === 0 ? (
                            <div className="mx-10 items-center h-[70vh]">
                                <p className='text-xl my-2 font-medium '>No matching results found.</p>
                            </div>
                        ) : (
                            <div className="overflow-x-auto rounded-lg border-none">
                                <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                                    <thead className="ltr:text-left rtl:text-right ">
                                        <tr className=''>
                                            <th className="  xl:w-10  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold py-2  text-[#060606]"></th>
                                            <th className="  px-2 xl:w-10  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold py-2  text-[#060606]">Name of Issuer</th>
                                            <th className="  px-2 xl:w-10 py-2  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold text-[#060606]">State</th>
                                            <th className="  px-2 xl:w-10 py-2  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold  text-[#060606]">Year Incorporated</th>
                                            <th className="  px-2 xl:w-10 py-2  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold w-2 h-auto text-[#060606]">Number of Full Time Employees</th>
                                            <th className="  px-2 xl:w-10 py-2  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold text-[#060606]">Total Stockholders’ Equity </th>
                                            <th className="  px-2 xl:w-10 py-2  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold text-[#060606]">Total Revenues</th>
                                            <th className="  px-2 xl:w-10 py-2  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold text-[#060606]">Net Income</th>
                                            <th className="px-2 xl:w-10 py-2  xs:text-sm lg:text-lg md:text-[15px] xl:text-lg font-semibold text-[#060606]">Link of Website</th>

                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">

                                        {filteredPosts
                                            .filter((post) => usaStates.includes(post.State.toUpperCase()))
                                            .map((filer, index) => (
                                                <tr key={index}>
                                                    <td className="w-9 py-2 xs:px-2 text-base text-[#060606]">
                                                        <div className='mx-1 bg-[#625D42] h-2 w-2'></div>
                                                    </td>
                                                    <td className="w-44 py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]">{filer.company}</td>
                                                    <td className="w-44 py-3 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]">{filer.State}</td>
                                                    <td className="w-44 py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">{filer.Year}</td>
                                                    <td className="w-44 py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">{filer.Full_Time_Employees}</td>
                                                    <td className="w-44 py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                                                        {Number(filer.Stockholder_Equity).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                    </td>
                                                    <td className="w-44 py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                                                        {Number(filer.Total_Revenues).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                    </td>
                                                    <td className="w-44 py-4 xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                                                        {Number(filer.Net_Income).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                    </td>
                                                    <td className="w-44 xs:px-2 py-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-gray-700">
                                                        <a
                                                            href={`https://www.google.com/search?q=${encodeURIComponent(`${filer.company} ${filer.fullAddress}`)}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer" className='underline'
                                                        >
                                                            View Link
                                                        </a>
                                                    </td>
                                                    <td className="whitespace-nowrap py-3 hidden  xs:px-2 xs:text-xs lg:text-lg md:text-[14px] xl:text-base text-[#060606]"> {formatDate(filer.filed_Date)}</td>
                                                </tr>
                                            ))}


                                    </tbody>
                                </table>
                            </div>)}
                    </div>
                )}
                <div className='xl:flex justify-between mt-22 mx-2  my-2 '>
                    <div className='flex justify-center xl:mx-5 my-1'>
                        <PaginationButtons count={totalPages} page={currentPage} onChange={handlePageChange} />
                    </div>
                    <div className='flex justify-center gap-2 mx-5 my-1'>
                        <p className='mt-1'>Page</p>
                        <select className='border-[1px] border-[#DDDDDD] rounded-[10px] px-1 cursor-pointer'
                            onChange={(e) => setCurrentPage(Number(e.target.value))} value={currentPage}>
                            {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                                <option key={page} value={page}>{page}</option>
                            ))}
                        </select>
                        <p className='mt-1'>of {totalPages}</p>
                    </div>
                </div>
            </div>
        </MainPageLayout>

    )
}
