import React, { useEffect, useState } from 'react';
import del from '../../Assets/Icons/delete.svg';
import edit from '../../Assets/Icons/edit.svg';
import view from '../../Assets/Images/view.png';
import { Link } from 'react-router-dom';
import Loader from '../../Components/LinearProgress';
import { db } from '../../firebase';
import { collection, getDocs, deleteDoc, doc, query, where, updateDoc } from 'firebase/firestore';
import PaginationButtons from '../../Components/PaginationButtons';
import AuthLayout from '../Layouts/AuthLayout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Dashboard() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;

    useEffect(() => {
        const fetchPosts = async () => {
            const postsCollection = collection(db, 'admin');
            const snapshot = await getDocs(postsCollection);
            const postData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            const postsWithCommentCount = await Promise.all(postData.map(async post => {
                const commentQuery = query(collection(db, 'comments'), where('postId', '==', post.id));
                const commentSnapshot = await getDocs(commentQuery);
                return { ...post, commentCount: commentSnapshot.size };
            }));
            const sortedPosts = postsWithCommentCount.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());

            setPosts(sortedPosts);
            setLoading(false);

        };
        fetchPosts();
    }, []);

    const deletePost = async (postId) => {
        try {
            await deleteDoc(doc(db, 'admin', postId));
            toast.success("Post deleted successfully!");
            window.location.reload();
        } catch (error) {
            console.error('Error deleting post:', error);
            toast.error("Error deleting", error);
        }
    };

    const handleStatusChange = async (postId, newStatus) => {
        try {
            const postRef = doc(db, 'admin', postId);
            await updateDoc(postRef, { status: newStatus });
            setPosts(prevPosts => prevPosts.map(post => post.id === postId ? { ...post, status: newStatus } : post));
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const filteredPosts = posts.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = filteredPosts.slice(startIndex, endIndex);

    const handleChangePage = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div>
            <AuthLayout>
                <ToastContainer />
                <div className='px-6 h-[90vh]'>
                    <div className='xl:flex lg:flex md:flex  mx-4 justify-between my-2 mt-5'>
                        <div className='text-start mx-2 xs:ml-1 xl:ml-7'>
                            <p className='text-3xl font-semibold'> Posts</p>
                            <p className='text-lg font-normal'>You have Total {posts.length} posts</p>
                        </div>

                        <div className='flex gap-1 xs:justify-center mt-2'>
                            <div className="relative xl:mx-2">
                                <input
                                    type="text"
                                    placeholder="Search Post"
                                    className="border-[#DCDCDC] border-[1px] w-40 md:w-auto bg-[#F8F8F8] rounded-[11px] py-2  px-10 pr-10 placeholder-gray-300 text-gray-800 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 transition duration-300"
                                    value={searchTerm}
                                    onChange={e => setSearchTerm(e.target.value)}
                                />
                                <div className="absolute inset-y-0 right-0 xl:pr-[14rem] lg:pr-[14rem] md:pr-[14rem] xs:pr-[8rem] xl:pb-4 lg:pb-4 md:pb-4 flex items-center pointer-events-none">
                                    <svg
                                        className="h-5 w-5 text-[#616161] "
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <div className=" mx-1 xl:flex lg:flex md:flex  ">
                                    <Link to='/post' className="bg-yellow xl:px-4 lg:px-4 md:px-4 xs:px-2 flex justify-start py-2.5 text-white text-sm font-medium hover:text-black" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                                        New Post
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {loading ? (
                        <div className="mx-10 items-center h-[80vh]">
                            <p className='text-lg my-2 font-medium '>Loading ....</p>
                            <div className='flex justify-center items-center'>
                                <Loader />
                            </div>
                        </div>
                    ) : filteredPosts.length === 0 ? (
                        <div className="mx-10 items-center h-[70vh]">
                            <p className='text-xl my-2 font-medium '>Nothing found</p>
                        </div>
                    ) : (
                        <div className='xl:mx-10 my-3 mt-5'>
                            <div className="overflow-x-auto rounded-lg border-none mx-4">
                                <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm ">
                                    <thead className="ltr:text-left rtl:text-right ">
                                        <tr className='text-start'>
                                            <th className=" text-start text-lg font-semibold py-2  text-[#060606]"></th>
                                            <th className="px-2 text-start text-lg font-semibold py-2  text-[#060606]">Title</th>
                                            <th className="px-2 text-start py-2 text-lg font-semibold text-[#060606]">Author</th>
                                            <th className="px-2 text-start py-2 text-lg font-semibold  text-[#060606]">Comments</th>
                                            <th className="px-2 text-start py-2 text-lg font-semibold w-2 h-auto text-[#060606]">Status</th>
                                            <th className="px-2 text-start py-2 text-lg font-semibold text-[#060606]"> </th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-start'>
                                        {paginatedData.map(post => (
                                            <tr key={post.id} className='gap-1'>
                                                <td className="whitespace-nowrap py-2 px-2 text-base text-[#060606]"><div className='mx-1 bg-[#625D42] h-2 w-2'></div></td>
                                                <td className="py-2 font-medium xl:w-52 px-2 text-base text-[#060606]">

                                                    {post.title}
                                                </td>
                                                <td className="whitespace-nowrap py-2 px-2 text-base text-gray-700">{post.Author}</td>
                                                <td className="whitespace-nowrap py-2 px-2 text-base text-gray-700">
                                                    <a href={`/comments/${post.id}`} >{post.commentCount} Comments </a></td>

                                                <td className="whitespace-nowrap py-2 px-2 text-base text-gray-700">
                                                    <select className=' cursor-pointer border-[1px] rounded-lg p-2' name="status" value={post.status} onChange={e => handleStatusChange(post.id, e.target.value)}>
                                                        <option value="">Draft</option>
                                                        <option value="UnPublished">Unpublish</option>
                                                        <option value="Published">Publish</option>
                                                    </select>
                                                </td>
                                                <td className="whitespace-nowrap py-2 px-2 text-base text-gray-700">
                                                    <div className='flex gap-2 justify-end'>
                                                        <div>
                                                            <Link to={`/detail/${post.id}`}>
                                                                <img src={view} className='h-6' alt='preview' />
                                                            </Link>
                                                        </div>
                                                        <button onClick={() => deletePost(post.id)}><img src={del} className='h-6' alt='delete' /></button>
                                                        <button>
                                                            <Link to={`/edit/${post.id}`}>
                                                                <img src={edit} className='h-6' alt='edit' />
                                                            </Link>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}

                </div>
                <div className='xl:flex justify-between mx-2  '>
                    <div className=' flex justify-center xl:mx-5 my-1'>
                        <PaginationButtons
                            count={Math.ceil(filteredPosts.length / rowsPerPage)}
                            page={currentPage}
                            onChange={handleChangePage}
                        />
                    </div>
                    <div className='flex  justify-center gap-2 mx-5 my-1'>
                        <p className='mt-1.5'>Page</p>
                        <select
                            className='border-[1px] border-[#DDDDDD] rounded-[10px] px-4 py-1.5 cursor-pointer'
                            value={currentPage}
                            onChange={(e) => setCurrentPage(Number(e.target.value))}
                        >
                            {Array.from({ length: Math.ceil(filteredPosts.length / rowsPerPage) }, (_, index) => index + 1).map((page) => (
                                <option key={page} value={page}>
                                    {page}
                                </option>
                            ))}
                        </select>
                        <p className='mt-1.5'>of {Math.ceil(filteredPosts.length / rowsPerPage)}</p>
                    </div>
                </div>
            </AuthLayout>
        </div>
    )
}
