import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

export default function Terms() {
    return (
        <div>
            <Navbar />
            <div>
                <div className="bg-white flex flex-col items-stretch my-5 mb-3 ">

                    <div className="text-[#625D42] xl:text-[60px] xs:text-3xl my-2 mb-5 font-semibold tracking-wide uppercase self-center mx-2  max-md:max-w-full max-md:text-4xl mt-5">
                        Website Terms and Conditions

                    </div>
                    <div className="text-[#616161] my-4 xl:text-lg xs:text-base leading-5 self-start text-start mx:10 2xl:mx-auto 2xl:w-[85rem] xl:mx-auto xl:w-[75rem] lg:mx-10 mt-7 md:mx-10 xs:mx-5 max-md:max-w-full ">
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl  xs:text-xl leading-7 text-[#343A40] my-3">
                                1. Acceptance of Terms
                            </div>
                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>1.1.</b> By accessing and using this website (the "Site"), you hereby agree to comply with and be legally bound by these Terms and Conditions. The Site is owned and operated by TheCrowdfundInvestor (“we,” “us,” or “our”). If you do not agree with any provision of these terms, or if you are unwilling to be bound by them, please refrain from accessing or using the Site. Your continued use of the Site constitutes your ongoing acceptance of these Terms and any modifications that may be made to them in the future.

                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>1.2.</b> These Terms constitute a legally binding agreement between you and us. They govern your use of the Site, including any services, information, content, features, and functionality provided through the Site. If you do not fully understand or agree with any part of these Terms, it is your responsibility to seek legal counsel before using the Site.

                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>1.3.</b> In the event that you are using the Site on behalf of an organization or entity, you represent and warrant that you have the authority to bind such organization or entity to these Terms. If you do not have such authority or if you do not agree with these Terms, you must not use the Site on behalf of the organization or entity
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                2. No Investment Advice
                            </div>
                            <ol className=' mx-5' >
                                <li className='my-1'>
                                    <b className='font-medium'>2.1.</b> The information, content, materials, and resources provided on this
                                    Site are intended for general informational purposes only and are
                                    not intended to constitute, and should not be construed as,
                                    investment advice, financial advice, legal advice, tax advice, or
                                    any other form of professional advice. The content presented on the
                                    Site is not tailored to your individual circumstances or needs.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>2.2.</b> You acknowledge and agree that any decisions you make based on the
                                    information provided on the Site, including but not limited to
                                    investment decisions, are solely at your own risk. We do not
                                    endorse, recommend, or provide opinions on specific investments,
                                    securities, strategies, or any financial products. The information
                                    on the Site may not be accurate, complete, or up-to-date.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>2.3.</b> Before making any investment decisions or taking any financial
                                    actions, it is crucial that you seek the advice of qualified
                                    professionals who can consider your specific situation, goals, risk
                                    tolerance, and other relevant factors. You should consult with
                                    financial advisors, legal counsel, tax professionals, or other
                                    experts who can provide advice tailored to your individual
                                    circumstances.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>2.4.</b> You agree that we and our affiliates shall not be held liable for
                                    any actions, losses, damages, or expenses that may arise as a result
                                    of your reliance on the information provided on the Site. You hereby
                                    release us from any and all claims, liabilities, and demands related
                                    to any decisions or actions you take based on the content of the
                                    Site.
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                3. Acceptable Use
                            </div>
                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>3.1.</b> You hereby agree to use the Site in a manner consistent with all
                                    applicable laws and regulations and in accordance with these Terms
                                    and Conditions. You further agree not to engage in any activities
                                    that are unlawful, prohibited, or that could disrupt or impair the
                                    functionality, security, or availability of the Site.
                                </li>
                                <li className='my-1'> <b className='font-medium'>3.2.</b>In connection with your use of the Site, you shall not:</li>

                                <div className='list-none mx-2'>
                                    <p className='my-1'>
                                        a. Engage in any unauthorized access to the Site's systems, databases,
                                        or servers.
                                    </p>
                                    <p className='my-1'>
                                        b. Distribute, upload, post, transmit, or otherwise make available any
                                        content that is unlawful, harmful, threatening, abusive, harassing,
                                        defamatory, vulgar, obscene, libelous, invasive of another's
                                        privacy, hateful, or racially, ethnically, or otherwise
                                        objectionable.
                                    </p>
                                    <p className='my-1'>
                                        c. Infringe upon the intellectual property rights belonging to us, our
                                        affiliates, or any third party.
                                    </p>
                                    <p className='my-1'>
                                        d. Upload or transmit any malicious code, viruses, malware, worms, or
                                        any other harmful software that could disrupt or damage the Site or
                                        the systems of other users.
                                    </p>
                                    <p className='my-1'>
                                        e. Attempt to gain unauthorized access to other users' accounts or any
                                        portion of the Site.
                                    </p>
                                    <p className='my-1'>
                                        f. Engage in any activity that could compromise the security or
                                        integrity of the Site, its users, or its data.
                                    </p>
                                    <p className='my-1'>
                                        g. Use the Site for any purpose that is not expressly permitted by
                                        these Terms or that could violate applicable laws or regulations.
                                    </p>
                                </div>
                                <li className='my-1'>
                                    <b className='font-medium'>3.3.</b> You understand and agree that we reserve the right, at our sole
                                    discretion, to monitor your use of the Site and to take appropriate
                                    legal action against anyone who violates these acceptable use
                                    provisions, including reporting any suspected unlawful activities to
                                    law enforcement authorities.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>3.4.</b> By using the Site, you acknowledge that we reserve the right to
                                    remove or block any content that violates these Terms, and that you
                                    are solely responsible for any content you upload, distribute, or
                                    make available on the Site.
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                4. Limitation of Liability
                            </div>

                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>4.1.</b> In no event shall we, our officers, directors, employees,
                                    affiliates, agents, or licensors be liable for any direct, indirect,
                                    incidental, special, consequential, or punitive damages, including,
                                    but not limited to, damages for loss of profits, data, use,
                                    goodwill, or other intangible losses, arising out of or in
                                    connection with your access to or use of the Site or the inability
                                    to access or use the Site, regardless of whether such damages are
                                    based on contract, tort, negligence, strict liability, or any other
                                    legal theory, and even if we have been advised of the possibility of
                                    such damages.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>4.2.</b> You expressly acknowledge and agree that your use of the Site is at
                                    your sole risk. The Site is provided on an &quot;as is&quot; and
                                    &quot;as available&quot; basis. We make no warranty or
                                    representation that the Site will meet your requirements, be
                                    uninterrupted, timely, secure, or error-free, or that any errors or
                                    defects will be corrected. We disclaim all warranties of any kind,
                                    whether express or implied, including, but not limited to, the
                                    implied warranties of merchantability, fitness for a particular
                                    purpose, and non-infringement.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>4.3.</b> To the maximum extent permitted by applicable law, we shall not be
                                    liable for any damages or losses arising from any aspect of your
                                    interaction with other users of the Site, whether online or offline.
                                    You acknowledge and agree that we do not assume any responsibility
                                    or liability for the conduct, actions, or omissions of any user of
                                    the Site.
                                </li>
                            </ol>
                            <ol className='my-3  mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>4.4.</b>   Some jurisdictions do not allow the exclusion or limitation of
                                    liability for consequential or incidental damages, so the above
                                    limitations may not apply to you. If you are dissatisfied with any
                                    portion of the Site or with these Terms, your sole and exclusive
                                    remedy is to discontinue use of the Site.
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                5. Indemnification
                            </div>
                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>5.1 </b> You hereby agree to indemnify, defend, and hold us harmless together
                                    with our officers, directors, employees, affiliates, agents, and
                                    licensors from and against any and all claims, liabilities, damages,
                                    losses, expenses, and costs (including but not limited to attorney's
                                    fees) arising out of or in connection with:
                                </li>

                                <div className='mx-2'>
                                    <p className='my-1'>
                                        a. Your use of the Site, including any content you submit, post, or
                                        transmit through the Site.
                                    </p>
                                    <p className='my-1'>
                                        b. Your violation of these Terms and Conditions, including any breach
                                        of the representations and warranties provided herein.
                                    </p>
                                    <p className='my-1'>
                                        c. Your violation of any third-party rights, including but not limited
                                        to intellectual property rights, privacy rights, and proprietary
                                        rights.
                                    </p>
                                    <p className='my-1'>
                                        d. Any actions you take that may harm, disrupt, or impair the Site, its
                                        functionality, or the experience of other users.
                                    </p>
                                </div>
                                <li className='my-1'>
                                    <b className='font-medium'>5.2.</b>  You agree to cooperate fully with us in the defense of any claim,
                                    and we reserve the right to assume the exclusive defense and control
                                    of any matter otherwise subject to indemnification by you. You shall
                                    not settle any matter without prior written consent from us.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>5.3.</b> You understand and agree that we reserve the right to seek remedies
                                    and damages to the fullest extent permitted by law for any violation
                                    of these Terms or any unauthorized use of the Site.
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                6. Intellectual Property
                            </div>

                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>6.1.</b>  All content available on or through the Site, including but not
                                    limited to text, graphics, logos, images, software, audio clips,
                                    video clips, data compilations, and other materials (collectively,
                                    &quot;Content&quot;), belongs to us, our affiliates, our licensors,
                                    or our content suppliers, and is protected by intellectual property
                                    laws, including copyright and trademark laws. You acknowledge and
                                    agree that we retain all rights, title, and interest in and to the
                                    Content.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>6.2.</b>  You may access, view, and use the Content solely for your personal,
                                    non-commercial use in accordance with these Terms and Conditions.
                                    You may not, without explicit written permission from us or unless
                                    otherwise permitted in this terms and conditions:
                                </li>
                                <div className='mx-2'>
                                    <p className='my-1'>
                                        a. Reproduce, duplicate, copy, modify, distribute, display, perform, or
                                        create derivative works based on the Content.
                                    </p>
                                    <p className='my-1'>
                                        b. Use the Content for any commercial purpose or for any public display
                                        (commercial or non-commercial).
                                    </p>
                                    <p className='my-1'>
                                        c. Reverse engineer, decompile, or disassemble any software contained
                                        in the Content.
                                    </p>
                                    <p className='my-1'>
                                        d. Remove any copyright or other proprietary notations from the
                                        Content.
                                    </p>
                                </div>
                                <li className='my-1'>
                                    <b className='font-medium'>6.3.</b> In addition to any rights granted pursuant to this clause 6 or any
                                    other clause in this terms and conditions, you are permitted to
                                    include limited excerpts of the Content in reports and presentations
                                    that are exclusively intended for your internal information purposes
                                    (&quot;Limited Excerpts&quot;). Furthermore, you are authorized to
                                    utilize Limited Excerpts of the Content in presentations or
                                    documents to be disclosed to third parties. Each instance of using
                                    Limited Excerpts shall be subject to the following conditions:
                                </li>
                                <div className='mx-2'>
                                    <p className='my-1'>
                                        a. You must provide proper attribution to us as the source of the
                                        Content. This attribution shall be given in the following format:
                                        “Source: TheCrowdFundInvestor.com.”
                                    </p>
                                    <p className='my-1'>
                                        b. Limited Excerpts must be of both limited qualitative and
                                        quantitative nature. Their usage shall be restricted to a reasonable
                                        extent that aligns with their intended purpose.
                                    </p>
                                    <p className='my-1'>
                                        c. There shall be no routine disclosure or widespread distribution of
                                        these excerpts.
                                    </p>
                                </div>

                                <li className='my-1'>
                                    <b className='font-medium'>6.4.</b> It is essential to recognize that if the volume of Content or the
                                    frequency of disclosing or distributing Content to third parties or
                                    within your internal non-user base (including non-licensed teams
                                    within your organization) surpasses a reasonable threshold, such
                                    usage could be construed as a practical alternative for these
                                    parties or internal non-users to subscribing to our Content. In such
                                    cases, such usage would exceed the boundaries of what constitutes a
                                    &quot;Limited Excerpt&quot; and would be deemed a breach of the
                                    Agreement.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>6.5. </b> Should there arise any uncertainty or ambiguity regarding the
                                    classification of a given usage as a Limited Excerpt, you are
                                    required to solicit our guidance and obtain our approval before
                                    proceeding with the distribution of the Content to third parties or
                                    internal non-users through Limited Excerpts. We commit to responding
                                    promptly to such requests; however, the final decision on whether to
                                    permit the distribution of such Limited Excerpts shall rest within
                                    our absolute discretion.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>6.6.</b> If you wish to use the Content in any manner not expressly permitted
                                    by these Terms, you must obtain prior written consent from us or the
                                    respective rights holder. Any unauthorized use of the Content may
                                    violate copyright, trademark, and other applicable laws.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>6.7.</b> You acknowledge that we actively monitor our intellectual property
                                    rights and will take legal action against any unauthorized use or
                                    infringement of our intellectual property to the fullest extent
                                    permitted by law.
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>

                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                7. Links to Third-Party Sites
                            </div>

                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>7.1.</b>  The Site may include hyperlinks to websites operated by third
                                    parties that are not owned or controlled by us. These links are
                                    provided for your convenience and reference only. We do not endorse,
                                    recommend, or have any control over the content, products, services,
                                    accuracy, legality, or privacy practices of such third-party
                                    websites. Your decision to access and use any linked website is
                                    solely at your own risk.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>7.2.</b>  You acknowledge and agree that we shall not be responsible or
                                    liable, directly or indirectly, for any damage or loss caused or
                                    alleged to be caused by or in connection with your use of or
                                    reliance on any content, products, services, or information
                                    available on or through any such third-party websites.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>7.3. </b> It is your responsibility to read the terms and conditions and
                                    privacy policies of any third-party websites that you visit through
                                    links from the Site. We encourage you to exercise caution and be
                                    aware when you leave the Site and to understand that these Terms do
                                    not apply to any third-party websites.
                                </li>
                            </ol>
                        </div>

                        <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40]">
                            8. Termination
                        </div>

                        <ol className=' mx-5'>
                            <li className='my-1'>
                                <b className='font-medium'>8.1.</b>  We reserve the right, at our sole discretion, to terminate, suspend,
                                or restrict your access to the Site, in whole or in part, without
                                notice, for any reason or no reason, including but not limited to a
                                violation of these Terms and Conditions, suspected fraudulent or
                                illegal activities, or any conduct that we believe is harmful to the
                                Site, its users, our rights or rights of others.
                            </li>
                            <li className='my-1'>
                                <b className='font-medium'>8.2.</b>  You acknowledge and agree that we shall not be liable to you or any
                                third party for any termination, suspension, or restriction of your
                                access to the Site. In the event of termination, your right to use
                                the Site shall immediately cease, and we may remove or delete any
                                content you have uploaded or submitted to the Site.
                            </li>
                        </ol>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                9. Data Protection
                            </div>

                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'>9.1.</b> We are committed to protecting your data and privacy in accordance
                                    with all relevant and applicable data protection laws, including but
                                    not limited to the California Consumer Privacy Act (CCPA), the
                                    California Privacy Rights Act (CPRA), and the General Data
                                    Protection Regulation (GDPR).
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>9.2.</b> Our practices for collecting, processing, and safeguarding your
                                    personal information are designed to comply with these laws, which
                                    are aimed at enhancing your privacy rights and providing you with
                                    transparency and control over your data.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>9.3.</b> By using our Site, you acknowledge that your personal information
                                    will be handled in accordance with the principles and requirements
                                    outlined in the CCPA, CPRA, and GDPR. We prioritize your privacy and
                                    endeavor to implement necessary measures to ensure the security and
                                    confidentiality of your data.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>9.4.</b> Please review our complete Privacy Policy to understand how your
                                    data is collected, used, and protected, and to learn about your
                                    rights in relation to your personal information.
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                10. Changes to Terms
                            </div>
                            <ol className=' mx-5'>
                                <li className='my-1'>
                                    <b className='font-medium'> 10.1.</b> We reserve the right to modify, revise, or update these Terms and
                                    Conditions at any time without prior notice. Any changes will be
                                    effective immediately upon posting to the Site. It is your
                                    responsibility to review these Terms periodically for any changes or
                                    updates.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'>10.2.</b>  Your continued use of the Site following the posting of revised
                                    Terms constitutes your acceptance and agreement to be bound by the
                                    updated Terms. If you do not agree with any modifications to these
                                    Terms, your sole remedy is to discontinue using the Site.
                                </li>
                                <li className='my-1'>
                                    <b className='font-medium'> 10.3.</b>  We encourage you to review these Terms regularly to ensure that you
                                    are aware of any changes. The date of the most recent update to
                                    these Terms will be indicated at the beginning of the document for
                                    your reference.
                                </li>
                            </ol>
                        </div>
                        <div className='my-3'>
                            <div className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                11. Governing Law
                            </div>
                            These Terms shall be governed by and construed in accordance with the laws of California. Any legal action arising out of or relating to these Terms shall be filed in the courts located in California, and you consent to the exclusive jurisdiction of such courts.
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
