import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import Loader from '../../Components/LinearProgress';
import PaginationButtons from '../../Components/PaginationButtons';
import AuthLayout from '../Layouts/AuthLayout';

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function PostsComments() {
    const [loading, setLoading] = useState(true);
    const [postsComments, setPostsComments] = useState([]);
    const [comments, setComments] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const commentsPerPage = 10;
    useEffect(() => {
        const fetchData = async () => {
            try {
                const adminSnapshot = await getDocs(collection(db, 'admin'));
                const adminData = adminSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

                const commentsSnapshot = await getDocs(collection(db, 'comments'));
                const commentsData = commentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const combinedData = adminData.map(admin => {
                    const relevantComments = commentsData.filter(comment => comment.postId === admin.id);
                    return { ...admin, comments: relevantComments };
                });

                setPostsComments(combinedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const handleDeleteComment = async (comment, post) => {
        try {
            const commentDoc = doc(db, "comments", comment.id);
            await deleteDoc(commentDoc);
            setPostsComments((prevState) =>
                prevState.map((p) =>
                    p.id === post.id
                        ? {
                            ...p,
                            comments: p.comments.filter((c) => c.id !== comment.id),
                        }
                        : p
                )

            );
        } catch (error) {
            console.error("Error deleting comment:", error);
        }
    };
    const approveComment = async (comment) => {
        try {
            const commentRef = doc(db, "comments", comment.id);
            await updateDoc(commentRef, { approved: true });

            setComments((prevComments) =>
                prevComments.map((c) =>
                    c.id === comment.id ? { ...c, approved: true } : c
                )
            );
            // window.location.reload();
        } catch (error) {
            console.error("Error approving comment:", error);
        }
    };
    const disapproveComment = async (comment) => {
        try {
            const commentRef = doc(db, "comments", comment.id);
            await updateDoc(commentRef, { approved: false });

            setComments((prevComments) =>
                prevComments.map((c) =>
                    c.id === comment.id ? { ...c, approved: false } : c
                )
            );
            // window.location.reload();
        } catch (error) {
            console.error("Error disapproving comment:", error);
        }
    };


    const startIndex = (currentPage - 1) * commentsPerPage;
    const endIndex = currentPage * commentsPerPage;
    const totalComments = postsComments.reduce((total, post) => total + post.comments.length, 0);
    const commentsToDisplay = postsComments.map(post => {
        const paginatedComments = post.comments.slice(startIndex, endIndex);
        return { ...post, comments: paginatedComments };
    });
    const totalPages = Math.ceil(totalComments / commentsPerPage);


    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };
    return (
        <div>
            <AuthLayout>
                <div className='my-3 h-auto'>
                    <div className='mx-10 '>
                        <p className='text-3xl text-start font-semibold  mt-5' style={{ marginLeft: "40px" }}>Comments</p>

                    </div>

                    {loading ? (
                        <div className="mx-10 items-center h-[80vh]">
                            <div className='mt-20'>

                                <p className='text-lg my-2 font-medium '>Loading ....</p>
                                <div className='flex justify-center items-center '>
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='mx-10 my-7 mt-5 h-auto mb-10'>
                            <div className="overflow-x-auto overflow-y-hidden rounded-lg border-none mx-6 h-auto">
                                <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm  my-4 h-auto">
                                    <thead className="ltr:text-left rtl:text-right ">
                                        <tr className='text-center'>
                                            <th className="text-lg font-semibold py-2  text-[#060606]"></th>
                                            <th className="px-5 whitespace-nowrap text-lg font-semibold py-2 text-center text-[#060606]">Name</th>
                                            <th className="px-5 whitespace-nowrap text-lg font-semibold py-2 text-center text-[#060606]">Response to</th>
                                            <th className="px-5 whitespace-nowrap py-2 text-lg font-semibold text-center text-[#060606]">Comments</th>
                                            <th className="px-5 whitespace-nowrap py-2 text-lg font-semibold text-center text-[#060606]">Submitted on</th>
                                            <th className="px-5 whitespace-nowrap py-2 text-lg font-semibold text-center text-[#060606]"></th>
                                            <th className="px-5 whitespace-nowrap py-2 text-lg font-semibold text-center text-[#060606]">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody className='divide-y divide-gray-200 text-center'>
                                        {commentsToDisplay.map((post, index) => (
                                            <React.Fragment key={index}>
                                                {post.comments.map((comment, commentIndex) => (
                                                    <tr key={`${index}-${commentIndex}`}>
                                                        <td className="xl:w-10 py-4 px-2 text-base text-[#060606]">
                                                            <div className='mx-1 bg-[#625D42] h-2 w-2'></div>
                                                        </td>
                                                        <td className='py-4 font-medium xl:w-44 px-3 text-base text-[#060606]'>
                                                            {comment.name}
                                                        </td>
                                                        <td className='py-4 font-medium xl:w-52 px-3 text-base text-[#060606]'>
                                                            {post.title}
                                                        </td>
                                                        <td className='py-4 font-base xl:w-52 px-3 text-base text-[#060606]'>
                                                            {comment.comment}
                                                        </td>
                                                        <td className='py-4 font-base xl:w-44 px-2 text-base text-[#060606]'>
                                                            {comment.timestamp.toDate().toLocaleString([], { dateStyle: 'medium' })}
                                                        </td>

                                                        <td className='whitespace-nowrap py-2 font-base xl:w-10 px-6 text-base text-[#060606] h-auto'>
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <div>
                                                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5  bg-white px-3 py-2 text-xl font-semibold text-gray-900  ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                                                        ...
                                                                    </Menu.Button>
                                                                </div>

                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 z-10  w-32 border-[1px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-1">
                                                                            {comment.approved ? (
                                                                                <Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <p
                                                                                            onClick={() => disapproveComment(comment)}
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-sm cursor-pointer'
                                                                                            )}
                                                                                        >
                                                                                            Disapprove
                                                                                        </p>
                                                                                    )}
                                                                                </Menu.Item>

                                                                            ) :
                                                                                (<Menu.Item>
                                                                                    {({ active }) => (
                                                                                        <p
                                                                                            onClick={() => approveComment(comment)}
                                                                                            className={classNames(
                                                                                                active ? 'bg-gray-100 text-gray-900 ' : 'text-gray-700',
                                                                                                'block px-4 py-2 text-sm  cursor-pointer'
                                                                                            )}
                                                                                        >
                                                                                            Approve
                                                                                        </p>
                                                                                    )}
                                                                                </Menu.Item>)}

                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <p
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'block px-4 py-2 text-sm cursor-pointer'
                                                                                        )}
                                                                                        onClick={() => handleDeleteComment(comment, post)}
                                                                                    >
                                                                                        Delete
                                                                                    </p>
                                                                                )}
                                                                            </Menu.Item>


                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </td>
                                                        <td className='whitespace-nowrappy-4 font-base xl:w-10 px-6 text-base text-[#060606]'>
                                                            {comment.approved ? (
                                                                <p>Approved</p>
                                                            ) : (
                                                                <p>Not Approved</p>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </tbody>

                                </table>
                            </div>
                        </div>)}
                </div>
                <div className='xl:flex justify-between mx-2 my-7 mt-28'>
                    <div className=' flex justify-center xl:mx-5 my-1'>
                        <PaginationButtons count={totalPages} page={currentPage} onChange={handlePageChange} />
                    </div>
                    <div className='flex  justify-center gap-2 mx-5 my-1'>
                        <p className='mt-1'>Page</p>
                        <select
                            className='border-[1px] border-[#DDDDDD] rounded-[7px] xl:px-4  py-1.5 cursor-pointer'
                            onChange={(e) => setCurrentPage(Number(e.target.value))}
                            value={currentPage}
                        >
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                                <option key={page} value={page}>
                                    {page}
                                </option>
                            ))}
                        </select>
                        <p className='mt-1'>of {totalPages}</p>
                    </div>

                </div>
            </AuthLayout>
        </div>
    );
}
