import React, { useState } from "react";
import { signInWithEmailAndPassword, sendPasswordResetEmail,getAuth } from "firebase/auth";
import { auth } from "../../../firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import bg from "../../../Assets/Images/bg.png"
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Signin() {
    const [password, setPassword] = useState("");
    const [email, setemail] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const login = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {

                const user = userCredential.user;
                navigate('/Dashboard')
            })
            .catch((error) => {
                const errorMessage = error.message;
                alert(errorMessage);
            });
    }
    return (
        <div>
            <ToastContainer/>
            <div className="flex overflow-hidden relative flex-col justify-center items-center px-16 py-12  max-md:px-5"
                style={{
                    backgroundImage: `url(${bg})`,
                    height: '100vh',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>

                <div className="flex relative flex-col items-stretch py-12  max-w-full shadow-sm bg-[#343A40] rounded-[38.009px] w-[700px] max-md:mt-10">
                    <div className="flex flex-col items-stretch px-12  max-md:px-5 max-md:max-w-full">
                        <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ff8074e0079c824504623212afdc96129387a77795b04861550fdaed430eae78?"
                            className="object-contain object-center max-w-full aspect-[4.17] w-[200px]" alt=""
                        />
                        <div className="mt-4 text-4xl font-bold text-start text-white max-md:max-w-full max-md:text-3xl">
                            Sign In
                        </div>

                    </div>
                    <div className="flex flex-col items-stretch px-12 mt-2 max-md:px-5 max-md:max-w-full">
                        
                        <input className="placeholder:text-white justify-center items-start py-3 pr-16 pl-5 mt-4 text-xl font-normal text-white whitespace-nowrap rounded-lg border-solid border-[1.118px] bg-[#343A40] border-[color:var(--Background,#FFF)] max-md:pr-5 max-md:max-w-full"
                            type="email" id="email" placeholder='Enter your Email'
                            onChange={(e) => setemail(e.target.value)} />
                        <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="placeholder:text-white justify-center items-start py-3 pr-16 pl-5 mt-4 text-xl font-normal text-white whitespace-nowrap rounded-lg border-solid border-[1.118px] bg-[#343A40] border-[color:var(--Background,#FFF)] max-md:pr-5 max-md:max-w-full"
                            placeholder="Password"
                        />

                        <button
                            className="absolute lg:text-base xs:text-xs  text-white lg:top-[260px] sm:top-[10px] md:top-[265px] xs:top-[260px] xl:left-[610px] lg:left-[610px] md:left-[530px] xs:left-[330px] z-10 bg-transparent border-none cursor-pointer"
                            type="button"
                            onClick={togglePasswordVisibility}
                        >
                            <FontAwesomeIcon
                                icon={showPassword ? faEye : faEyeSlash}
                            />
                        </button>

                        <div className="flex gap-5 justify-between items-stretch mt-10 max-md:flex-wrap max-md:max-w-full">
                           
                            <a  href="/reset" className="grow text-lg leading-6 text-right text-blue-200 max-md:max-w-full">
                                Forgot Password?
                            </a>
                        </div>
                        <button onClick={login} href='' className="justify-center items-center px-16 py-3 mt-4 text-2xl font-medium text-white whitespace-nowrap bg-yellow rounded-lg shadow-md max-md:px-5 max-md:max-w-full">
                            Sign In
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
