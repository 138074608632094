import React from 'react'

export default function Input({ id, type, placeholder, className, value, onChange }) {
  return (
    <div>
      <input
        type={type}
        id={id}
        placeholder={placeholder}
        className={className}
        value={value}
        onChange={onChange}
      />
    </div>
  )
}
