import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import Loader from '../Components/LinearProgress';
import bg from '../Assets/Images/bg.png';
import MainPageLayout from './Layouts/MainPageLayout';
import PaginationButtons from '../Components/PaginationButtons';

export default function Articlespage() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 5;

  useEffect(() => {
    const fetchPosts = async () => {
      const querySnapshot = await getDocs(collection(db, 'admin'));
      const postData = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate());
      setPosts(postData);
      setLoading(false);
    };
    fetchPosts();
  }, []);

  const totalPages = Math.ceil(posts.length / postsPerPage);


  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <MainPageLayout heading="Articles" text="WE POST INDUSTRY NEWS, INVESTOR GUIDES, COMPANY UPDATES AND MORE."
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
        height: '300px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>

      <div className='xl:mx-auto lg:mx-auto 2xl:w-[80rem] xl:w-[70rem] lg:w-[50rem] xs:mx-10 my-10 py-2 '>
        <div>
          {loading ? (
            <div className="mx-10 items-center h-[80vh]">
              <p className='text-lg my-2 font-medium '>Loading ....</p>
              <div className='flex justify-center items-center'>
                <Loader />
              </div>
            </div>

          ) : (
            <div className="my-5 items-center">
              {currentPosts.filter(post => post.status === 'Published')
                .map(post => (
                  <div className=" flex my-4 justify-between py-2  border-b-[2px]" key={post.id}>
                    <div>
                      <div className="flex items-stretch gap-5">
                        <img
                          loading="" alt='profile'
                          src={post.profileImageUrl}
                          className="aspect-square object-center w-[75px] overflow-hidden shrink-0 max-w-full rounded-full"
                        />
                        <div className="flex grow basis-[0%] flex-col items-start  mt-2 self-start">
                          <div className="text-neutral-700 xl:text-2xl xs:text-lg  font-medium leading-7 whitespace-nowrap">
                           {post.Author}
                          </div>
                          <div className="text-zinc-600 xl:text-base xs:text-[10px] font-medium leading-5 ">
                            on {post.timestamp.toDate().toLocaleString([], { dateStyle: 'medium' })}
                          </div>
                        </div>
                      </div>
                      <div className="text-black xl:text-[28px] xs:text-xl text-start font-semibold leading-9 self-stretch mt-2 max-md:max-w-full">
                        {post.title}
                      </div>
                      <div className="text-[#616161]  xl:text-lg lg:text-lg mr-3 md:text-xl xs:text-xs xl:w-[49rem] lg:w-[35rem] md:w-[20rem] xs:auto  text-start  leading-8 self-stretch mt-2 ">
                        <div className=''>
                          <p dangerouslySetInnerHTML={{ __html: truncateContent(post.content) }} />
                          <Link to={`/posts/${post.id}`} className='font-medium  xl:text-base lg:text-base md:text-lg xs:text-xs text-[#625D42] underline'>{post.readTime} {post.time} Read</Link>
                        </div>

                      </div>
                    </div>
                    <div className="xl:flex lg:flex  md:flex xl:relative xs:hidden flex-col ">
                      <img
                        loading="" alt=''
                        src={post.featuredImageUrl}
                        className="rounded-[21px]  h-64 w-72 my-2"
                      />
                    </div>
                  </div>
                ))}

            </div>
          )}
        </div>

      </div>
      <div className='xl:flex justify-between mx-2 my-7 mt-28'>
        <div className=' flex justify-center xl:mx-5 my-1'>
          <PaginationButtons count={totalPages} page={currentPage} onChange={handlePageChange} />
        </div>
        <div className='flex  justify-center gap-2 mx-5 my-1'>
          <p className='mt-1'>Page</p>
          <select
            className='border-[1px] border-[#DDDDDD] rounded-[7px] xl:px-4  py-1.5 cursor-pointer'
            onChange={(e) => setCurrentPage(Number(e.target.value))}
            value={currentPage}
          >
            {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
              <option key={page} value={page}>
                {page}
              </option>
            ))}
          </select>
          <p className='mt-1'>of {totalPages}</p>
        </div>
      </div>

    </MainPageLayout>
  )
}
function truncateContent(content) {
  const MAX_LENGTH = 300;
  if (content.length <= MAX_LENGTH) return content;

  return content.slice(0, MAX_LENGTH) + '...';
}