import React, { useState, useRef } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import { db, storage } from '../../firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLayout from '../Layouts/AuthLayout';
import { useNavigate } from 'react-router-dom';

const Key = process.env.REACT_APP_TINYMCE_API;

export default function Posts() {
    const editorRef = useRef(null);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: "",
        readTime: "",
        time: "",
        Author: ""
    });
    const [content, setContent] = useState("");
    const [featuredImage, setFeaturedImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [profileImage, setProfileImage] = useState(null);

    // State for image previews
    const [profileImagePreview, setProfileImagePreview] = useState(null);
    const [featuredImagePreview, setFeaturedImagePreview] = useState(null);
    const [coverImagePreview, setCoverImagePreview] = useState(null);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFeaturedImageChange = (e) => {
        const file = e.target.files[0];
        setFeaturedImage(file);
        setFeaturedImagePreview(URL.createObjectURL(file));
    };

    const handleCoverImageChange = (e) => {
        const file = e.target.files[0];
        setCoverImage(file);
        setCoverImagePreview(URL.createObjectURL(file));
    };

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
        setProfileImagePreview(URL.createObjectURL(file));
    };

    const submit = async (event) => {
        event.preventDefault();
        try {
            const featuredImageRef = ref(storage, `featured_images/${featuredImage.name}`);
            await uploadBytesResumable(featuredImageRef, featuredImage);
            const featuredImageUrl = await getDownloadURL(featuredImageRef);

            const coverImageRef = ref(storage, `cover_images/${coverImage.name}`);
            await uploadBytesResumable(coverImageRef, coverImage);
            const coverImageUrl = await getDownloadURL(coverImageRef);

            const profileImageRef = ref(storage, `profile_images/${profileImage.name}`);
            await uploadBytesResumable(profileImageRef, profileImage);
            const profileImageUrl = await getDownloadURL(profileImageRef);

            await addDoc(collection(db, 'admin'), {
                title: formData.title,
                readTime: formData.readTime,
                time: formData.time,
                Author: formData.Author,
                content: content,
                featuredImageUrl: featuredImageUrl,
                coverImageUrl: coverImageUrl,
                profileImageUrl: profileImageUrl,
                timestamp: serverTimestamp()
            });

            setFormData({
                title: "",
                readTime: "",
                time: "",
                Author: ""
            });
            setContent("");
            setFeaturedImage(null);
            setCoverImage(null);
            setProfileImage(null);
            setProfileImagePreview(null);
            setFeaturedImagePreview(null);
            setCoverImagePreview(null);

            toast.success("Post submitted successfully!");
            navigate('/Dashboard');
        } catch (error) {
            console.error("Error adding post: ", error);
            toast.warning("Please fill all the Data ");
        }
    };

    return (
        <div>
            <AuthLayout>
                <ToastContainer />
                <div className='px-6'>
                    <div className='mx-4 my-2 mt-5'>
                        <p className='text-3xl text-start font-semibold'>Add Post</p>
                        <form className='xl:flex gap-3 mt-4'>
                            <div className='border-[#ececec] border-[1px] rounded-md p-4 my-2'>
                                <div className='text-start'>
                                    <label className='text-start text-base font-medium'>Author Name</label> <br />
                                    <input name='Author'
                                        className="border-[#ececec] border-[1px] xs:w-64 xl:w-[45rem] rounded-[10px] w-[718px] mt-3 p-2"
                                        onChange={handleOnChange}
                                        type='text' placeholder='Author' />
                                </div>
                                <div className='text-start my-4'>
                                    <label className='text-start text-base font-medium'>Title</label> <br />
                                    <input name='title'
                                        className="border-[#ececec] border-[1px] xs:w-64 xl:w-[45rem] rounded-[10px] w-[718px] mt-3 p-2"
                                        onChange={handleOnChange}
                                        type='text' placeholder='Title' />
                                </div>
                                <div className='my-4'>
                                    <p className='text-start text-base font-medium'>Content</p>
                                </div>
                                <div className='my-4'>
                                    <Editor
                                        apiKey={Key}
                                        onInit={(evt, editor) => (editorRef.current = editor)}
                                        initialValue=""
                                        onChange={(e) => setContent(e.target.getContent({ format: 'raw' }))}
                                        name='content'
                                        init={{
                                            height: 300,
                                            menubar: true,
                                            statusbar: false,
                                            plugins: [
                                                "advlist", "autolink", "lists", "link", "image",
                                                "charmap", "preview", "anchor", "searchreplace",
                                                "visualblocks", "code", "fullscreen", "insertdatetime",
                                                "media", "table", "code", "wordcount"
                                            ],
                                            toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist outdent indent | link image | preview fullscreen | forecolor backcolor emoticons',
                                            content_style: "body { font-family:sans-serif; font-size:14px }",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='border-[#ececec] border-[1px] rounded-md p-4 my-2'>
                                <div className='relative text-start'>
                                    <label className='text-start text-base font-medium'>Read Time</label> <br />
                                    <input className="border-[#ececec] border-[1px] xs:w-[17rem] xl:w-[30rem] rounded-[10px] w-[418px] mt-3 p-2"
                                        name='readTime'
                                        onChange={handleOnChange}
                                        type='number' placeholder='00' />
                                    <select className='absolute right-10 top-11 border-[#ececec] border-[1px] rounded-[3px]'
                                        name='time' onChange={handleOnChange}>
                                        <option>select</option>
                                        <option>min</option>
                                        <option>hrs</option>
                                    </select>
                                </div>
                                <div className='text-start mt-3'>
                                    <label className='text-start text-base font-medium'>Profile Image</label> <br />
                                    <div className='border-[#ececec] border-[1px] rounded-md p-4 mt-3'>
                                        <input className="mt-3 p-2"
                                            type='file' placeholder='' onChange={handleProfileImageChange}
                                            accept="image/webp,image/jpeg,image/png,image/jpg" />
                                        <p className='text-red-600 text-xs px-2'>Only webp, jpg, png, jpeg formats are accepted.</p>
                                        <div className='flex justify-center items-center'>
                                            {profileImagePreview && (
                                                <img src={profileImagePreview} alt="Profile Preview" className='h-20 w-20 rounded-full my-2' />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='text-start mt-3'>
                                    <label className='text-start text-base font-medium'>Featured Image</label> <br />
                                    <div className='border-[#ececec] border-[1px] rounded-md p-4 mt-3'>
                                        <input className="mt-3 p-2"
                                            type='file' placeholder='' onChange={handleFeaturedImageChange}
                                            accept="image/webp,image/jpeg,image/png,image/jpg" />
                                        <p className='text-red-600 text-xs px-2'>Only webp, jpg, png, jpeg formats are accepted.</p>
                                        <div className='flex justify-center items-center'>
                                            {featuredImagePreview && (
                                                <img src={featuredImagePreview} alt="Featured Preview" className='h-24 w-56 my-2' />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='text-start mt-3'>
                                    <label className='text-start text-base font-medium'>Cover Image</label> <br />
                                    <div className='border-[#ececec] border-[1px] rounded-md p-4 mt-3'>
                                        <input className="mt-3 p-2"
                                            type='file' placeholder='' onChange={handleCoverImageChange}
                                            accept="image/webp,image/jpeg,image/png,image/jpg" />
                                        <p className='text-red-600 text-xs px-2'>Only webp, jpg, png, jpeg formats are accepted.</p>
                                        <div className='flex justify-center items-center'>
                                            {coverImagePreview && (
                                                <img src={coverImagePreview} alt="Cover Preview" className='h-24 w-56 my-2' />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="flex justify-center mt-5">
                        <button onClick={submit} className="bg-yellow px-4 flex justify-start py-2 text-white hover:text-black xl:text-lg xs:text-base font-normal" style={{ borderRadius: '6.355px 6.355px 6.355px 6.355px' }}>
                            Publish
                        </button>
                    </div>
                </div>
            </AuthLayout>
        </div>
    );
}
