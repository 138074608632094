import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'

export default function PrivacyPolicy() {
    return (
        <div>
            <Navbar />
            <div className="bg-white flex flex-col items-stretch my-5 mb-3  xl:mx-5">

                <div className="text-[#625D42] xl:text-[60px] xs:text-3xl my-2 mb-3 font-semibold tracking-wide uppercase self-center  max-md:max-w-full max-md:text-4xl mt-3">
                    Privacy Policy
                </div>
                <div className="text-stone-600 text-lg my-2 font-semibold  leading-6 self-center whitespace-nowrap ">
                    Last Updated: September 1, 2023
                </div>
                <div className="text-[#616161] my-3 xl:text-lg xs:text-base leading-5 self-start text-start mx:10 2xl:mx-auto 2xl:w-[85rem] xl:mx-auto xl:w-[75rem] lg:mx-10  md:mx-10 xs:mx-5  mt-7 max-md:max-w-full">
                    <div className='my-3'>
                        <p className="my-1">
                            The CrowdFund Investor (“we,” “us,” or “our”) is committed to protecting
                            the personal information of its users. We believe that you have a right
                            to know our practices regarding the information we collect when you
                            visit and/or use the website. This Privacy Policy explains our privacy
                            practices. By accessing and/or using the Site, you acknowledge that you
                            understand how we process your personal data in accordance with this
                            Policy, including our collection, use, disclosure, processing and
                            retention of personal information. You can also learn how to limit
                            sharing of information in this Policy.
                        </p>
                        <div className='flex'>
                            <p className='font-medium mr-2'>Important Notice: </p> You are free to use this website without the need todisclose personal data.
                        </div>
                        
                    </div>
                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            A. Information We Collect

                        </p>
                    </div>

                  

                        <p className="font-semibold italic text-[#343A40] my-1">
                           1. Information You Provide
                        </p>
                    <div className='my-3'>
                        We may ask you to provide certain personal information, including a
                        valid email address, your name (first and last), or your company name.
                        We may also request information such as physical address or billing
                        information, telephone number or other contact details, transactional
                        information, payment information (for example, in certain cases we
                        process your payment method and credit card number), and additional
                        authentication information (such as copies of your government issued ID,
                        passport, or driving license, as permitted by applicable laws). We also
                        collect information about or contained in your communications with us.
                            <p className="font-semibold italic text-[#343A40] my-1">
                               2. Information We Collect Automatically.
                            </p>
                        
                        <p className='my-3'>
                            We collect information while you access, browse, view or otherwise use
                            the Site. In other words, when you access the Site, we collect, use and
                            process the information relating to such usage, including geo-location
                            information, IP address, device and connection information, and web-log
                            information. We use that information to provide you our services,
                            enhance user experience, personalize your browsing experience as well as
                            monitor the Site for preventing fraud and inappropriate content or
                            behavior.</p>
                            
                        <div className='my-3'>
                            <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-1">
                                B. How Do We Use the Information Collected?
                            </p>
                        </div>
                        We will use your personal information for the following purposes:

                        <ol className=' mx-5'>
                            <li className="font-semibold  text-[#616161] my-1">
                                a. To provide you with quality service and security, to operate the
                                Site, to perform our obligations to you and to develop and improve
                                our Site.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                b. To ensure integrity, prevent fraud and maintain a safe and secure
                                online space.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                               c. To contact you, as requested by you or as otherwise approved by
                                you or permitted according to this Policy.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                               d.  To promote and advertise the Site and our content.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                               e. To maintain appropriate business records, to comply with lawful
                                requests by public authorities and to comply with applicable laws
                                and regulations or as otherwise required by law.
                            </li>
                        </ol>
                        <div className='my-2'>
                            <p className="my-1"> We will ask for your consent before using information for a purpose
                                other than those set out in this Policy.</p>

                            <p className="my-1 font-semibold xl:text-xl   xs:text-xl leading-7 text-[#343A40]"> Direct marketing:</p>

                            <p className="my-1"> We use your personal information to send you direct marketing
                                communications about our Site and its content that may be of interest to
                                you. This may be via email, post, SMS, telephone or targeted online
                                advertisements.</p>

                            <p className="my-1">Our processing of your personal information for marketing purposes is
                                based on our legitimate interest, or on your consent, or as otherwise
                                permitted according to applicable laws. You have a right to prevent
                                direct marketing of any form at any time - this can be exercised by
                                following the opt-out link attached to each communication or by sending
                                an email to us.</p>

                            <p className="my-1"> We take steps to limit direct marketing to a reasonable and
                                proportionate level, and to send you communications which we believe may
                                be of interest or relevance to you, based on the information we have
                                about you.</p>
                        </div>
                    </div>
                    <div className='my-3'>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            C. Our Legal Basis for Using Your Personal Information
                        </p>
                    </div>
                    <ol className='my-3  mx-5'>
                        Where relevant under applicable laws, all processing of your personal
                        information will be justified by a &quot;lawful ground&quot; for
                        processing. In the majority of cases, processing will be justified on
                        the basis that:
                        <li className="font-semibold  text-[#616161] my-1">
                            a. You have provided your consent for us to use your personal
                            information for a specific purpose;
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            b. Our use of your personal information is necessary to perform a
                            contract or take steps to enter into a contract with you (e.g. to
                            provide you with content which you have requested);
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            c. The processing is necessary to comply with a relevant legal
                            obligation or regulatory obligation that we have (e.g. fraud
                            prevention); or
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            d. The processing is necessary to support our legitimate interests as
                            a business (e.g. to improve our services to you), subject to your
                            interests and fundamental rights and provided it is conducted at
                            all times in a way that is proportionate.
                        </li>
                    </ol>
                    <div className='my-3'>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            D. How Long Do We Keep Personal Information?
                        </p>
                    </div>
                    <ol className='my-3  mx-5'>
                        <p className="my-1">
                            We apply a general rule of keeping personal information only for as long
                            as is required to fulfil the purpose for which it was collected.
                            However, in some circumstances, we will retain your personal information
                            for longer periods of time. We will retain personal information for the
                            following purposes:
                        </p>
                        <li className="font-semibold  text-[#616161] my-1">
                            a. As long as it is necessary and relevant for our operations, e.g.
                            so that we have an accurate record of your dealings with us in the
                            event of any complaints or challenge; and
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            b. To comply with applicable laws, prevent fraud, collect any fees
                            owed, resolve disputes, troubleshoot problems, assist with any
                            investigation, enforce our Site terms and take other actions as
                            permitted by law.
                        </li>
                    </ol>
                    <div className='my-3'>
                        <div >
                            <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                E.  Children
                            </p>
                        </div>
                        <p className='mx-5'>
                            This Site is offered and available to users who are at least 18 years of
                            age or older and of legal age to form a binding contract. Minors under
                            18 and at least 13 years of age, are only permitted to use the Site
                            through a parent or legal guardian. Minors under 13 are not permitted to
                            use the Site. We do not knowingly collect personal information from
                            children under 13. Parents and guardians should at all times supervise
                            their children's activities. If we learn we have collected or received
                            personal information from a child under 13, we will delete that personal
                            information. If you believe we might have any information from or about
                            a child under 13, please contact us at us.
                        </p>
                    </div>
                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            F. Sharing Personal Information with Third Parties
                        </p>
                    </div>
                    <div className=' list-none mx-5'>
                        <p className="my-1"> We do not sell or rent your personal information to third parties for
                            their marketing purposes without your explicit consent.</p>

                        <p className="my-1">We share your personal information with third parties as detailed below:</p>
                        <div className='my-4'>
                            <li className="font-semibold  text-[#616161] my-1">
                               a. With Service Clients. We share personal information for business
                                purposes with service clients that provide us with services for
                                the purpose of operating the Site, as well as providing ancillary
                                services and solutions. These include, among others, hosting
                                services, billing and payment processors and vendors, legal and
                                financial advisors or technical consultants. Consistent with
                                applicable legal requirements, we take appropriate technical and
                                organizational measures to require third parties to adequately
                                safeguard your personal information and only process it in
                                accordance with our instructions;
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                b. For legal reasons. We share personal information with law
                                enforcement agencies, public authorities or other parties in order
                                to respond to a subpoena or court order, judicial process or to
                                regulatory authorities, if we believe we are required to do so by
                                law, or that doing so is reasonably necessary to comply with legal
                                processes; when we believe it necessary or appropriate to disclose
                                personal information to law enforcement authorities, such as to
                                investigate actual or suspected fraud or violations of law,
                                breaches of security, or breaches of this Policy; to respond to
                                claims against us; and to protect the rights, property, or
                                personal safety of our company, our customers, or the public;
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                c. For payments and fraud detection. We share personal information
                                with payment processors, fraud detection agencies and similar
                                third parties for the purpose of facilitating payments done via
                                the Site and securing the Site and protecting it against fraud,
                                unauthorized transactions (such as money laundering), claims or
                                other liabilities; and
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                d. In the context of a corporate transaction. Personal information
                                will also be disclosed if we go through a business transition such
                                as a merger, sale, transfer of all or a portion of our assets,
                                acquisition, bankruptcy or similar event. In the event that we
                                sell any business or assets, we will disclose your data to the
                                prospective client. If we or substantially all of our assets are
                                acquired by a third party, information held by us about our users
                                will be one of the transferred assets.
                            </li>
                        </div>

                    </div>

                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            G. Where We Store Your Personal Information
                        </p>
                    </div>
                    <p className='mx-5'>Primarily, we store your data in the United States of America. Whether
                        to third parties or internally, there are also transfers of personal
                        information from the European Economic Area (“EEA”) to countries outside
                        of the EEA not deemed to provide an adequate level of data protection
                        from a European data protection perspective. With regard to data
                        transfers to such recipients outside of the EEA we provide appropriate
                        safeguards, in particular, by way of entering into European Union (EU)
                        standard contractual clauses as amended from time to time, and/or
                        equivalent data transfer safeguards. We will take all steps reasonably
                        necessary to ensure that your personal information is treated securely
                        and in accordance with this Policy.</p>

                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            h. External Links
                        </p>
                    </div>
                    <p className='mx-5'> Please note that the Site may contain links to third party apps and
                        sites and if you navigate to a third-party app or site from the Site,
                        any data you provide to that app or site and any use of that data by the
                        third party are not under our control and are not subject to this
                        Policy. You should consult the privacy policies of each app and site you
                        visit. This Policy applies solely to personal information collected by
                        our Site. If you upload content, including personal information, to a
                        social network and then tag the app or site, your submission will be
                        subject to that social network's terms of use and privacy policy, even
                        where you post or share content from our Site on the social network. We
                        do not have control over these terms of use and privacy policies and
                        have not reviewed their adequacy. You should therefore review these
                        before submitting any personal information.</p>

                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            I. Security
                        </p>
                    </div>
                    <p className='mx-5'> We implement technical and organizational measures to maintain the
                        security of the Site, our services and personal information and in
                        preventing unauthorized access, loss, misuse, alteration, destruction or
                        damage to it through industry standard technologies and internal
                        procedures. We contractually ensure that any third party processing your
                        personal information equally provides for confidentiality and integrity
                        of your data in a secure way. However, the transmission of data via the
                        internet is not completely secure, and although we will do our best to
                        protect your personal information, we cannot guarantee the security of
                        your data transmitted to the Site. Once we have received your data, we
                        will use strict procedures and security features to try to prevent
                        unauthorized access.</p>

                    <div>
                        <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                            J. Rights of EU, EEA and UK Users
                        </p>
                    </div>
                    <div className='mx-5'>
                        <p className="my-1">This section of the Policy applies to you if you are in the EU, the
                            European Economic Area (EEA) or UK.</p>
                        <p className="my-1">
                            The Controller (within the meaning of the EU and UK General Data
                            Protection Regulation (“GDPR”)) for the processing of personal data as
                            described in this Privacy policy is: The CrowdFund Investor
                        </p>
                        <p className="my-1">
                            Under applicable EU regulation, you have the following rights in respect
                            of your personal information:
                        </p>
                    </div>


                    <ol className=' mx-5 my-4'>
                        <li className="font-semibold  text-[#616161] my-1">
                            a. To obtain information about how and on what basis your personal
                            information is processed and to obtain a copy;
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            b. To rectify inaccurate personal information;
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            c. To erase your personal information in limited circumstances where
                            (a) you believe that it is no longer necessary for us to hold your
                            personal information; (b) we are processing your personal
                            information on the basis of legitimate interests and you object to
                            such processing, and we cannot demonstrate an overriding
                            legitimate ground for the processing; (c) where you have provided
                            your personal information to us with your consent and you wish to
                            withdraw your consent and there is no other ground under which we
                            can process your personal information; and (d) where you believe
                            the personal information we hold about you is being unlawfully
                            processed by us;
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            d. To restrict processing of your personal information where: (a) the
                            accuracy of the personal information is contested; (b) the
                            processing is unlawful but you object to the erasure of the
                            personal information; (c) we no longer require the personal
                            information for the purposes for which it was collected, but it is
                            required for the establishment, exercise or defense of a legal
                            claim or (d) you have objected to us processing your personal
                            information based on our legitimate interests and we are
                            considering your objection;
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            e. To object to decisions which are based solely on automated
                            processing or profiling;
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            f. Where you have provided your personal information to us with your
                            consent, to ask us for a copy of this data in a structured,
                            machine-readable format and to ask us to share (port) this data to
                            another data controller; or
                        </li>
                        <li className="font-semibold  text-[#616161] my-1">
                            g. To obtain a copy of or access to safeguards under which your
                            personal information is transferred outside of the EEA.
                        </li>
                    </ol>
                    <div className='mx-5'>
                        <p className="my-1">
                            Under certain circumstances, you may have the right to object, on
                            grounds relating to your particular situation, to the processing of your
                            personal data by us and we may be required to no longer process your
                            personal data. Moreover, if your personal data is processed for direct
                            marketing purposes, you have the right to object at any time to the
                            processing of personal data concerning you for such marketing, which
                            includes profiling to the extent that it is related to such direct
                            marketing. In this case your personal data will no longer be processed
                            for such purposes by us.  </p>

                        <p className="my-1">
                            In addition to the above, you have the right to lodge a complaint with a
                            supervisory authority for data protection.
                        </p>
                        <p className="my-1">
                            Please note that the right of access and the right to erasure do not
                            constitute absolute rights and the interests of other individuals may
                            restrict your right of access or erase in accordance with local laws.

                        </p>

                        <p className="my-1">

                            We will ask you for additional data to confirm your identity and for
                            security purposes, before disclosing data requested by you. We reserve
                            the right to charge a fee where permitted by law. We will decline to
                            process requests that jeopardize the privacy of others, are extremely
                            impractical, or would cause us to take any action that is not
                            permissible under applicable laws.
                        </p>
                        <p className="my-1">

                            Additionally, as permitted by applicable laws, we will retain where
                            necessary certain personal information for a limited period of time for
                            record-keeping, accounting and fraud prevention purposes.
                        </p>
                    </div>
                    <div>
                        <div>
                            <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                K.  Specific Provisions for California Residents
                            </p>
                        </div>

                        <div className='mx-5'>
                            <p className="my-1">
                                This section of the Policy applies to you, if you are a California
                                resident.
                            </p>
                            <p className="my-1">
                                During the last twelve (12) months we have collected the following
                                categories of personal information from users:
                            </p>
                        </div>

                        <ol className=' mx-5 my-4 '>
                            <li className="font-semibold  text-[#616161] my-1">
                                a. Information that you chose to upload or otherwise provided by you
                                to us.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                b. Information we collect when you use our Site, including (i)
                                Identifiers and personal information, such as online identifiers,
                                internet protocol (IP) addresses, access device and connection
                                information such as browser type, version, and time zone setting
                                and browser plug-in types and versions
                            </li>
                        </ol>
                        <div className='mx-5 my-2'>
                            <p className="my-1">
                                We use the personal information that we collect or receive for the
                                business purposes as described above under the Section titled “How Do We
                                Use the Information Collected?”. </p>

                            <p className="my-1">We may disclose the above listed categories of personal information to
                                third parties for business purposes as described above under the Section
                                titled “Sharing Personal Information with Third Parties” in the Privacy
                                Policy. In the preceding twelve (12) months, we have disclosed all the
                                categories of personal information detailed above for business purposes.
                            </p>
                            <p className="my-1">
                                As previously mentioned in this Policy, we do not “sell” (as such term
                                is defined in the CCPA) personal information.
                            </p>
                            <p className="my-1">
                                You are entitled to the following specific rights under the CCPA,
                                subject to certain exceptions, in relation to personal information
                                related to you:
                            </p>
                        </div>


                        <ol className=' mx-5 my-4'>
                            <li className="font-semibold  text-[#616161] my-1">
                                a. You have a right to request access to the personal information we
                                have collected about you over the past 12 months, including: (i)
                                the categories of personal information we collect about you; (ii)
                                the categories of sources from which the personal information is
                                collected; (iii) the business or commercial purpose for collecting
                                your personal information; (iv) the categories of third parties
                                with whom we have shared your personal information; (v) the
                                specific pieces of personal information that we have collected
                                about you.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                b. You have a right to request that we delete personal information
                                related to you that we collected from you under certain
                                circumstances and exceptions.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                c. You also have a right not to be discriminated against for
                                exercising your rights under the CCPA.
                            </li>
                            <li className="font-semibold  text-[#616161] my-1">
                                d. You also have a right to submit your request via an authorized
                                agent. If you use an authorized agent to submit a request to
                                access or delete your personal information on your behalf, the
                                authorized agent must: (1) be a person or business entity
                                registered with the California Secretary of State to conduct
                                business in California; (2) provide proof of such registration;
                                and (3) provide documentation or other proof indicating that they
                                are authorized to act on your behalf. We may also require you to
                                verify your identity directly with us, and directly confirm with
                                us that you provided the authorized agent permission to submit the
                                request.
                            </li>
                        </ol>
                        <div className='mx-5 my-2'>
                            <p className="my-1">

                                To make such requests, we kindly ask that you contact us. We will verify
                                your request using the information associated with you, including email
                                address. Government identification may also be required.
                            </p>
                            <p className="my-1">
                                A request for access can be made by you only twice within a
                                12-months period. Any disclosures that we provide will only cover the
                                12-months period preceding receipt of your request. We do not charge a
                                fee to process or respond to your verifiable User request unless it is
                                excessive, repetitive, or manifestly unfounded. If we determine that the
                                request warrants a fee, we will inform you of the reasons for such
                                decision and provide you with a cost estimate before processing further
                                your request.

                            </p>
                        </div>
                    </div>
                    <div className='my-3'>

                        <div>
                            <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                L.  Updating Personal Information
                            </p>
                        </div>
                        <div className='mx-5'>
                            We take steps to ensure that the personal information we collect is
                            accurate and up to date, and we provide you with the opportunity to
                            update your information by contacting us. In the event that you believe
                            your information is in any way incorrect or inaccurate, please let us
                            know immediately. We will make sure we investigate the matter and
                            correct any inaccuracies as quickly as possible where necessary or give
                            you ways to update it quickly or to delete it - unless we have to keep
                            that information for legitimate business or legal purposes. When
                            updating your personal information, we will ask you to verify your
                            identity before we can act on your request.
                        </div>
                    </div>
                    <div className='my-3'>
                        <div>
                            <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                M.  Changes to this Policy
                            </p>
                        </div>
                        <div className='my-1 mx-5'>
                            We reserve the right to modify or revise this privacy policy at our sole
                            discretion or as necessary to update our privacy commitment and comply
                            with relevant privacy laws. The date of the last revision will always be
                            indicated at the top of this document. By continuing to use the Site,
                            you acknowledge and consent to the modifications made to this Privacy
                            Policy, and agree to abide by and be bound by the updated Privacy
                            Policy.
                        </div>
                    </div>

                    <div className='my-3'>

                        <div>
                            <p className="font-semibold xl:text-2xl   xs:text-xl leading-7 text-[#343A40] my-3">
                                N. Contact Us
                            </p>
                        </div>
                        <div className='mx-5'>
                            <p className="my-1">
                                To exercise any of your rights in connection with your personal
                                information, we kindly ask that you contact us using the information
                                below. We will process any requests in line with any local laws and our
                                policies and procedures.
                            </p>
                            <p className="my-1 font-semibold">
                                The CrowdFund Investor
                            </p>
                            <p className="my-1  font-semibold">
                            Email: info@thecrowdfundinvestor.com
                            </p>
                            <p className="my-1">
                                If you have any questions (or comments) concerning this Policy, please
                                email us, and we will make an effort to reply within a reasonable
                                timeframe.
                            </p>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}
