import './App.css';
import RealEstateOfferings from './Pages/RealEstateOfferings';
import RealState from './Pages/RealState';
import RegA from './Pages/RegA';
import RegCF from './Pages/RegCF';
import Landingpage from './Pages/Landingpage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RealStateSponser from './Pages/RealStateSponser';
import RealEstateFee from './Pages/RealEstateFee';
import About from './Pages/About';
import Articlespage from './Pages/Articlespage';
import Terms from './Pages/Terms';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Signin from './Pages/Admin/Auth/Signin ';
import Dashboard from './Pages/Admin/Dashboard';
import Posts from './Pages/Admin/Posts';
import ArticleDetail from './Pages/ArticleDetail';
import PostComments from './Pages/Admin/PostComments';
import EditPost from './Pages/Admin/EditPost';
import ResetPass from './Pages/Admin/Auth/ResetPass';
import AuthReset from './Pages/Admin/Auth/AuthReset';
import AdminArticlePage from './Pages/Admin/AdminArticlePage';
import AffiliateDisclosure from './Pages/AffiliateDisclosure';
import Cookies from './Pages/Cookies';
import Fees from './Pages/Fees';


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Landingpage />}></Route>
          <Route exact path="/offering" element={<RealEstateOfferings />}></Route>
          <Route exact path="/rega" element={<RegA />}></Route>
          <Route exact path="/Regcf" element={<RegCF />}></Route>
          <Route exact path="/realstate" element={<RealState />}></Route>
          <Route exact path="/Sponser" element={<RealStateSponser />}></Route>
          <Route exact path="/fee" element={<RealEstateFee />}></Route>
          {/* <Route exact path="/fees" element={<Fees/>}></Route> */}
          <Route exact path="/about" element={<About /> }></Route>
          <Route exact path="/articles" element={<Articlespage/> }></Route>
          <Route exact path="/terms" element={<Terms/> }></Route>
          <Route exact path="/privacy" element={<PrivacyPolicy/> }></Route>
          <Route exact path="/signin" element={<Signin/> }></Route>
          <Route exact path="/Dashboard" element={<Dashboard/> }></Route>
          <Route exact path="/post" element={<Posts/> }></Route>
          <Route exact path="/posts/:postId" element={<ArticleDetail/>}></Route>
          <Route exact path="/detail/:postId" element={<AdminArticlePage/>}></Route>
          <Route exact path ="/comments/:postId" element={<PostComments/>}></Route>
          <Route path="/edit/:postId" element={<EditPost />} />
          <Route path="/reset" element={<ResetPass/>} />
          <Route path="/authreset" element={<AuthReset/>} />
          <Route path="/Affiliate" element={<AffiliateDisclosure/>} />
          <Route path="/cookies" element={<Cookies/>} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
