import React from 'react'
import Mission from '../Components/Mission'
import about from '../Assets/Images/about.png'
import abt from '../Assets/Images/abt.png'
import Questions from '../Components/Questions'
import MainPageLayout from '../Pages/Layouts/MainPageLayout';
import bg from '../Assets/Images/bg.png';

export default function About() {
    return (
        <div>
            <MainPageLayout heading="About us" text="WE POST INDUSTRY NEWS, INVESTOR GUIDES, COMPANY UPDATES AND MORE."
                style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bg})`,
                    height: '300px',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}>
                <div className='lg:mt-10 mt-0'>
                    <Mission />
                </div>
                <div className='xl:flex xl:flex-col-2 xl:mx-16 xs:mx-2 xl:my-7 xs:my-5'>
                    <div className='mx-6 xl:flex xs:hidden'>
                        <img src={about} alt="" className='h-80' />
                    </div>
                    <div className='xl:w-[59rem] mx-2'>
                        <p className='text-start font-bold text-black xl:text-3xl lg:text-3xl md:text-2xl xs:text-lg my-2 p-4 uppercase'>Investment Insights for the Self-Directed Investor:
                        </p>
                        <p className=' font-medium text-start xl:text-xl lg:text-xl md:text-xl xs:text-xs text-[#616161]  px-4'>In a world where financial knowledge can make all the difference, TheCrowdFundInvestor.com was born out of a need to break down barriers and democratize access to alternative investment opportunities. Recognizing the information gap that often leaves individual investors at a disadvantage, we set out on a mission to level the playing field.</p>
                        <p className=' font-medium text-start  xl:text-xl lg:text-xl md:text-xl xs:text-xs text-[#616161]  px-4'>   We strive to break down the complexities of crowdfunding investments and transform them into accessible, understandable, and actionable resources for every self-directed investor, irrespective of their accreditation status.
                        </p>
                    </div>
                </div>
                <div className='xs:flex xl:hidden mb-5'>
                    <img src={abt} alt="" className=' w-full' />
                </div>
                <div className='mx-2'>
                    <Questions />
                </div>
            </MainPageLayout>
        </div>
    )
}
